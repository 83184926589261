import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Box, Button, Tab, Typography } from "@mui/material";
import { a11yProps, StyledTabs } from "../utils/Helpers";
import publishedBook from "../assets/images/published-book.svg";
import TruncatedText from "../components/reuseableFunctions/TruncatedText";
import { AgentMatchingDlg } from "../components/dialogs/AgentMatchingDlg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export const AgentBio = () => {
  const navigate = useNavigate();
  const [userGenres, setUserGenres] = useState("");
  const [showAgentMatchingDlg, setShowAgentMatchingDlg] = useState(false);

  const { singleAgent } = useSelector((state) => state.author);

  useEffect(() => {
    if (singleAgent?.genres?.length) {
      let data = "";
      singleAgent?.genres.forEach((item) => {
        data = data + item.name + ", ";
      });
      setUserGenres(data);
    }
  }, [singleAgent]);

  return (
    <Stack
      flexDirection={{ sm: "column", md: "row" }}
      gap={"1em"}
      padding={"1em"}
    >
      <Stack width={{ sm: "100%", md: "30%" }}>
        <Box>
          <StyledTabs value={0} aria-label="basic tabs example">
            <Tab label="Agent Details" {...a11yProps(0)} />
          </StyledTabs>
        </Box>
        <Stack padding={"1em"}>
          <Typography fontWeight={700}>Name</Typography>
          <Typography paddingBottom={"0.5em"}>
            {singleAgent?.full_name}
          </Typography>
          <Typography fontWeight={700}>Bio</Typography>
          <Typography>
            {singleAgent?.user_profile?.bio && (
              <TruncatedText
                limit={"40"}
                fontSize={"1em"}
                text={singleAgent?.user_profile?.bio}
              />
            )}
          </Typography>
          <Typography fontWeight={700} paddingTop={"0.5em"}>
            Genres
          </Typography>
          <Typography>
            {userGenres?.length && (
              <TruncatedText text={userGenres} limit={"8"} fontSize={"1em"} />
            )}
          </Typography>
          <Typography fontWeight={700} paddingTop={"1em"}>
            Languages
          </Typography>
          <Typography>
            {singleAgent?.user_languages?.length
              ? singleAgent?.user_languages?.map((item, i) => {
                  return (
                    <span key={i}>
                      {item.name}
                      {i !== singleAgent?.user_languages?.length - 1
                        ? ", "
                        : ""}
                    </span>
                  );
                })
              : ""}
          </Typography>
          <Typography fontWeight={700} paddingTop={"1em"}>
            Countries
          </Typography>
          <Typography>
            {singleAgent?.user_countries?.length
              ? singleAgent?.user_countries?.map((item, i) => {
                  return (
                    <span key={i}>
                      {item.name}
                      {i !== singleAgent?.user_countries?.length - 1
                        ? ", "
                        : ""}
                    </span>
                  );
                })
              : ""}
          </Typography>
          <Typography fontWeight={700} paddingTop={"1em"}>
            Submission Link
          </Typography>
          <Typography
            color={"#00A3FF"}
            onClick={() =>
              window.open(
                singleAgent?.user_profile?.submission_link,
                "_blank",
                "noreferrer"
              )
            }
            sx={{ cursor: "pointer" }}
          >
            {singleAgent?.user_profile?.submission_link}
          </Typography>
          <Typography fontWeight={700} paddingTop={"1em"}>
            Website
          </Typography>
          <Typography
            color={"#00A3FF"}
            onClick={() =>
              window.open(
                singleAgent?.user_profile?.website,
                "_blank",
                "noreferrer"
              )
            }
            sx={{ cursor: "pointer" }}
          >
            {singleAgent?.user_profile?.website}
          </Typography>
        </Stack>
      </Stack>
      <Stack width={{ sm: "100%", md: "50%" }} marginTop={"3rem"}>
        {/* <Stack
          sx={{
            height: "12em",
            width: "12em",
            justifyContent: "flex-end",
            borderRadius: "15px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${singleAgent?.user_profile?.profile_image})`,
          }}
        >
        </Stack> */}
        <img
          src={singleAgent?.user_profile?.profile_image}
          alt="Centered Imagee"
          style={{
            height: "12em",
            width: "12em",
            objectFit: "cover",
            maxWidth: "100%",
            maxHeight: "100%",
            borderRadius: "15px",
          }}
        />
        {/* <Typography fontWeight={700} paddingTop={"1em"}>
          Genres
        </Typography>
        <Typography>
          {userGenres?.length && (
            <TruncatedText text={userGenres} limit={"8"} fontSize={"1em"} />
          )}
        </Typography> */}
        <Typography fontWeight={700} paddingTop={"1em"}>
          Publications
        </Typography>
        <Stack flexDirection={"row"} gap={"0.5em"}>
          {singleAgent?.publications?.length ? (
            singleAgent?.publications.map((_, i) => {
              return (
                <img
                  key={i}
                  alt={""}
                  src={publishedBook}
                  style={{ width: "100px" }}
                />
              );
            })
          ) : (
            <Typography textAlign={"center"}>No Data Found</Typography>
          )}
        </Stack>
      </Stack>
      <Stack width={{ sm: "100%", md: "20%" }}>
        <Button
          sx={{
            fontWeight: "700",
            fontSize: "0.8em",
            lineHeight: "25px",
            textalign: "center",
            color: "#FFFFFF",
            background: "#FF414C",
            boxShadow: "0px 5.47764px 27.3882px rgba(255, 0, 0, 0.25)",
            borderRadius: "32.8658px",
            width: "12em",
            paddingX: "1em",
            marginTop: "2em",
            ":hover": {
              color: "#FFFFFF",
              background: "#FF414C",
            },
          }}
          onClick={() => setShowAgentMatchingDlg(true)}
        >
          Find More Matches
        </Button>
        <Button
          sx={{
            fontWeight: "700",
            fontSize: "0.8em",
            lineHeight: "25px",
            textalign: "center",
            color: "#FFFFFF",
            background: "#FF414C",
            boxShadow: "0px 5.47764px 27.3882px rgba(255, 0, 0, 0.25)",
            borderRadius: "32.8658px",
            width: "12em",
            paddingX: "1em",
            marginTop: "2em",
            ":hover": {
              color: "#FFFFFF",
              background: "#FF414C",
            },
          }}
          onClick={() => navigate("/all-agents")}
        >
          Back
        </Button>
      </Stack>
      <AgentMatchingDlg
        open={showAgentMatchingDlg}
        handleClose={() => setShowAgentMatchingDlg(false)}
      />
    </Stack>
  );
};
