import React from "react";
import MainRoutes from "./utils/routes/MainRoutes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});
const theme = createTheme({
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <MainRoutes />
        </QueryClientProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
