import produce from "immer";
import { toast } from "react-hot-toast";
import {
  STOP_LOADING,
  START_LOADING,
  ERROR,
  GET_AGENCIES_SUCCESS,
  ADD_REMOVE_AGENCY_SUCCESS,
  GET_WISHLIST_SUCCESS,
  GET_FREE_AGENT_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_MY_AGENTS_SUCCESS,
  GET_WISHLIST_AGENCIES_SUCCESS,
  GET_ALL_PITCHES_SUCCESS,
  GET_MY_SUBMISSIONS_SUCCESS,
  UNPUBLISHED_SUBMISSION_SUCCESS,
  BOOK_DEAL_SUCCESS,
  FILTER_AGENCIES_SUCCESS,
  FILTER_PITCHES_SUCCESS,
  CONTACT_US_SUCCESS,
  UPDATE_PROFILE_SUCCESS,
  DELETE_ACCOUNT_SUCCESS,
  GET_SINGLE_PITCH,
  POST_SUBMISSION_SUCCESS,
  GET_SINGLE_SUBMISSION,
  GET_SINGLE_AGENT,
  GET_SINGLE_AGENCY,
  GET_AGENCY_START_LOADING,
  GET_AGENCY_STOP_LOADING,
  GET_WISHLIST_AGENCY_START_LOADING,
  GET_WISHLIST_AGENCY_STOP_LOADING,
  GET_SUBMISSION_START_LOADING,
  GET_SUBMISSION_STOP_LOADING,
  GET_PITCHES_START_LOADING,
  GET_PITCHES_STOP_LOADING,
  RESET_STATE,
  FIND_AGENT_BY_GENRE_SUCCESS,
  FIND_AGENT_BY_GENRE_START_LOADING,
  FIND_AGENT_BY_GENRE_STOP_LOADING,
  AGENT_PAID_SUCCESS,
  CLEAR_AGENT,
  BOOK_SUBMISSION_PURCHASE_SUCCESS,
} from "./actionTypes";

const initialState = {
  isLoading: false,
  getAgencyLoading: false,
  getWishlistAgencyLoading: false,
  getSubmissionLoading: false,
  getPitchesLoading: false,
  agencies: [],
  wishlist: [],
  notifications: [],
  allPitches: [],
  totalPitches: 0,
  mySubmissions: [],
  totalSubmissions: 0,
  paymentHistory: [],
  singlePitch: null,
  singleSubmission: null,
  freeAgent: null,
  paidAgents: [],
  currentPage: null,
  last_page: null,
  agentFoundLoading: false,
  singleAgent: null,
  singleAgency: null,
  agentFound: null,
};

const Author = produce((state, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case GET_AGENCY_START_LOADING:
      return {
        ...state,
        getAgencyLoading: true,
      };
    case GET_AGENCY_STOP_LOADING:
      return {
        ...state,
        getAgencyLoading: false,
      };
    case GET_SUBMISSION_START_LOADING:
      return {
        ...state,
        getSubmissionLoading: true,
      };
    case GET_SUBMISSION_STOP_LOADING:
      return {
        ...state,
        getSubmissionLoading: false,
      };
    case GET_PITCHES_START_LOADING:
      return {
        ...state,
        getPicthesStartLoading: true,
      };
    case GET_PITCHES_STOP_LOADING:
      return {
        ...state,
        getPicthesStartLoading: false,
      };
    case GET_WISHLIST_AGENCY_START_LOADING:
      return {
        ...state,
        getWishlistAgencyLoading: true,
      };
    case GET_WISHLIST_AGENCY_STOP_LOADING:
      return {
        ...state,
        getWishlistAgencyLoading: false,
      };
    case FIND_AGENT_BY_GENRE_START_LOADING:
      return {
        ...state,
        agentFoundLoading: true,
      };
    case FIND_AGENT_BY_GENRE_STOP_LOADING:
      return {
        ...state,
        agentFoundLoading: false,
      };
    case GET_AGENCIES_SUCCESS:
      state.agencies = action.payload.data;
      break;
    case ADD_REMOVE_AGENCY_SUCCESS:
      toast.success("Success");
      break;
    case GET_WISHLIST_SUCCESS:
      state.wishlist = action.payload.data;
      break;
    case GET_FREE_AGENT_SUCCESS:
      state.freeAgent = action.payload.data;
      break;
    case GET_NOTIFICATIONS_SUCCESS:
      state.notifications = action.payload.data;
      break;
    case GET_PAYMENT_HISTORY_SUCCESS:
      state.paymentHistory = action.payload;
      break;
    case GET_MY_AGENTS_SUCCESS:
      if (state.paidAgents.length > 0) {
        state.paidAgents.push(...action.payload.data);
        const uniqueData = Object.values(
          state.paidAgents.reduce((acc, obj) => ((acc[obj.id] = obj), acc), {})
        );
        state.paidAgents = uniqueData;
      } else {
        state.paidAgents = action.payload.data;
      }
      state.currentPage = action.payload.current_page;
      state.last_page = action.payload.last_page;
      break;
    case GET_WISHLIST_AGENCIES_SUCCESS:
      break;
    case GET_SINGLE_PITCH:
      state.singlePitch = action.payload;
      break;
    case GET_SINGLE_SUBMISSION:
      state.singleSubmission = action.payload;
      break;
    case GET_SINGLE_AGENT:
      state.singleAgent = action.payload;
      break;
    case GET_SINGLE_AGENCY:
      state.singleAgency = action.payload;
      break;
    case GET_ALL_PITCHES_SUCCESS:
      let pitchesData = [...state.allPitches, ...action.payload.data];
      const uniquePitches = pitchesData.filter(
        (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
      );
      state.totalPitches = action.payload.total;
      state.allPitches = [...uniquePitches];
      break;
    case GET_MY_SUBMISSIONS_SUCCESS:
      let submissionsData = [...state.mySubmissions, ...action.payload.data];
      const uniqueSubmissions = submissionsData.filter(
        (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
      );
      state.totalSubmissions = action.payload.total;
      state.mySubmissions = [...uniqueSubmissions];
      break;
    case UNPUBLISHED_SUBMISSION_SUCCESS:
      toast.success(action.payload.message);
      break;
    case BOOK_DEAL_SUCCESS:
      toast.success(action.payload.message);
      break;
    case BOOK_SUBMISSION_PURCHASE_SUCCESS:
      console.log("action.payload", action.payload);
      // toast.success(action.payload.message);
      break;
    case FILTER_AGENCIES_SUCCESS:
      state.agencies = action.payload.data;
      break;
    case FILTER_PITCHES_SUCCESS:
      break;
    case CONTACT_US_SUCCESS:
      toast.success(action.payload.message);
      break;
    case UPDATE_PROFILE_SUCCESS:
      toast.success(action.payload.message);
      break;
    case DELETE_ACCOUNT_SUCCESS:
      toast.success("Account Deleted Successfully");
      break;
    case POST_SUBMISSION_SUCCESS:
      break;
    case FIND_AGENT_BY_GENRE_SUCCESS:
      let freeAgent = action.payload.freeAgent;
      state.agentFound = freeAgent ? "free_agent" : action.payload?.data[0];
      break;

    case AGENT_PAID_SUCCESS:
      console.log("success agent paid", action.payload);
      toast.success("New agent added successfully");
      break;
    // toast.success()
    case RESET_STATE:
      state.agencies = [];
      state.wishlist = [];
      state.notifications = [];
      state.allPitches = [];
      state.mySubmissions = [];
      state.paymentHistory = [];
      state.singlePitch = null;
      state.singleSubmission = null;
      state.freeAgent = null;
      state.paidAgents = [];
      state.currentPage = null;
      state.last_page = null;
      state.singleAgent = null;
      state.singleAgency = null;
      break;
    case CLEAR_AGENT:
      state.agentFound = null;
      break;
    case ERROR:
      if (action?.payload?.data?.message) {
        toast.error(action.payload.data.message);
      } else {
        toast.error("Error");
      }
      break;
    default:
      break;
  }
}, initialState);

export default Author;
