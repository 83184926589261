import React from "react";
import { Stack } from "@mui/system";
import { Avatar, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import TruncatedText from "../reuseableFunctions/TruncatedText";
import { useDispatch } from "react-redux";
import { TimeAgo } from "../../utils/Helpers";
import { getSinglePitch } from "../../store/author/actions";

const PitchCard = ({ pitch }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();
console.log("pitchhhhh------>",pitch)
  return (
    <Stack
      p={2}
      spacing={2}
      bgcolor={"white"}
      minHeight={"12em"}
      borderRadius={"16.1431px"}
      sx={{ cursor: "pointer" }}
      border={"1px solid #FF414C"}
      onClick={() => {
        dispatch(getSinglePitch(pitch));
        nav(`/all-submissions/all-pitches/${pitch?.id}`);
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"} spacing={1}>
          <Avatar
            alt={pitch?.user?.user_profile?.full_name}
            src={pitch?.user?.user_profile?.profile_image}
          />
          <Stack>
            <Typography component={"div"} fontWeight={"700"}>
              {pitch?.user?.full_name}
            </Typography>
            <Typography component={"div"} variant="caption" color={"#C0C0C0"}>
              {TimeAgo(new Date(pitch?.created_at))}
            </Typography>
          </Stack>
        </Stack>
        {pitch?.status === "booked" && (
          <>
            <Stack
              onClick={(e) => e.stopPropagation()}
              sx={{
                color: "#6DD96B",
                paddingX: "10px",
                justifyContent: "center",
                borderRadius: "12.1691px",
                border: "1px solid #6DD96B",
              }}
            >
              Book Deal
            </Stack>
          </>
        )}
      </Stack>
      <Stack>
        <Typography component={"div"} fontWeight={"700"}>
          {pitch.title}
        </Typography>
        <TruncatedText text={pitch.pitch} limit={"25"} fontSize={"1em"} />
      </Stack>
    </Stack>
  );
};

export default PitchCard;
