import produce from "immer";
import { toast } from "react-hot-toast";
import {
  STOP_LOADING,
  START_LOADING,
  ERROR,
  GET_COUNTRIES_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  GET_CITIES_SUCCESS,
  GET_PLOTS_SUCCESS,
  GET_STATES_SUCCESS,
  GET_GENRES_SUCCESS,
  GET_LANGUAGES_SUCCESS,
  GET_ADVERTISEMENTS_SUCCESS,
} from "./actionTypes";

const initialState = {
  isLoading: false,
  languages: [],
  genres: [],
  countries: [],
  states: [],
  cities: [],
  advertisements: [],
  categories: [],
  plots: [],
};

const Other = produce((state, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case GET_COUNTRIES_SUCCESS:
      state.countries = action.payload.data;
      break;
    case GET_CATEGORIES_SUCCESS:
      state.categories = action.payload.data;
      break;
    case GET_STATES_SUCCESS:
      state.states = action.payload.data;
      break;
    case GET_CITIES_SUCCESS:
      state.cities = action.payload.data;
      break;
    case GET_PLOTS_SUCCESS:
      state.plots = action.payload.data;
      break;
    case GET_GENRES_SUCCESS:
      state.genres = action.payload.data;
      break;
    case GET_LANGUAGES_SUCCESS:
      state.languages = action.payload.data.map((item) => {
        return { ...item, label: item.name };
      });
      break;
    case GET_ADVERTISEMENTS_SUCCESS:
      state.advertisements = action.payload.data;
      break;
    case ERROR:
      if (action?.payload?.data?.message) {
        toast.error(action.payload.data.message);
      } else {
        toast.error("Error");
      }
      break;
    default:
      break;
  }
}, initialState);

export default Other;
