import React from "react";
import MySubmissionDetails from "../components/submissions/MySubmissionDetails";

const MySubmissionDetailScreen = () => {
  return (
    <>
      <MySubmissionDetails />
    </>
  );
};

export default MySubmissionDetailScreen;
