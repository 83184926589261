import {
  CustomNextArrow,
  CustomPrevArrow,
} from "../components/reuseableFunctions/CustomeArrows";

export const editProfileValues = {
  firstName: "",
  lastName: "",
  userName: "",
  bio: "",
  genreType: "",
  subgenres: "",
  language: "",
  country: "",
  state: "",
  city: "",
  streetAddress1: "",
  streetAddress2: "",
  zip: "",
};

export const fictionalValues = {
  title: "",
  genreType: "fictional",
  subgenres: [],
  pitch: "",
  targetAudience: "",
  plot: [],
  theme: "",
  characters: "",
  country: [],
  language: [],
  category: [],
  wordCount: "",
  singleSeries: "",
  scriptPublished: "",
  queryLetters: "",
  synopsis: "",
  fiftyPages: "",
};

export const nonFictionalValues = {
  title: "",
  genreType: "nonFictional",
  subgenres: [],
  pitch: "",
  targetAudience: "",
  country: [],
  language: [],
  category: [],
  wordCount: "",
  singleSeries: "",
  scriptPublished: "",
  bookProposal: "",
};

export const defaultModalStyle = {
  p: 4,
  top: "50%",
  left: "50%",
  boxShadow: 24,
  bgcolor: "#FFFFFF",
  borderRadius: "8px",
  position: "absolute",
  transform: "translate(-50%, -50%)",
};

export const carouselSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  slidesToScroll: 3,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const agencyCarouselSliderSettings = {
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  initialSlide: 0,
  infinite: false,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};

export const signupForm1Values = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  reEmail: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  terms: false,
};

export const pages = [
  { page: "Home", route: "/" },
  { page: "Agent", route: "/all-agents" },
  { page: "Submissions", route: "/all-submissions" },
  { page: "Resources", route: "Resources" },
  { page: "Help Center", route: "HelpCenter" },
];
export const settingsList = [
  "Query Submission",
  "Submissions",
  "Payment History",
  "Logout",
];
export const helpList = [
  "Privacy Policy",
  "Contact Us",
  "About Us",
  "Help Center",
];
