import React from "react";
import {
  Link,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
} from "@mui/material";
import "../../utils/css/styles.css";
import { Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { Formik, Form, Field } from "formik";
import BWLogo from "../../assets/images/BWLogo.svg";
import { loginUser } from "../../store/auth/actions";
import { useDispatch, useSelector } from "react-redux";

const LoginComponent = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.auth);

  return (
    <Stack
      spacing={10}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack alignItems={"center"} width={"100%"}>
        <img src={BWLogo} alt="" />
        <Typography>Login to access your account</Typography>
      </Stack>
      <Formik
        initialValues={{ email: "", password: "", rememberMe: false }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.password) {
            errors.password = "Required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          let data = new FormData();
          data.append("email", values.email);
          data.append("password", values.password);
          data.append("fmc_token", "asdasd");
          dispatch(loginUser({ data, nav, rememberMe: values.rememberMe }));
        }}
      >
        {({ values, handleChange, errors, touched }) => (
          <Form style={{ width: "100%" }}>
            <Stack spacing={5} alignItems={"center"}>
              <Stack width={{ xs: "90%", sm: "60%", md: "25%" }} spacing={2}>
                <Field
                  as={TextField}
                  error={touched.email && errors.email}
                  className="TextField-without-border-radius fieldset"
                  name="email"
                  label="Username / Email"
                  fullWidth
                  type="email"
                  sx={{
                    background: " #f5f5f5",
                    borderRadius: "35.5689px",
                    "&:-webkit-autofill": {
                      borderRadius: "35.5689px !important", // Set the background color to transparent for autofill
                    },
                  }}
                  InputLabelProps={{ style: { color: "#C0C0C0" } }}
                />
                <Field
                  as={TextField}
                  error={touched.password && errors.password}
                  className="TextField-without-border-radius fieldset"
                  name="password"
                  label="Password"
                  fullWidth
                  type="password"
                  sx={{ background: " #f5f5f5", borderRadius: "35.5689px" }}
                  InputLabelProps={{ style: { color: "#C0C0C0" } }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  mt={"-8px !important"}
                >
                  <FormControlLabel
                    control={
                      <Field
                        as={Checkbox}
                        name="rememberMe"
                        checked={values.rememberMe}
                        onChange={handleChange}
                      />
                    }
                    label="Remember Me"
                  />
                  <Link
                    underline="none"
                    fontWeight="700"
                    color="black"
                    sx={{ cursor: "pointer" }}
                    onClick={() => nav("/forget-password")}
                  >
                    Forgot Password?
                  </Link>
                </Stack>
              </Stack>
              <LoadingButton
                loading={isLoading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  background: "#FF414C",
                  width: { xs: "90%", sm: "60%", md: "25%" },
                  color: "white",
                  boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
                  borderRadius: "35.5689px",
                }}
              >
                Login
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
      <Typography alignSelf={"center"}>
        Don’t have any acount?{" "}
        <Link
          component="button"
          onClick={() => nav("/sign-up-1")}
          underline="none"
          fontWeight={"700"}
          color={"black"}
        >
          SIGN UP
        </Link>
      </Typography>
    </Stack>
  );
};

export default LoginComponent;
