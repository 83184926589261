import React from "react";
import { Stack } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import "../../utils/css/styles.css";
import { defaultModalStyle } from "../../utils/Constants";
import closeIcon from "../../assets/images/close-icon.svg";

export const HelpCenterDlg = ({ handleClose, open }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing="1em"
        alignItems={"center"}
        sx={defaultModalStyle}
        width={{ xs: "90%", sm: "70vw", md: "50vw" }}
      >
        <IconButton
          component="span"
          onClick={handleClose}
          sx={{ position: "absolute", right: "6px", top: "10px" }}
        >
          <img alt="" src={closeIcon} style={{ width: "15px" }} />
        </IconButton>
        <Typography marginTop={"0 !important"} fontWeight={700} variant={"h4"}>
          Help Center
        </Typography>
        <Typography marginTop={"0 !important"}>
          How can we help you today?
        </Typography>
        <Stack sx={{ flexDirection: "row", gap: "1em", paddingTop: "1em" }}>
          <Stack width={"50%"}>
            <Accordion
              sx={{
                borderRadius: "30px !important",
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  borderRadius: 0,
                },
                boxShadow: "4",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontWeight={700}>
                  Who can use the BookWizard App?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Any author with or without a finished manuscript or book
                  proposal
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                marginTop: "1em",
                borderRadius: "30px !important",
                "& .MuiAccordionSummary-root.Mui-expanded": {
                  borderRadius: 0,
                },
                ":before": {
                  height: "0px",
                },
                boxShadow: "4",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontWeight={700}>
                  Author with NO manuscript or book proposal
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  If you do not have a finished manuscript or book proposal, but
                  you love to do research and be ready for when it is, then sign
                  up and have at it! You will have FREE ACCESS to search all the
                  listed Agencies, plus our ‘Resource Library.’
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack width={"50%"}>
            <Accordion
              sx={{
                boxShadow: "4",
                borderRadius: "30px !important",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontWeight={700}>
                  Author with a FINISHED manuscript or book proposal
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  BE DISCOVERED!!! That’s right, enter your pitch, book
                  proposal, or manuscript’s query, synopsis, and 1st 50 pages
                  and your book will be available for an agent to FIND you! Your
                  PITCH and manuscript information can be read 24/7/365 by an
                  agent! PLUS – You will have ACCESS to our ‘Resource Library’
                  to use at your leisure, use the BookWizard genre matching
                  filter to find both, a listed AGENT and AGENCY.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
