export const LOGIN = "login";
export const LOGIN_SUCCESS = "login_success";

export const FETCH_USER = "fetch_user";
export const FETCH_USER_SUCCESS = "fetch_user_success";

export const SIGN_UP_STEP_ONE = "sign_up_1";
export const SIGN_UP_STEP_ONE_SUCCESS = "sign_up_1_success";

export const VERIFY_STEP_ONE = "verify";
export const VERIFY_STEP_ONE_SUCCESS = "verify_success";

export const SIGN_UP_STEP_TWO = "sign_up_2";
export const SIGN_UP_STEP_TWO_SUCCESS = "sign_up_2_success";

export const UPDATE_EMAIL = "update_email";
export const UPDATE_EMAIL_SUCCESS = "update_email_success";

export const UPDATE_EMAIL_VERIFICATION = "update_email_verification";
export const UPDATE_EMAIL_VERIFICATION_SUCCESS =
  "update_email_verification_success";

export const UPDATE_PHONE_NUMBER = "update_phone_number";
export const UPDATE_PHONE_NUMBER_SUCCESS = "update_phone_number_success";

export const UPDATE_PHONE_VERIFICATION = "update_phone_number_verification";
export const UPDATE_PHONE_VERIFICATION_SUCCESS =
  "update_phone_number_verification_success";

export const UPDATE_PASSWORD = "update_password";
export const UPDATE_PASSWORD_SUCCESS = "update_password_success";

export const FORGET_PASSWORD = "forget_password";
export const FORGET_PASSWORD_SUCCESS = "forget_password_success";

export const FORGET_PASSWORD_VERIFY = "forget_password_verify";
export const FORGET_PASSWORD_VERIFY_SUCCESS = "forget_password_verify_success";

export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCESS = "reset_password_success";

export const GET_UPDATED_AUTHOR_PROFILE = "get_updated_author_profile";
export const GET_UPDATED_AUTHOR_PROFILE_SUCCESS =
  "get_updated_author_profile_success";

export const LOGOUT = "logout";

export const ERROR = "ERROR";


export const STOP_LOADING = "stop_loading";
export const START_LOADING = "start_loading";
