import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";
import { addRemoveAgency, getSingleAgency } from "../../store/author/actions";

const AgencyCard = ({ agency }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  return (
    <Card
      sx={{
        minHeight: "45vh",
        border: "1px solid red",
        borderRadius: "15.2542px",
        position: "relative",
      }}
    >
      <CardMedia
        height="165"
        component="img"
        alt={agency?.full_name}
        image={agency?.user_profile?.profile_image}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ToggleButton
          value="check"
          onChange={() => dispatch(addRemoveAgency({ agency_id: agency?.id }))}
          sx={{
            border: "none !important",
            borderRadius: "50px !important",
            position: "absolute !important",
            right: "10px !important",
            // bottom: "40% !important",
            padding: "0 !important",
            ":hover": {
              bgcolor: "white",
              color: "red",
            },
          }}
        >
          <FavoriteIcon
            sx={{ fill: agency?.favourite_agency ? "red" : "currentcolor" }}
          />
        </ToggleButton>
        <Typography
          width={"90%"}
          variant={"h6"}
          lineHeight={1}
          component={"span"}
          fontWeight={"700"}
        >
          {agency?.full_name}
        </Typography>
        <Typography component={"span"} variant={"caption"}>
          {agency?.user_countries?.length
            ? agency?.user_countries?.map((item, i) => {
                return (
                  <span key={i}>
                    {item.name}
                    {i !== agency?.user_countries?.length - 1 ? ", " : ""}
                  </span>
                );
              })
            : ""}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button
          size="small"
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            dispatch(getSingleAgency(agency));
            nav(`agency-detail/${agency?.id}`);
          }}
        >
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default AgencyCard;
