import React from "react";
import DashboardComponent from "../components/dashboard/DashboardComponent";

const DashboardScreen = () => {
  return (
    <>
      <DashboardComponent />
    </>
  );
};

export default DashboardScreen;
