import React, { useState } from "react";
import { IconButton, Modal } from "@mui/material";
import { Stack } from "@mui/system";
import { BiRightArrowAlt } from "react-icons/bi";
import { TextField, Typography } from "@mui/material";
import { defaultModalStyle } from "../../utils/Constants";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateEmail } from "../../store/auth/actions";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import closeIcon from "../../assets/images/close-icon.svg";

export const UpdateEmailDlg = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailValue, setEmailValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (emailValue && isEmail(emailValue)) {
      setIsLoading(true);
      let data = new FormData();
      data.append("email", emailValue);
      dispatch(
        updateEmail({
          data,
          setIsLoading,
          handleClose,
          navigate,
          email: emailValue,
        })
      );
    } else {
      toast.error("Enter a Valid Email");
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing="1em"
        alignItems={"center"}
        sx={defaultModalStyle}
        width={{ xs: "90%", sm: "35vw" }}
      >
        <Stack width={"80%"} justifyContent={"center"} alignItems={"center"}>
          <IconButton
            component="span"
            onClick={handleClose}
            sx={{ position: "absolute", right: "6px", top: "10px" }}
          >
            <img alt="" src={closeIcon} style={{ width: "15px" }} />
          </IconButton>
          <Typography variant="h4" fontWeight={"700"} mt={"0.3em !important"}>
            Update Email
          </Typography>
          <Typography textAlign={"center"}>
            Enter your email and we’ll send you a code to update your email
          </Typography>
          <TextField
            placeholder="Email"
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
            sx={{
              marginTop: "2em",
              "& label": { paddingLeft: (theme) => theme.spacing(2) },
              "& input": {
                paddingLeft: (theme) => theme.spacing(3.5),
                backgroundColor: "#F5F5F5",
                borderRadius: "30px",
              },
              "& fieldset": {
                border: "none",
                borderRadius: "30px",
              },
              width: "100%",
            }}
          />
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            size="large"
            sx={{
              background: "#FF414C",
              color: "white",
              fontSize: "0.8em",
              fontWeight: "700",
              borderRadius: "20px",
              paddingY: "0.7em",
              marginTop: "4em",
              boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
              ":hover": { background: "#FF414C" },
              width: "100%",
            }}
            onClick={handleSubmit}
            endIcon={<BiRightArrowAlt size={20} />}
          >
            Send{" "}
          </LoadingButton>
        </Stack>
      </Stack>
    </Modal>
  );
};
