import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { getAdvertisements } from "../../store/other/actions";
import { carouselSliderSettings } from "../../utils/Constants";
import agencyAdvertCard from "../../assets/images/agencyAdvertCard.svg";
import "../../utils/css/styles.css";

const AgenciesAdvertisements = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const { advertisements } = useSelector((state) => state.other);

  useQuery({
    queryKey: ["getAdvertisements"],
    queryFn: () => dispatch(getAdvertisements()),
  });

  const handleClick = (item) => {
    if (!ref.current) {
      window.open(item?.website, "_blank", "noreferrer");
    }
    ref.current = false;
  };

  const CustomeSlide = ({ item, index }) => {
    return (
      <div
        key={index}
        className="agency-carousal"
        style={{
          border: "1px solid red",
          cursor: "pointer",
          borderRadius: "20px",
          height: "10.5em",
          backgroundImage:
            `url(${item.advertisement_sliders[0].image_url})` ||
            `url(${agencyAdvertCard})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => handleClick(item)}
      />
    );
  };

  return (
    <div>
      {advertisements && advertisements.length ? (
        <Slider
          {...carouselSliderSettings}
          onSwipe={() => (ref.current = true)}
        >
          {advertisements.map((item, i) => {
            return <CustomeSlide item={item} index={i} />;
          })}
        </Slider>
      ) : null}
      {advertisements && advertisements.length === 0 ? (
        <Slider {...carouselSliderSettings}>
          <div style={{ border: "1px solid red" }}>
            <img
              alt=""
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              src={agencyAdvertCard}
            />
          </div>
          <div style={{ border: "1px solid red" }}>
            <img
              alt=""
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              src={agencyAdvertCard}
            />
          </div>
          <div>
            <img
              alt=""
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              src={agencyAdvertCard}
            />
          </div>
          <div>
            <img
              alt=""
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              src={agencyAdvertCard}
            />
          </div>
        </Slider>
      ) : null}
    </div>
  );
};

export default AgenciesAdvertisements;
