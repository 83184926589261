export const GET_AGENCIES = "get_agencies";
export const GET_AGENCIES_SUCCESS = "get_agencies_success";

export const ADD_REMOVE_AGENCY = "add_remove_agency";
export const ADD_REMOVE_AGENCY_SUCCESS = "add_remove_agency_success";

export const GET_WISHLIST = "get_wishlist";
export const GET_WISHLIST_SUCCESS = "get_wishlist_success";

export const GET_FREE_AGENT = "get_free_agent";
export const GET_FREE_AGENT_SUCCESS = "get_free_agent_success";

export const GET_NOTIFICATIONS = "get_notifications";
export const GET_NOTIFICATIONS_SUCCESS = "get_notifications_success";

export const GET_PAYMENT_HISTORY = "get_payment_history";
export const GET_PAYMENT_HISTORY_SUCCESS = "get_payment_history_success";

export const GET_MY_AGENTS = "get_my_agents";
export const GET_MY_AGENTS_SUCCESS = "get_my_agents_success";

export const GET_WISHLIST_AGENCIES = "get_wishlist_agencies";
export const GET_WISHLIST_AGENCIES_SUCCESS = "get_wishlist_agencies_success";

export const GET_ALL_PITCHES = "get_all_pitches";
export const GET_ALL_PITCHES_SUCCESS = "get_all_pitches_success";

export const GET_SINGLE_PITCH = "get_single_pitch";
export const GET_SINGLE_SUBMISSION = "get_single_submission";
export const GET_SINGLE_AGENT = "get_single_agent";
export const GET_SINGLE_AGENCY = "get_single_agency";

export const GET_MY_SUBMISSIONS = "get_my_submissions";
export const GET_MY_SUBMISSIONS_SUCCESS = "get_my_submissions_success";

export const UNPUBLISHED_SUBMISSION = "unpublished_submission";
export const UNPUBLISHED_SUBMISSION_SUCCESS = "unpublished_submission_success";

export const POST_FICTION_SUBMISSION = "post_fiction_submission";
export const POST_FICTION_SUBMISSION_SUCCESS =
  "post_fiction_submission_success";

export const POST_SUBMISSION = "post_submission";
export const POST_SUBMISSION_SUCCESS = "post_submission_success";

export const BOOK_DEAL = "book_deal";
export const BOOK_DEAL_SUCCESS = "book_deal_success";

export const FILTER_AGENCIES = "filter_agencies";
export const FILTER_AGENCIES_SUCCESS = "filter_agencies_success";

export const FILTER_PITCHES = "filter_pitches";
export const FILTER_PITCHES_SUCCESS = "filter_pitches_success";

export const CONTACT_US = "contact_us";
export const CONTACT_US_SUCCESS = "contact_us_success";

export const UPDATE_PROFILE = "update_profile";
export const UPDATE_PROFILE_SUCCESS = "update_profile_success";

export const DELETE_ACCOUNT = "delete_account";
export const DELETE_ACCOUNT_SUCCESS = "delete_account_success";

export const BOOK_SUBMISSION_PURCHASE = "book_submission_purchase";
export const BOOK_SUBMISSION_PURCHASE_SUCCESS = "book_submission_purchase_success";

export const ERROR = "author_error";

export const STOP_LOADING = "stop_loading";
export const START_LOADING = "start_loading";

export const GET_AGENCY_STOP_LOADING = "get_agency_stop_loading";
export const GET_AGENCY_START_LOADING = "get_agency_start_loading";

export const GET_SUBMISSION_STOP_LOADING = "get_submission_stop_loading";
export const GET_SUBMISSION_START_LOADING = "get_submission_start_loading";

export const GET_PITCHES_STOP_LOADING = "get_pitches_stop_loading";
export const GET_PITCHES_START_LOADING = "get_pitches_start_loading";

export const FIND_AGENT_BY_GENRE = "find_agent_by_genre";
export const FIND_AGENT_BY_GENRE_SUCCESS = "find_agent_by_genre_success";

export const AGENT_PAID = "agent_paid";
export const AGENT_PAID_SUCCESS = "agent_paid_success";

export const FIND_AGENT_BY_GENRE_START_LOADING =
  "find_agent_by_genre_start_loading";
export const FIND_AGENT_BY_GENRE_STOP_LOADING =
  "find_agent_by_genre_stop_loading";

export const GET_WISHLIST_AGENCY_STOP_LOADING =
  "get_wishlist_agency_stop_loading";
export const GET_WISHLIST_AGENCY_START_LOADING =
  "get_wishlist_agency_start_loading";

export const RESET_STATE = "reset_state";
export const CLEAR_AGENT = "clear_agent"

