import { Paper, ToggleButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router";
import { addRemoveAgency, getSingleAgency } from "../../store/author/actions";
import { useDispatch } from "react-redux";

const WishlistCard = ({ bg, agency }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  return (
    <Paper
      component={Stack}
      elevation={2}
      border={"1px solid #FF414C"}
      borderRadius={"14.4px !important"}
      direction={"row"}
      height="6em !important"
      position={"relative"}
    >
      <Stack
        onClick={() => {
          dispatch(getSingleAgency(agency?.agency));
          nav(`/agency-detail/${agency?.agency?.id}`);
        }}
        width={"100%"}
        direction={"row"}
        sx={{ cursor: "pointer" }}
      >
        <Stack
          sx={{
            borderRadius: "20px",
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          width={"30%"}
        >
          <img
            alt=""
            height={"100%"}
            style={{ borderRadius: "15px" }}
            src={agency?.agency?.user_profile?.profile_image}
          />
        </Stack>
        <Stack justifyContent={"center"} pl={1} width={"50%"}>
          <Typography fontWeight={"700"}>
            {agency?.agency?.full_name}
          </Typography>
          <Typography variant="caption">
            {agency?.agency?.user_countries?.length
              ? agency?.agency?.user_countries?.map((item, i) => {
                  return (
                    <span key={i}>
                      {item.name}
                      {i !== agency?.agency?.user_countries?.length - 1
                        ? ", "
                        : ""}
                    </span>
                  );
                })
              : ""}
          </Typography>
        </Stack>
      </Stack>
      <ToggleButton
        value="check"
        onChange={() =>
          dispatch(addRemoveAgency({ agency_id: agency?.agency?.id }))
        }
        sx={{
          border: "none !important",
          borderRadius: "50px !important",
          position: "absolute !important",
          right: "0 !important",
          bottom: "0 !important",
          top: "0 !important",
          ":hover": {
            bgcolor: "white",
            color: "red",
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: "white",
          },
        }}
      >
        <FavoriteIcon
          sx={{
            paddingBottom: "0.5em",
            fill: agency?.agency?.favourite_agency ? "red" : "currentcolor",
          }}
        />
      </ToggleButton>
    </Paper>
  );
};

export default WishlistCard;
