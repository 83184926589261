import React, { useEffect } from "react";
import { Stack } from "@mui/system";
import FilterBar from "../dashboard/FilterBar";
import SubmissionsTabs from "./SubmissionsTabs";
import divider from "../../assets/images/divider.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, getPlots } from "../../store/other/actions";

const SubmissionsComponent = () => {
  const dispatch = useDispatch();

  const { categories, plots } = useSelector((state) => state.other);

  useEffect(() => {
    if (!plots?.length) {
      dispatch(getPlots());
    }
    if (!categories?.length) {
      dispatch(getCategories());
    }
  }, [dispatch, plots, categories]);

  return (
    <Stack height={"100%"} direction={"row"} paddingTop={"2vh"}>
      <Stack
        width={"25%"}
        height={"100%"}
        display={{ xs: "none", md: "inherit" }}
      >
        <FilterBar />
      </Stack>
      <Stack
        width={"2%"}
        height={"100%"}
        display={{ xs: "none", md: "inherit" }}
      >
        <img
          src={divider}
          alt=""
          height={"100%"}
          style={{ objectFit: "contain" }}
        />
      </Stack>
      <Stack width={{ xs: "100%", md: "74%" }}>
        <SubmissionsTabs />
      </Stack>
    </Stack>
  );
};

export default SubmissionsComponent;
