import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Stack } from "@mui/system";
import {
  Radio,
  Modal,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  Typography,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { updateProfile } from "../../store/author/actions";
import { getCities, getStates } from "../../store/other/actions";
import { defaultModalStyle, editProfileValues } from "../../utils/Constants";
import { LoadingButton } from "@mui/lab";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import closeIcon from "../../assets/images/close-icon.svg";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  userName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  bio: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  streetAddress1: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  language: Yup.string().required("Required"),
  subgenres: Yup.array()
    .min(1, "Select at least one option")
    .max(2, "Max 2 Genres can be selected")
    .required(),
  genreType: Yup.string(),
});

export const EditProfileDlg = ({ handleClose, open }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [genresToShow, setGenresToShow] = useState([]);
  const [genreType, setGenreType] = useState("fiction");
  const [initValues, setInitValues] = useState({ ...editProfileValues });

  const { user } = useSelector((state) => state.auth);
  console.log("🚀 ~ file: EditProfileDlg.jsx:60 ~ EditProfileDlg ~ user:", user)
  const { genres, languages, countries, states, cities } = useSelector(
    (state) => state.other
  );

  useEffect(() => {
    if (genreType === "fiction") {
      if (genres && genres.fiction && genres.fiction.length) {
        setGenresToShow([...genres.fiction]);
      }
    }
    if (genreType === "nonFiction") {
      if (genres && genres.nonfiction && genres.nonfiction.length) {
        setGenresToShow([...genres.nonfiction]);
      }
    }
  }, [genres, genreType]);

  useEffect(() => {
    if (user) {
      dispatch(getStates(user.user_profile.country.id));
      dispatch(getCities(user.user_profile.state.id));

      setInitValues({
        bio: user.user_profile.bio,
        city: user.user_profile.user_city,
        country: user.user_profile.country.id,
        firstName: user.first_name,
        lastName: user.last_name,
        language: user.user_profile.language.id,
        genreType: user.is_fictional === 1 ? "fiction" : "nonFiction",
        state: user.user_profile.state.id,
        streetAddress1: user.user_profile.street_address,
        userName: user.username,
        zip: user.user_profile.zip_code,
        subgenres: user.user_genres.length
          ? user.user_genres.map((item) => {
            return {
              label: item.genre.name,
              value: item.genre_id,
              id: item.genre_id,
            };
          })
          : [],
      });
    }
  }, [dispatch, user]);

  // useEffect(() => {
  //   if (cities?.length && user?.user_profile?.user_city) {
  //     let data = cities.filter((item) => {
  //       return item.name === user.user_profile.user_city;
  //     });
  //     console.log({ data });
  //     if (data && data.length) {
  //       setInitValues({ ...initValues, city: data[0].id });
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [cities, user]);

  // console.log({ user, initValues });
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        height={"80vh"}
        spacing={"1em"}
        overflow={"auto"}
        alignItems={"center"}
        sx={defaultModalStyle}
        paddingX={"3em !important"}
        width={{ xs: "70%", sm: "40vw", md: "35vw", lg: "30vw" }}
      >
        <IconButton
          component="span"
          onClick={handleClose}
          sx={{ position: "absolute", right: "6px", top: "10px" }}
        >
          <img alt="" src={closeIcon} style={{ width: "15px" }} />
        </IconButton>
        <Typography
          variant="h4"
          fontWeight={700}
          textAlign={"center"}
          paddingBottom={"1em"}
        >
          Edit Profile
        </Typography>
        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {

            setIsLoading(true);
            let selectedCity = cities.filter((item) => {
              return item.id === Number(values.city);
            });
            let cityName =
              selectedCity && selectedCity.length
                ? selectedCity[0].name
                : values.city;
            let isFictional = values.genreType === "fiction" ? 1 : 2;
            let data = new FormData();
            data.append("first_name", values.firstName);
            data.append("last_name", values.lastName);
            data.append("username", values.userName);
            data.append("_method", "PATCH");
            data.append("country_id", values.country);
            data.append("language_id", values.language);
            data.append("is_fictional", isFictional === 1 ? true : false);
            data.append("state_id", values.state);
            data.append("city", cityName);
            data.append("street_address", values.streetAddress1);
            data.append("zip_code", values.zip);
            data.append("bio", values.bio);
            values.subgenres.forEach((item, index) => {
              data.append(`genre_ids[${index}]`, item.id);
            });
            // data.append("profile_image", "selectedPicture");

            dispatch(updateProfile({ data, setIsLoading, handleClose }));
          }}
        >
          {({ values, handleChange, field, setFieldValue }) => (
            <Form style={{ width: "100%" }}>
              <Stack width="100%" spacing={2}>
                <Stack sx={{ flexDirection: "row", gap: "1em" }}>
                  <Stack width={"50%"}>
                    <Field
                      type={"text"}
                      as={TextField}
                      name="firstName"
                      placeholder={"First Name"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        "& input": {
                          paddingLeft: (theme) => theme.spacing(3.5),
                          backgroundColor: "#F5F5F5",
                          borderRadius: "35px",
                          boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
                        },
                        "& fieldset": {
                          border: "none",
                          borderRadius: "30px",
                        },
                      }}
                    />
                    <Typography variant="body2" color="error">
                      <ErrorMessage name="firstName" />
                    </Typography>
                  </Stack>

                  <Stack width={"50%"}>
                    <Field
                      fullWidth
                      type={"text"}
                      as={TextField}
                      name="lastName"
                      placeholder={"Last Name"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        "& input": {
                          paddingLeft: (theme) => theme.spacing(3.5),
                          backgroundColor: "#F5F5F5",
                          borderRadius: "35px",
                          boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
                        },
                        "& fieldset": {
                          border: "none",
                          borderRadius: "30px",
                        },
                      }}
                    />
                    <Typography variant="body2" color="error">
                      <ErrorMessage name="lastName" />
                    </Typography>
                  </Stack>
                </Stack>
                <Field
                  type={"text"}
                  as={TextField}
                  name="userName"
                  placeholder={"User Name"}
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2),
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5),
                      backgroundColor: "#F5F5F5",
                      borderRadius: "35px",
                      boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
                    },
                    "& fieldset": {
                      border: "none",
                      borderRadius: "30px",
                    },
                  }}
                />
                <Typography variant="body2" color="error">
                  <ErrorMessage name="userName" />
                </Typography>
                <Field
                  name="bio"
                  type={"text"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline={true}
                      rows={4}
                      placeholder={"Bio"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        "& fieldset": {
                          border: "none",
                          borderRadius: "30px",
                        },
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                        boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",

                        paddingLeft: "1em",
                      }}
                    />
                  )}
                />
                <Typography variant="body2" color="error">
                  <ErrorMessage name="bio" />
                </Typography>
                <RadioGroup
                  {...field}
                  name={"genreType"}
                  value={values.genreType}
                  onChange={(e) => {
                    setGenreType(e.target.value);
                    setFieldValue("subgenres", "");
                    handleChange(e);
                  }}
                  sx={{
                    gap: "1em",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <FormControlLabel
                    value={"fiction"}
                    control={<Radio />}
                    label={"Fiction"}
                    sx={{
                      backgroundColor: "#F5F5F5",
                      borderRadius: "35px",
                      boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
                      paddingY: "1em",
                      paddingLeft: "1em",
                      width: { sm: "100%", md: "45%" },
                      margin: 0,
                      color: "#C0C0C0",
                      fontSize: "0.7vw",
                      outline:
                        values.genreType === "fiction"
                          ? "2px solid #FF414C"
                          : "none",
                    }}
                  />
                  <FormControlLabel
                    value={"nonFiction"}
                    control={<Radio />}
                    label={"Nonfiction"}
                    sx={{
                      backgroundColor: "#F5F5F5",
                      borderRadius: "35px",
                      boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
                      paddingY: "1em",
                      paddingLeft: "1em",
                      width: { sm: "100%", md: "45%" },
                      margin: 0,
                      color: "#C0C0C0",
                      fontSize: "0.7vw",
                      outline:
                        values.genreType === "nonFiction"
                          ? "2px solid #FF414C"
                          : "none",
                    }}
                  />
                </RadioGroup>
                <Field
                  as={MultiSelect}
                  name={"subgenres"}
                  options={genresToShow}
                  value={values.subgenres}
                  handleChange={setFieldValue}
                />
                <Typography variant="body2" color="error">
                  <ErrorMessage name="subgenres" />
                </Typography>
                <Field
                  as={Select}
                  name={"language"}
                  value={values.language}
                  onChange={handleChange}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "Language"
                      ? {
                        content: `"Language"`,
                        opacity: 0.42,
                      }
                      : {},
                    "& fieldset": {
                      border: "none",
                      borderRadius: "30px",
                    },
                    border: "none",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "35px",
                  }}
                >
                  {languages && languages.length
                    ? languages.map((item, i) => {
                      return (
                        <MenuItem value={item.id} key={i}>
                          {item.name}
                        </MenuItem>
                      );
                    })
                    : []}
                </Field>
                <Typography variant="body2" color="error">
                  <ErrorMessage name="language" />
                </Typography>
                <Field
                  as={Select}
                  name={"country"}
                  value={values.country}
                  onChange={(e) => {
                    dispatch(getStates(e.target.value));
                    // fetching cities with no id to reset cities state in store
                    dispatch(getCities());
                    handleChange(e);
                  }}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "Country"
                      ? {
                        content: `"Country"`,
                        opacity: 0.42,
                      }
                      : {},
                    "& fieldset": {
                      border: "none",
                      borderRadius: "30px",
                    },
                    border: "none",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "35px",
                  }}
                >
                  {countries && countries.length
                    ? countries.map((item, i) => {
                      return (
                        <MenuItem value={item.id} key={i}>
                          {item.name}
                        </MenuItem>
                      );
                    })
                    : []}
                </Field>
                <Typography variant="body2" color="error">
                  <ErrorMessage name="country" />
                </Typography>
                <Field
                  as={Select}
                  name={"state"}
                  value={values.state}
                  onChange={(e) => {
                    dispatch(getCities(e.target.value));
                    handleChange(e);
                  }}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "State/Province"
                      ? {
                        content: `"State/Province"`,
                        opacity: 0.42,
                      }
                      : {},
                    "& fieldset": {
                      border: "none",
                      borderRadius: "30px",
                    },
                    border: "none",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "35px",
                  }}
                >
                  {states && states.length
                    ? states.map((item, i) => {
                      return (
                        <MenuItem value={item.id} key={i}>
                          {item.name}
                        </MenuItem>
                      );
                    })
                    : []}
                </Field>
                <Typography variant="body2" color="error">
                  <ErrorMessage name="state" />
                </Typography>
                {/* <Field
                  as={Select}
                  name={"city"}
                  value={values.city}
                  onChange={handleChange}
                  sx={{
                    "& .MuiSelect-select .notranslate::after": "City"
                      ? {
                          content: `"City"`,
                          opacity: 0.42,
                        }
                      : {},
                    "& fieldset": {
                      border: "none",
                      borderRadius: "30px",
                    },
                    border: "none",
                    backgroundColor: "#F5F5F5",
                    borderRadius: "35px",
                  }}
                >
                  {cities && cities.length
                    ? cities.map((item, i) => {
                        return (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        );
                      })
                    : []}
                </Field> */}
                <Field
                  type={"text"}
                  as={TextField}
                  name="city"
                  placeholder={"City"}
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2),
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5),
                      backgroundColor: "#F5F5F5",
                      borderRadius: "35px",
                      boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
                    },
                    "& fieldset": {
                      border: "none",
                      borderRadius: "30px",
                    },
                  }}
                />
                <Typography variant="body2" color="error">
                  <ErrorMessage name="city" />
                </Typography>
                <Field
                  type={"text"}
                  as={TextField}
                  name="streetAddress1"
                  placeholder={"Street Address"}
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2),
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5),
                      backgroundColor: "#F5F5F5",
                      borderRadius: "35px",
                      boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
                    },
                    "& fieldset": {
                      border: "none",
                      borderRadius: "30px",
                    },
                  }}
                />
                <Typography variant="body2" color="error" gutterBottom>
                  <ErrorMessage name="streetAddress1" />
                </Typography>

                <Field
                  type={"text"}
                  as={TextField}
                  name="zip"
                  placeholder={"Zip/Postal Code"}
                  sx={{
                    "& label": {
                      paddingLeft: (theme) => theme.spacing(2),
                    },
                    "& input": {
                      paddingLeft: (theme) => theme.spacing(3.5),
                      backgroundColor: "#F5F5F5",
                      borderRadius: "35px",
                      boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.15)",
                    },
                    "& fieldset": {
                      border: "none",
                      borderRadius: "30px",
                    },
                    width: "50%",
                  }}
                />
                <Typography variant="body2" color="error">
                  <ErrorMessage name="zip" />
                </Typography>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  size="large"
                  sx={{
                    alignSelf: "center",
                    background: "#FF414C",
                    color: "white",
                    fontSize: "0.8em",
                    fontWeight: "700",
                    borderRadius: "20px",
                    width: "80%",
                    paddingY: "0.7em",
                    marginTop: "4em",
                    ":hover": { background: "#FF414C" },
                    boxShadow: "0px 3.9px 19.9px 0px rgba(255, 0, 0, 0.25)",
                  }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
};
