import React, { useState } from "react";
import * as Yup from "yup";
import { Stack } from "@mui/system";
import { IconButton, Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { contactUs } from "../../store/author/actions";
import { Field, Form, Formik } from "formik";
import { TextField, Typography } from "@mui/material";
import { defaultModalStyle } from "../../utils/Constants";
import { LoadingButton } from "@mui/lab";
import closeIcon from "../../assets/images/close-icon.svg";
import axios from "axios";
import { toast } from "react-hot-toast";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Enter a valid Email").required("Required"),
  description: Yup.string()
    .required("Required"),
});

export const ContactUsDlg = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const token = sessionStorage.getItem("bw-token")

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing="1em"
        alignItems={"center"}
        sx={defaultModalStyle}
        width={{ xs: "90%", sm: "35vw" }}
      >
        <Stack width={"80%"} justifyContent={"center"} alignItems={"center"}>
          <IconButton
            component="span"
            onClick={handleClose}
            sx={{ position: "absolute", right: "6px", top: "10px" }}
          >
            <img alt="" src={closeIcon} style={{ width: "15px" }} />
          </IconButton>
          <Typography variant="h4" textAlign={"center"} fontWeight={"700"}>
            Send us a message!
          </Typography>
          <Typography textAlign={"center"}>
            How can we help you today?
          </Typography>
          <Formik
            initialValues={{
              name: "",
              email: "",
              description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              console.log("🚀 ~ file: ContactUsDlg.jsx:64 ~ ContactUsDlg ~ values:", values)
              setIsLoading(true);
              let formData = new FormData()
              formData.append("name", values.name)
              formData.append("email", values.email)
              formData.append("description", values.description)
              axios.post('https://admin.bookwizardhub.com/api/author/send-message', formData, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              }).then(() => {
                toast.success("Message Sent Successfully")
                setIsLoading(false)
                resetForm();

              })
              // dispatch(contactUs({ values, handleClose, setIsLoading }));
            }}
          >
            {({ values, errors }) => (
              <Form style={{ width: "100%" }}>
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Field
                    type={"text"}
                    as={TextField}
                    name="name"
                    error={errors.name}
                    placeholder={"Name"}
                    sx={{
                      "& label": {
                        paddingLeft: (theme) => theme.spacing(2),
                      },
                      "& input": {
                        paddingLeft: (theme) => theme.spacing(3.5),
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                      },
                      "& fieldset": {
                        border: errors.name ? "1px solid" : "none",
                        borderRadius: "30px",
                      },
                      width: "100%",
                      marginTop: "1em",
                    }}
                  />
                  <Field
                    type={"email"}
                    as={TextField}
                    name="email"
                    error={errors.email}
                    placeholder={"Email"}
                    sx={{
                      "& label": {
                        paddingLeft: (theme) => theme.spacing(2),
                      },
                      "& input": {
                        paddingLeft: (theme) => theme.spacing(3.5),
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                      },
                      "& fieldset": {
                        border: errors.email ? "1px solid" : "none",
                        borderRadius: "30px",
                      },
                      width: "100%",
                      marginTop: "0.5em !important",
                    }}
                  />
                  {console.log(errors.description)}
                  <Field
                    name="description"
                    type={"text"}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        multiline={true}
                        rows={5}
                        error={errors.description}
                        placeholder={"Description"}
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& fieldset": {
                            border: errors.description ? "1px solid" : "none",
                            borderRadius: "15px",
                          },
                          backgroundColor: "#F5F5F5",
                          borderRadius: "15px",
                          marginTop: "0.5em !important",
                        }}
                      />
                    )}
                  />
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                    size="large"
                    sx={{
                      background: "#FF414C",
                      color: "white",
                      fontSize: "0.8em",
                      fontWeight: "700",
                      borderRadius: "20px",
                      paddingY: "0.7em",
                      boxShadow:
                        "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
                      ":hover": { background: "#FF414C" },
                      width: "80%",
                      alignSelf: "center",
                    }}
                  >
                    Send Message
                  </LoadingButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Stack>
    </Modal>
  );
};
