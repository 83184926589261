import React, { useState } from "react";
import { Stack } from "@mui/system";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router";
import MenuIcon from "@mui/icons-material/Menu";
import BWLogo from "../assets/images/BWLogo.svg";
import settings from "../assets/images/settings.svg";
import { Notifications } from "./Notifications";
import { ContactUsDlg } from "../components/dialogs/ContactUsDlg";
import { logoutUser } from "../store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { helpList, pages, settingsList } from "../utils/Constants";
import { PaymentHistoryDlg } from "../components/dialogs/PaymentHistoryDlg";
import { AgentMatchingDlg } from "../components/dialogs/AgentMatchingDlg";
import { HelpCenterDlg } from "../components/dialogs/HelpCenterDlg";
import { handleActiveTab } from "../utils/Helpers";
import { AlertDialog } from "../components/dialogs/AlertDialog";
import logoutIcon from "../assets/images/logout.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { resetAuthorState } from "../store/author/actions";

function Header() {
  const dispatch = useDispatch();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElHelp, setAnchorElHelp] = useState(null);
  const [confirmLogoutDlg, setConfirmLogoutDlg] = useState(false);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [showContactUsDlg, setShowContactUsDlg] = useState(false);
  const [showAgentMatchingDlg, setShowAgentMatchingDlg] = useState(false);
  const [showPaymentHistoryDlg, setShowPaymentHistoryDlg] = useState(false);
  const [showHelpCenterDlg, setShowHelpCenterDlg] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const handleOpenHelp = (event) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleCloseHelp = (e) => {
    setAnchorElHelp(null);
  };

  const handleHelpClick = (e) => {
    let value = e.target.innerText;
    if (value === "Privacy Policy") window.open("https://bookwizard.com/privacy-policy/", "_blank", "noreferrer");
    if (value === "About Us") nav("/about-us");
    if (value === "Contact Us") setShowContactUsDlg(true);
    if (value === "Help Center") setShowHelpCenterDlg(true);

    setAnchorElHelp(null);
  };

  const handleSettingsClick = (e) => {
    let value = e.target.innerText;
    if (value === "Submissions") nav("/all-submissions");
    if (value === "Payment History") setShowPaymentHistoryDlg(true);
    if (value === "Query Submission") setShowAgentMatchingDlg(true);
    if (value === "Logout") setConfirmLogoutDlg(true);
    setAnchorElSettings(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };
  const nav = useNavigate();
  const handleNav = (e, route) => {
    if (route === "HelpCenter") {
      // setShowHelpCenterDlg(true);
      handleOpenHelp(e);
    } else if (route === "Resources") {
      window.open("https://bookwizard.com/resources/", "_blank", "noreferrer");
    } else {
      nav(route);
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "#FF414C",
        height: { xs: "9vh" },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="30000px" sx={{ px: { xs: ".5em", md: "1em" } }}>
        <Toolbar disableGutters sx={{ position: "relative" }}>
          <Stack
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 1,
              cursor: "pointer",
              zIndex: 1,
            }}
            onClick={() => nav("/")}
          >
            <img src={BWLogo} alt="" width={"50%"} />
          </Stack>

          {/* xs hamburger menu */}
          <Stack sx={{ display: { xs: "inherit", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((item, index) => (
                <MenuItem key={index} onClick={(e) => handleNav(e, item.route)} >
                  <Typography textAlign="center">{item.page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Stack>
          {/* xs logo*/}
          <Stack
            position={"absolute"}
            width={"fit-content"}
            right="0"
            left="0"
            margin={"0 auto"}
            onClick={() => nav("/")}
            alignItems={"center"}
            sx={{
              display: { xs: "flex", md: "none", cursor: "pointer" },
            }}
          >
            <img src={BWLogo} alt="" width={"50%"} />
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            position={"absolute"}
            right="0"
            left="0"
            sx={{
              display: { xs: "none", md: "inherit" },
            }}
          >
            {pages.map((item, index) => (
              <Button
                key={index}
                sx={{ color: "white" }}
                onClick={(e) => handleNav(e, item.route)}
                endIcon={
                  item.page === "Help Center" ? (
                    Boolean(anchorElHelp) ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  ) : (
                    ""
                  )
                }
              >
                <Typography
                  fontSize={"1.03em"}
                  textTransform={"capitalize"}
                  fontWeight={() => handleActiveTab(item.page)}
                >
                  {item.page}
                </Typography>
              </Button>
            ))}
          </Stack>
          {/* help center menu */}
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElHelp}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElHelp)}
            onClose={handleCloseHelp}
          >
            {helpList.map((item) => (
              <MenuItem key={item} onClick={handleHelpClick}>
                <Typography textAlign="center">{item}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            position={"absolute"}
            spacing={1}
            right="0"
            sx={{
              display: { xs: "inherit", md: "inherit" },
            }}
          >
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="">
                <IconButton onClick={() => nav("/user-profile")} sx={{ p: 0 }}>
                  <Avatar
                    alt={user?.first_name}
                    sx={{ width: 28, height: 28 }}
                    src={
                      user?.user_profile?.profile_image
                        ? user?.user_profile?.profile_image
                        : "/static/images/avatar/2.jpg"
                    }
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Notifications />

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenSettings} sx={{ p: 0 }}>
                  <img src={settings} alt="" width={"70%"} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElSettings}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElSettings)}
                onClose={handleCloseSettings}
              >
                {settingsList.map((setting) => (
                  <MenuItem key={setting} onClick={handleSettingsClick}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Stack>
        </Toolbar>
      </Container>
      <ContactUsDlg
        open={showContactUsDlg}
        handleClose={() => setShowContactUsDlg(false)}
      />
      <PaymentHistoryDlg
        open={showPaymentHistoryDlg}
        handleClose={() => setShowPaymentHistoryDlg(false)}
      />
      <AgentMatchingDlg
        open={showAgentMatchingDlg}
        handleClose={() => setShowAgentMatchingDlg(false)}
      />
      <HelpCenterDlg
        open={showHelpCenterDlg}
        handleClose={() => setShowHelpCenterDlg(false)}
      />
      <AlertDialog
        primaryButton={
          <Typography
            textTransform={"capitalize"}
            display={"flex"}
            alignItems={"center"}
            gap={"0.2em"}
          >
            <img alt="" src={logoutIcon} width={"20px"} />
            Log Out
          </Typography>
        }
        secondaryButton={"Cancel"}
        secondaryStyle={{ color: "#C0C0C0" }}
        primaryStyle={{ color: "#FF0000", fontWeight: 700 }}
        open={confirmLogoutDlg}
        heading={"Are you sure?"}
        text={<Typography textAlign={"center"}>You want to logout.</Typography>}
        handleClose={() => setConfirmLogoutDlg(false)}
        secondaryAction={() => setConfirmLogoutDlg(false)}
        primaryAction={() => {
          dispatch(resetAuthorState());
          dispatch(logoutUser());
          nav("/login");
          setConfirmLogoutDlg(false);
        }}
        buttonWidth={"95%"}
      />
    </AppBar>
  );
}
export default Header;
