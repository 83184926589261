import { Stack } from "@mui/system";
import React from "react";
import Header from "./Header";

const Layout = ({ children }) => {
  return (
    <Stack minHeight={"100vh"} bgcolor={"#FBFBFB"}>
      <Header />
      {children}
    </Stack>
  );
};

export default Layout;
