import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { AlertDialog } from "../components/dialogs/AlertDialog";
import { UpdatePhoneDlg } from "../components/dialogs/UpdatePhoneDlg";
import { UpdateEmailDlg } from "../components/dialogs/UpdateEmailDlg";
import { EditProfileDlg } from "../components/dialogs/EditProfileDlg";
import { UpdatePasswordDlg } from "../components/dialogs/UpdatePasswordDlg";
import { deleteAccount } from "../store/author/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const options = [
  "Edit Profile",
  "Update Email",
  "Update Phone",
  "Update Password",
  "Delete My Account",
];

const ITEM_HEIGHT = 48;

export const UserProfileMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showUpdatePhoneDlg, setShowUpdatePhoneDlg] = useState(false);
  const [showUpdateEmailDlg, setShowUpdateEmailDlg] = useState(false);
  const [showEditProfileDlg, setShowEditProfileDlg] = useState(false);
  const [showDeleteAccountDlg, setShowDeleteAccountDlg] = useState(false);
  const [showUpdatePasswordDlg, setShowUpdatePasswordDlg] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (e) => {
    let value = e.target.innerText;
    if (value === "Update Phone") setShowUpdatePhoneDlg(true);
    if (value === "Update Email") setShowUpdateEmailDlg(true);
    if (value === "Edit Profile") setShowEditProfileDlg(true);
    if (value === "Update Password") setShowUpdatePasswordDlg(true);
    if (value === "Delete My Account") setShowDeleteAccountDlg(true);

    setAnchorEl(null);
  };

  const handleDeleteAccount = () => {
    dispatch(deleteAccount({ navigate }));
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={handleOptionClick}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <UpdatePhoneDlg
        open={showUpdatePhoneDlg}
        handleClose={() => setShowUpdatePhoneDlg(false)}
      />
      <UpdatePasswordDlg
        open={showUpdatePasswordDlg}
        handleClose={() => setShowUpdatePasswordDlg(false)}
      />
      <UpdateEmailDlg
        open={showUpdateEmailDlg}
        handleClose={() => setShowUpdateEmailDlg(false)}
      />
      <EditProfileDlg
        open={showEditProfileDlg}
        handleClose={() => setShowEditProfileDlg(false)}
      />
      <AlertDialog
        primaryButton={"Delete"}
        secondaryButton={"Cancel"}
        secondaryStyle={{ color: "#C0C0C0" }}
        primaryStyle={{ color: "#FF0000", fontWeight: 700 }}
        open={showDeleteAccountDlg}
        heading={"Are you sure you want to delete account?"}
        handleClose={() => setShowDeleteAccountDlg(false)}
        primaryAction={handleDeleteAccount}
        secondaryAction={() => setShowDeleteAccountDlg(false)}
      />
    </div>
  );
};
