import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  IconButton,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { Stack } from "@mui/system";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { BiRightArrowAlt } from "react-icons/bi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { resetPassword } from "../../store/auth/actions";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [values, setValues] = useState({ password: "", confirmPassword: "" });

  const { resetPasswordEmialVerfiied } = useSelector((state) => state.auth);

  let email = sessionStorage.getItem("forget-password-email");

  const handleSubmit = () => {
    if (values.password.length >= 8) {
      if (values.password === values.confirmPassword) {
        setIsLoading(true);
        let data = new FormData();
        data.append("email", email);
        data.append("password", values.password);
        dispatch(resetPassword({ data, navigate, setIsLoading }));
      } else {
        toast.error("Passwords Should Match");
      }
    } else {
      toast.error("Passwords Should Be 8 or more Characters");
    }
  };

  useEffect(() => {
    if (!resetPasswordEmialVerfiied) {
      // navigate("/forget-password");
    }
  }, [navigate, resetPasswordEmialVerfiied]);

  return (
    <Stack justifyContent={"center"} alignItems={"center"} height={"100vh"}>
      <Stack width={{ xs: "70%", sm: "45%", md: "40%", lg: "25%" }}>
        <Typography variant="h4" fontWeight={"700"} textAlign={"center"}>
          Reset Password
        </Typography>
        <Typography textAlign={"center"}>
          Your new password must be different from your previous used passwords
        </Typography>
        <FormControl
          sx={{
            m: 1,
            border: "none",
            marginTop: "3em",
            borderRadius: "30px",
            backgroundColor: "#F5F5F5",
          }}
        >
          <TextField
            value={values.password}
            placeholder="New Password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setValues({ ...values, password: e.target.value })}
            sx={{
              "& label": { paddingLeft: (theme) => theme.spacing(2) },
              "& input": {
                paddingLeft: (theme) => theme.spacing(3.5),
                borderRadius: "30px",
              },
              "& fieldset": {
                border: "none",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Typography variant="caption" color="#C0C0C0" paddingLeft={"2em"}>
          Password must be atleast 8 characters
        </Typography>

        <Stack paddingTop={"1em"}>
          <FormControl
            sx={{
              m: 1,
              border: "none",
              borderRadius: "30px",
              backgroundColor: "#F5F5F5",
            }}
          >
            <TextField
              placeholder="Re-Enter New Password"
              type={showConfirmPassword ? "text" : "password"}
              value={values.confirmPassword}
              onChange={(e) =>
                setValues({ ...values, confirmPassword: e.target.value })
              }
              sx={{
                "& label": { paddingLeft: (theme) => theme.spacing(2) },
                "& input": {
                  paddingLeft: (theme) => theme.spacing(3.5),
                  borderRadius: "30px",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <AiFillEye />
                      ) : (
                        <AiFillEyeInvisible />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Typography variant="caption" color="#C0C0C0" paddingLeft={"2em"}>
            Both passwords must match
          </Typography>
        </Stack>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="submit"
          size="large"
          sx={{
            color: "white",
            paddingY: "1em",
            marginTop: "5em",
            fontSize: "0.8em",
            fontWeight: "700",
            borderRadius: "20px",
            background: "#FF414C",
            ":hover": { background: "#FF414C" },
            boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
          }}
          onClick={handleSubmit}
          endIcon={<BiRightArrowAlt size={20} />}
        >
          Reset{" "}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
