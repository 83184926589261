import {
  LOGIN,
  LOGOUT,
  STOP_LOADING,
  START_LOADING,
  LOGIN_SUCCESS,
  ERROR,
  SIGN_UP_STEP_ONE,
  SIGN_UP_STEP_ONE_SUCCESS,
  SIGN_UP_STEP_TWO,
  SIGN_UP_STEP_TWO_SUCCESS,
  VERIFY_STEP_ONE,
  VERIFY_STEP_ONE_SUCCESS,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_VERIFICATION,
  UPDATE_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_PHONE_NUMBER,
  UPDATE_PHONE_NUMBER_SUCCESS,
  UPDATE_PHONE_VERIFICATION,
  UPDATE_PHONE_VERIFICATION_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  GET_UPDATED_AUTHOR_PROFILE,
  GET_UPDATED_AUTHOR_PROFILE_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_VERIFY,
  FORGET_PASSWORD_VERIFY_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  CLEAR_AGENT,
  FETCH_USER,
  FETCH_USER_SUCCESS,
} from "./actionTypes";

export const loginUser = (data) => {
  return {
    type: LOGIN,
    payload: data,
  };
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

export const fetchUser = () => {
  return {
    type: FETCH_USER,
  };
};

export const fetchUserSuccess = (data) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: data,
  };
};

export const signUpStepOne = (data) => {
  return {
    type: SIGN_UP_STEP_ONE,
    payload: data,
  };
};

export const signUpStepOneSuccess = (data) => {
  return {
    type: SIGN_UP_STEP_ONE_SUCCESS,
    payload: data,
  };
};

export const verifySignUpStepOne = (data) => {
  return {
    type: VERIFY_STEP_ONE,
    payload: data,
  };
};

export const verifySignUpStepOneSuccess = (data) => {
  return {
    type: VERIFY_STEP_ONE_SUCCESS,
    payload: data,
  };
};

export const signUpStepTwo = (data) => {
  return {
    type: SIGN_UP_STEP_TWO,
    payload: data,
  };
};

export const signUpStepTwoSuccess = (data) => {
  return {
    type: SIGN_UP_STEP_TWO_SUCCESS,
    payload: data,
  };
};

export const updateEmail = (data) => {
  return {
    type: UPDATE_EMAIL,
    payload: data,
  };
};

export const updateEmailSuccess = (data) => {
  return {
    type: UPDATE_EMAIL_SUCCESS,
    payload: data,
  };
};

export const updateEmailVerify = (data) => {
  return {
    type: UPDATE_EMAIL_VERIFICATION,
    payload: data,
  };
};

export const updateEmailVerifySuccess = (data) => {
  return {
    type: UPDATE_EMAIL_VERIFICATION_SUCCESS,
    payload: data,
  };
};

export const updatePhoneNumber = (data) => {
  return {
    type: UPDATE_PHONE_NUMBER,
    payload: data,
  };
};

export const updatePhoneNumberSuccess = (data) => {
  return {
    type: UPDATE_PHONE_NUMBER_SUCCESS,
    payload: data,
  };
};

export const updatePhoneNumberVerify = (data) => {
  return {
    type: UPDATE_PHONE_VERIFICATION,
    payload: data,
  };
};

export const updatePhoneNumberVerifySuccess = (data) => {
  return {
    type: UPDATE_PHONE_VERIFICATION_SUCCESS,
    payload: data,
  };
};

export const updatePassword = (data) => {
  return {
    type: UPDATE_PASSWORD,
    payload: data,
  };
};

export const updatePasswordSuccess = (data) => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const forgetPassword = (data) => {
  return {
    type: FORGET_PASSWORD,
    payload: data,
  };
};

export const forgetPasswordSuccess = (data) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const forgetPasswordVerify = (data) => {
  return {
    type: FORGET_PASSWORD_VERIFY,
    payload: data,
  };
};

export const forgetPasswordVerifySuccess = (data) => {
  return {
    type: FORGET_PASSWORD_VERIFY_SUCCESS,
    payload: data,
  };
};

export const resetPassword = (data) => {
  return {
    type: RESET_PASSWORD,
    payload: data,
  };
};

export const resetPasswordSuccess = (data) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const getUpdatedAuthorProfile = (data) => {
  return {
    type: GET_UPDATED_AUTHOR_PROFILE,
    payload: data,
  };
};

export const getUpdatedAuthorProfileSuccess = (data) => {
  return {
    type: GET_UPDATED_AUTHOR_PROFILE_SUCCESS,
    payload: data,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT,
  };
};

export const errorHandler = (data) => ({
  type: ERROR,
  payload: data,
});

export const startLoading = (data) => ({
  type: START_LOADING,
  payload: data,
});

export const stopLoading = (data) => ({
  type: STOP_LOADING,
  payload: {
    action: data,
  },
});
