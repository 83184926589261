import React from "react";
import { IconButton, Modal, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentHistory } from "../../store/author/actions";
import { defaultModalStyle } from "../../utils/Constants";
import { useQuery } from "@tanstack/react-query";
import closeIcon from "../../assets/images/close-icon.svg";
import moment from "moment";

export const PaymentHistoryDlg = ({ handleClose, open }) => {
  const dispatch = useDispatch();

  const { paymentHistory } = useSelector((state) => state.author);

  useQuery({
    queryKey: ["getPaymentHistory"],
    queryFn: () => dispatch(getPaymentHistory()),
  });
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing="1em"
        alignItems={"center"}
        sx={defaultModalStyle}
        width={{ xs: "90%", sm: "45vw", md: "30vw" }}
      >
        <IconButton
          component="span"
          onClick={handleClose}
          sx={{ position: "absolute", right: "6px", top: "10px" }}
        >
          <img alt="" src={closeIcon} style={{ width: "15px" }} />
        </IconButton>
        <Typography fontWeight={700} variant={"h4"} mt={"0.3em !important"}>
          Payment History
        </Typography>

        <Stack width={"100%"} height={"53vh"} overflow={"auto"}>
          {paymentHistory?.length ? (
            paymentHistory.map((item, i) => {
              return (
                <Stack
                  key={i}
                  sx={{ borderBottom: "1px solid #E4E4E4", paddingY: "1em" }}
                >
                  <Typography color={"#888888"} variant={"caption"}>
                    ID#{item.transaction_id}
                  </Typography>
                  <Typography fontWeight={700}>
                    Submission Pack x{item.no_of_submissions}
                  </Typography>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography color={"#888888"}>
                      {moment(item.created_at).format("MMMM d YYYY")}
                    </Typography>
                    <Typography
                      color={"#FF414C"}
                      fontWeight={700}
                      variant={"h5"}
                    >
                      {item?.price}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })
          ) : (
            <Typography textAlign={"center"}>No Data Found</Typography>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};
