import { Avatar, Chip, Grid, Typography , Box } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import TruncatedText from "../reuseableFunctions/TruncatedText";
import { useNavigate } from "react-router";
import SubmissionActions from "../submissions/SubmissionActions";
import { useDispatch } from "react-redux";
import { getSingleSubmission } from "../../store/author/actions";
import { TimeAgo } from "../../utils/Helpers";

const MySubmissionCard = ({ submission }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Stack
        p={2}
        spacing={2}
        bgcolor={"white"}
        sx={{ cursor: "pointer" }}
        borderRadius={"16.1431px"}
        border={"1px solid #FF414C"}
        onClick={() => {
          dispatch(getSingleSubmission(submission));
          nav(`/all-submissions/my-submissions/${submission?.id}`);
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} spacing={1}>
            <Avatar
              alt={submission?.user?.user_profile?.full_name}
              src={submission?.user?.user_profile?.profile_image}
            />
            <Stack>
              <Typography component={"div"} fontWeight={"700"}>
                {submission?.user?.full_name}
              </Typography>
              <Typography variant="caption" color={"#C0C0C0"}>
                {TimeAgo(new Date(submission?.created_at))}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            {submission?.status === "booked" ? (
              <Stack
                onClick={(e) => e.stopPropagation()}
                sx={{
                  color: "#6DD96B",
                  paddingX: "10px",
                  justifyContent: "center",
                  borderRadius: "12.1691px",
                  border: "1px solid #6DD96B",
                  height: "100%",
                }}
              >
                Book Deal
              </Stack>
            ) : submission?.status === "unpublished" ? (
              <Stack
                onClick={(e) => e.stopPropagation()}
                sx={{
                  color: "#6DD96B",
                  paddingX: "10px",
                  justifyContent: "center",
                  borderRadius: "12.1691px",
                  border: "1px solid #6DD96B",
                }}
              >
                UnPublished
              </Stack>
            ) : (
              <Stack onClick={(e) => e.stopPropagation()}>
                <SubmissionActions submission={submission} />
              </Stack>
            )}
            {submission?.status === "pending" && (
              <Stack
                onClick={(e) => e.stopPropagation()}
                sx={{
                  color: "#00A3FF",
                  justifyContent: "center",
                }}
              >
                Draft
              </Stack>
            )}
          </Stack>
        </Stack>

        <Grid container xs={12} spacing={1}>
          <Grid  item xs={12} md={9}>
            <Stack direction={"row"} spacing={1}>
              
              <Typography fontWeight={"700"} variant={"caption"}>
                Pitch
              </Typography>

              <Chip
                size="small"
                label={`PID#${submission?.pid}`}
                variant="outlined"
                sx={{
                  color: "#FFA63E",
                  border: "1px solid #FFA63E",
                  borderRadius: "5px",
                }}
              />
              <Box  >  <TruncatedText text={submission?.status} limit={"97"} /></Box>
              
            </Stack>
           

            <TruncatedText text={submission?.pitch} limit={"97"} />
          </Grid>
          <Grid item xs={3}>
            <Stack spacing={1} height={"100%"}>
              <Stack>
                <Typography fontWeight={"700"} variant={"caption"}>
                  Genres
                </Typography>
                <Typography variant={"caption"}>
                  {submission?.genres?.map((item, i) => {
                    return (
                      <p style={{ margin: 0 }} key={i}>
                        {item?.name}
                      </p>
                    );
                  })}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"} variant={"caption"}>
                  Category
                </Typography>
                <Typography variant={"caption"}>A 18+</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"} variant={"caption"}>
                  Country
                </Typography>
                <Typography variant={"caption"}>
                  {submission?.countries?.map((item, i) => {
                    return (
                      <p style={{ margin: 0 }} key={i}>
                        {item?.name}
                      </p>
                    );
                  })}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"} variant={"caption"}>
                  Language
                </Typography>
                <Typography variant={"caption"}>
                  {submission?.languages?.map((item, i) => {
                    return (
                      <p style={{ margin: 0 }} key={i}>
                        {item?.name}
                      </p>
                    );
                  })}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default MySubmissionCard;
