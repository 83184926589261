import React from "react";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import TruncatedText from "../reuseableFunctions/TruncatedText";
import { TimeAgo } from "../../utils/Helpers";

const AllPitchesDetails = () => {
  const nav = useNavigate();
  const { singlePitch } = useSelector((state) => state.author);

  const handleNav = () => {
    sessionStorage.setItem("lastActiveTab", 0);
    setTimeout(() => {
      nav(-1);
    }, 200);
  };

  return (
    <>
      <Stack
        my={2}
        width={"100%"}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} spacing={1}>
          <Avatar
            alt={singlePitch?.user?.user_profile?.full_name}
            src={singlePitch?.user?.user_profile?.profile_image}
          />
          <Stack>
            <Typography fontWeight={"700"}>
              {singlePitch?.user?.full_name}
            </Typography>
            <Typography variant="caption" color={"#C0C0C0"}>
              {TimeAgo(new Date(singlePitch?.created_at))}
            </Typography>
          </Stack>
        </Stack>
        <Stack alignItems={"end"}>
          <Button
            sx={{
              border: "1px solid #6DD96B",
              color: "#6DD96B",
              borderRadius: "12.1691px",
              fontFamily: "Source Sans Pro",
              width: "13vw",
              px: { sm: 5, md: 3, lg: 5 },
            }}
          >
            Book Deal
          </Button>
        </Stack>
      </Stack>
      {/* Page Content */}
      <Grid container xs={12} sx={{ height: "65vh", overflow: "auto" }}>
        <Grid item xs={12} md={8}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={3}>
            <Stack width={{ xs: "100%", md: "50%" }} spacing={4}>
              <Stack>
                <Typography fontWeight={"700"}>Title</Typography>
                <Typography> {singlePitch?.title}</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Pitch</Typography>
                <TruncatedText text={singlePitch?.pitch} limit={"55"} />
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>
                  Potential Target Audience
                </Typography>
                {singlePitch?.target_audience}
              </Stack>
            </Stack>
            <Stack width={{ xs: "100%", md: "50%" }} spacing={2}>
              <Stack>
                <Typography fontWeight={"700"}>Genres</Typography>
                {singlePitch?.genres.map((item, i) => {
                  return <Typography key={i}>{item?.name}</Typography>;
                })}
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Language</Typography>
                {singlePitch?.languages.map((item, i) => {
                  return <Typography key={i}>{item?.name}</Typography>;
                })}
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Single / Series</Typography>
                <Typography>{singlePitch?.submission_types?.value}</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>
                  Manuscript / Published
                </Typography>
                <Typography>{singlePitch?.publication_types?.value}</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Category</Typography>
                <Typography>{singlePitch?.category?.name}</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Country</Typography>
                {singlePitch?.countries?.map((item, i) => {
                  return <Typography key={i}>{item?.name}</Typography>;
                })}
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Total Words Count</Typography>
                <Typography>{singlePitch?.words_count}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack alignItems={"end"}>
            <Button
              variant="contained"
              size="large"
              sx={{
                borderRadius: "54.6591px",
                bgcolor: "#F83D4B",
                fontSize: "small",
                px: 6,
                width: "13vw",
                fontWeight: "700",
                fontFamily: "Source Sans Pro",
              }}
              onClick={() => handleNav()}
            >
              Back
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default AllPitchesDetails;
