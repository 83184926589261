import React, { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import "../../utils/css/styles.css";
import styled from "@emotion/styled";
import PitchCard from "../cards/PitchCard";
import { Tabs, Tab, Box } from "@mui/material";
import { a11yProps } from "../../utils/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import MySubmissionCard from "../cards/MySubmissionCard";
import { NonFictionalSubmission } from "../dialogs/NonFictionalSubmission";
import { FictionalSubmissionDlg } from "../dialogs/FictionalSubmissionDlg";
import { getAllPitches, getMySubmissions } from "../../store/author/actions";
import PuffLoader from "react-spinners/PuffLoader";
import { useQuery } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "79vh", overflow: "auto" }}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
export default function SubmissionsTabs() {
  const dispatch = useDispatch();

  const getLastActiveTab = sessionStorage.getItem("lastActiveTab");
  const lastActiveTab = parseInt(getLastActiveTab);
  const [activetab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [showFictionaModal, setShowFictionalModal] = useState(false);
  const [showNonFictionalModal, setShowNonFictionalModal] = useState(false);
  const [pitchesPageCount, setPitchesPageCount] = useState(1);
  const [submissionsPageCount, setsubmissionsPageCount] = useState(1);

  const { user, draft_submissions } = useSelector((state) => state.auth);
  const {
    allPitches,
    totalPitches,
    mySubmissions,
    totalSubmissions,
    getSubmissionLoading,
    getPitchesLoading,
  } = useSelector((state) => state.author);
  console.log(
    "🚀 ~ file: SubmissionsTabs.jsx:68 ~ SubmissionsTabs ~ draft_submissions:",
    draft_submissions
  );

  useEffect(() => {
    if (getLastActiveTab !== null) {
      setActiveTab(lastActiveTab);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    sessionStorage.setItem("lastActiveTab", activetab);
  }, [activetab]);

  const StyledTabs = styled(({ className, ...other }) => {
    return (
      <Stack position={"relative"}>
        <Tabs
          sx={{ color: "red" }}
          {...other}
          classes={{
            root: className,
            flexContainer: "flexContainer",
            indicator: "indicator",
          }}
          TabIndicatorProps={{ children: <span /> }}
        />
      
      </Stack>
    );
  })({
    "& .indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: 40,
        width: "100%",
        backgroundColor: "red",
      },
    },
    "& .flexContainer": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
    },
  });

  useQuery({
    queryKey: ["getAllPitches"],
    queryFn: () => dispatch(getAllPitches({ page: pitchesPageCount })),
  });
  useQuery({
    queryKey: ["getMySubmissions"],
    queryFn: () => dispatch(getMySubmissions({ page: submissionsPageCount })),
  });

  const handleLoadMore = () => {
    setIsLoading(true);
    if (activetab === 0) {
      dispatch(getAllPitches({ page: pitchesPageCount + 1, setIsLoading }));
      setPitchesPageCount((prev) => prev + 1);
    } else {
      dispatch(
        getMySubmissions({ page: submissionsPageCount + 1, setIsLoading })
      );
      setsubmissionsPageCount((prev) => prev + 1);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
              <Grid container >
            <Grid xs={12} md={9}>
            <StyledTabs
          value={activetab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >

<Tab
            label="All Pitches"
            sx={{
              color:
                activetab === 0 ? "#FF414C !important" : "#000000 !important",
              fontWeight: activetab === 0 ? 700 : 400,
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="My Submissions"
            sx={{
              color:
                activetab === 1 ? "#FF414C !important" : "#000000 !important",
              fontWeight: activetab === 1 ? 700 : 400,
            }}
            {...a11yProps(1)}
          />
           </StyledTabs>
            </Grid>
            <Grid  sm={12}   md={3}>
              <Box sx={{ marginLeft: { xs: '23px', md:"0px" } , marginTop:{ xs: '13px', md:"0px"} }} >
              <Button
          variant="contained"
          size="large"
          disabled={draft_submissions === totalSubmissions}
          sx={{
            borderRadius: "54.6591px",
            bgcolor: "#F83D4B",
            fontSize: "small",
            px: 6,
            width: "fit-content",
          
            right: 0,
            fontWeight: "700",
          }}
          onClick={() =>
            user?.is_fictional === 1
              ? setShowFictionalModal(true)
              : setShowNonFictionalModal(true)
          }
        >
          {draft_submissions === totalSubmissions
            ? "Buy Submissions"
            : "New Submission"}
        </Button>

              </Box>
           

            </Grid>

          </Grid>
      <TabPanel value={activetab} index={0}>
        {!allPitches?.length && getPitchesLoading ? (
          <PuffLoader
            color={"#FF414C"}
            loading={true}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <div style={{ overflowY: "scroll", height: "100%" }}>
            <Grid container xs={12} spacing={2} display={"flex"}>
              {allPitches && allPitches.length ? (
                allPitches.map((item, i) => {
                  return (
                    <Grid item xs={12} md={6} key={i}>
                      <PitchCard pitch={item} />
                    </Grid>
                  );
                })
              ) : (
                <Stack width={"100%"} alignItems={"center"}>
                  <Typography
                    alignItems={"center"}
                    display={!getPitchesLoading ? "inherit" : "none"}
                  >
                    No Data Found
                  </Typography>
                </Stack>
              )}
            </Grid>
          </div>
        )}
      </TabPanel>
      <TabPanel value={activetab} index={1}>
        {!mySubmissions?.length && getSubmissionLoading ? (
          <PuffLoader
            color={"#FF414C"}
            loading={true}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <Grid container xs={12} spacing={2}>
            {mySubmissions && mySubmissions.length > 0 ? (
              mySubmissions.map((item, i) => {
                return (
                  <Grid item xs={12} md={6} key={i}>
                    <MySubmissionCard submission={item} />
                  </Grid>
                );
              })
            ) : (
              <Stack width={"100%"} alignItems={"center"}>
                <Typography
                  alignItems={"center"}
                  display={!getSubmissionLoading ? "inherit" : "none"}
                >
                  No Data Found
                </Typography>
              </Stack>
            )}
          </Grid>
        )}
      </TabPanel>
      <Stack>
        {(activetab === 0 && allPitches?.length < totalPitches) ||
        (activetab === 1 && mySubmissions?.length < totalSubmissions) ? (
          <LoadingButton
            loading={isLoading}
            onClick={handleLoadMore}
            sx={{ width: "fit-content", color: "#FF414C", alignSelf: "center" }}
          >
            Load More
          </LoadingButton>
        ) : (
          ""
        )}
      </Stack>
      <FictionalSubmissionDlg
        open={showFictionaModal}
        handleClose={() => setShowFictionalModal(false)}
      />
      <NonFictionalSubmission
        open={showNonFictionalModal}
        handleClose={() => setShowNonFictionalModal(false)}
      />
    </Box>
  );
}
