import { Avatar, Button, Chip, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import TruncatedText from "../reuseableFunctions/TruncatedText";
import SubmissionActions from "./SubmissionActions";
import attachmentDownload from "../../assets/images/attachmentDownload.svg";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { TimeAgo } from "../../utils/Helpers";
import { toast } from "react-hot-toast";

const MySubmissionDetails = () => {
  const nav = useNavigate();
  const { singleSubmission } = useSelector((state) => state.author);

  const handleNav = () => {
    sessionStorage.setItem("lastActiveTab", 1);
    setTimeout(() => {
      nav(-1);
    }, 200);
  };

  return (
    <>
      {/* Logo with Actions */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        width={"100%"}
        my={2}
      >
        <Stack direction={"row"} spacing={1}>
          <Avatar
            alt={singleSubmission?.user?.user_profile?.full_name}
            src={singleSubmission?.user?.user_profile?.profile_image}
          />
          <Stack>
            <Typography fontWeight={"700"}>
              {singleSubmission?.user?.full_name}
            </Typography>
            <Typography variant="caption" color={"#C0C0C0"}>
              {TimeAgo(new Date(singleSubmission?.created_at))}
            </Typography>
          </Stack>
        </Stack>
        <Stack alignItems={"end"}>
          <SubmissionActions submission={singleSubmission} />
          <Chip
            size="small"
            variant="outlined"
            label={`PID#${singleSubmission?.pid}`}
            sx={{
              color: "#FFA63E",
              border: "1px solid #FFA63E",
              borderRadius: "5px",
              width: "13vw",
            }}
          />
        </Stack>
      </Stack>
      {/* Page Content */}
      <Grid container xs={12} sx={{ height: "65vh", overflow: "auto" }}>
        <Grid item xs={12} md={8}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={3}>
            <Stack width={{ xs: "100%", md: "50%" }} spacing={4}>
              <Stack>
                <Typography fontWeight={"700"}>Title</Typography>
                <Typography>{singleSubmission?.title}</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Pitch</Typography>
                <TruncatedText text={singleSubmission?.pitch} limit={"85"} />
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>
                  Potential Target Audience
                </Typography>
                <Typography>{singleSubmission?.target_audience}</Typography>
              </Stack>
            </Stack>
            <Stack width={{ xs: "100%", md: "50%" }} spacing={2}>
              <Stack>
                <Typography fontWeight={"700"}>Genres</Typography>
                <Typography>
                  {singleSubmission?.genres?.map((item, i) => {
                    return (
                      <p style={{ margin: 0 }} key={i}>
                        {item?.name}
                      </p>
                    );
                  })}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Language</Typography>
                <Typography>
                  {singleSubmission?.languages?.map((item, i) => {
                    return (
                      <p style={{ margin: 0 }} key={i}>
                        {item?.name}
                      </p>
                    );
                  })}
                </Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Plot</Typography>
                <Typography>Quest</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Single / Series</Typography>
                <Typography>Series</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Characters</Typography>
                <Typography>
                  Lexi Constantine, Reverend Edward, Tamara
                </Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Settings</Typography>
                <Typography>English</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Category</Typography>
                <Typography>A 18+</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={"700"}>Country</Typography>
                <Typography>
                  {singleSubmission?.countries?.map((item, i) => {
                    return (
                      <p style={{ margin: 0 }} key={i}>
                        {item?.name}
                      </p>
                    );
                  })}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack alignItems={"end"} mb={4}>
            <Button
              variant="contained"
              size="large"
              sx={{
                borderRadius: "54.6591px",
                bgcolor: "#F83D4B",
                fontSize: "small",
                px: 6,
                width: "13vw",
                fontWeight: "700",
              }}
              onClick={handleNav}
            >
              Back
            </Button>
          </Stack>
          <Typography fontWeight={"700"}>Attachments</Typography>
          <Stack spacing={3}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>Query Letter</Typography>
              <img
                alt=""
                width={"5%"}
                src={attachmentDownload}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (singleSubmission?.query_letter) {
                    window.open(singleSubmission?.query_letter, "_blank");
                  } else {
                    toast.error("No Data Found");
                  }
                }}
              />
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>Synopsis</Typography>
              <img
                alt=""
                width={"5%"}
                src={attachmentDownload}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (singleSubmission?.synopsis) {
                    window.open(singleSubmission?.synopsis, "_blank");
                  } else {
                    toast.error("No Data Found");
                  }
                }}
              />
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>1st 50 Pages</Typography>
              <img
                alt=""
                width={"5%"}
                src={attachmentDownload}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (singleSubmission?.first_fifty_pages) {
                    window.open(singleSubmission?.first_fifty_pages, "_blank");
                  } else {
                    toast.error("No Data Found");
                  }
                }}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default MySubmissionDetails;
