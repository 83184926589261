import React from "react";
import SubmissionsComponent from "../components/submissions/SubmissionsComponent";

const SubmissionsScreen = () => {
  return (
    <>
      <SubmissionsComponent />
    </>
  );
};

export default SubmissionsScreen;
