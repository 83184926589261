import React, { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Tab, Typography } from "@mui/material";
import paidAgentPic from "../assets/images/paid-agent.svg";
import { a11yProps, StyledTabs } from "../utils/Helpers";
import publishedBook from "../assets/images/published-book.svg";
import TruncatedText from "../components/reuseableFunctions/TruncatedText";
import {
  getFreeAgent,
  getMyPaidAgents,
  getSingleAgent,
} from "../store/author/actions";
import { useNavigate } from "react-router";
import rightArrow from "../assets/images/right-arrow.svg";
import PuffLoader from "react-spinners/PuffLoader";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { AgentMatchingDlg } from "../components/dialogs/AgentMatchingDlg";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
export const AllAgents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);

  const [userGenres, setUserGenres] = useState([]);
  const [visibleCount, setVisibleCount] = useState(12);
  const [showAgentMatchingDlg, setShowAgentMatchingDlg] = useState(false);
  const paidAgentPage = useRef(1);

  const { freeAgent, paidAgents, isLoading, currentPage, last_page } =
    useSelector((state) => state.author);

  useQuery({
    queryKey: ["getFreeAgent"],
    queryFn: () => dispatch(getFreeAgent()),
  });
  useQuery({
    queryKey: ["getMyPaidAgents"],
    queryFn: () => dispatch(getMyPaidAgents(paidAgentPage.current)),
  });

  useEffect(() => {
    if (freeAgent?.free_agent?.genres?.length) {
      let data = "";
      freeAgent?.free_agent?.genres.forEach((item) => {
        data = data + item.name + ", ";
      });
      setUserGenres(data);
    }
  }, [freeAgent]);
  useEffect(() => {
    if (paidAgents) {
      paidAgentPage.current = currentPage + 1;
    }
  }, [paidAgents]);

  useEffect(() => {
    console.log(`here ${currentPage} `)
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        const toastId = toast.loading("Loading...");
        if (
          currentPage > 0 &&
          currentPage <= last_page &&
          paidAgentPage.current < 4
        )
          dispatch(getMyPaidAgents(paidAgentPage.current));

        setTimeout(() => {
          setVisibleCount((prevCount) => prevCount + 5);
          toast.dismiss(toastId);
        }, 1000);
      }
    };

    if (ref?.current) {
      ref.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref?.current) {
        // eslint-disable-next-line
        ref?.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [paidAgents]);

  return (
    <Stack
      marginX={"2em"}
      marginY={"1em"}
      alignItems={{ xs: "center", sm: "flex-start" }}
    >
      {!paidAgents?.length && isLoading ? (
        <PuffLoader
          color={"#FF414C"}
          loading={isLoading}
          cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <>
          <Box>
            <StyledTabs value={0} aria-label="basic tabs example">
              <Tab
                label="Free Agent"
                sx={{
                  color: "#FF414C !important",
                  fontWeight: 700,
                }}
                {...a11yProps(0)}
              />
            </StyledTabs>
          </Box>

          {freeAgent ? (
            <Stack
              sx={{
                cursor: "pointer",
                height: "fit-content",
                marginTop: "1em",
                borderRadius: "16.2692px",
                border: "1.34024px solid #FF414C",
                width: { xs: "20em", sm: "30em" },
                alignItems: { xs: "center", sm: "flex-start" },
                flexDirection: { xs: "column", sm: "row" },
                boxShadow: "0px 2.68047px 7.14793px rgba(0, 0, 0, 0.2)",
              }}
              onClick={() => {
                dispatch(getSingleAgent(freeAgent?.free_agent));
                navigate("/agent-bio");
              }}
            >
              <img
                alt=""
                src={freeAgent?.free_agent?.user_profile?.profile_image}
                style={{
                  width: "35%",
                  height: "14rem",
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "15px",
                }}
              />
              <Stack marginLeft={{ xs: "0", sm: "1em" }}>
                <Typography fontWeight={700}>
                  {freeAgent?.free_agent?.full_name}
                </Typography>
                <Typography variant={"body2"}>
                  {freeAgent?.free_agent?.user_countries?.length
                    ? freeAgent?.free_agent?.user_countries.map((item) => {
                      return <span>{item?.name}, </span>;
                    })
                    : ""}
                </Typography>

                <Typography fontWeight={700}>Genres</Typography>
                <Typography variant={"body2"}>
                  {userGenres?.length && (
                    <TruncatedText
                      text={userGenres}
                      limit={"6"}
                      fontSize={"1em"}
                    />
                  )}
                </Typography>
                {freeAgent?.free_agent?.publications?.length > 0 ? (
                  <>
                    <Typography fontWeight={700}>Publications</Typography>
                    <Stack flexDirection={"row"} gap={"0.5em"}>
                      {freeAgent?.free_agent?.publications.map((item, i) => {
                        return (
                          <img
                            alt=""
                            key={i}
                            // NEED TO CHECK THIS WITH REAL DATA
                            src={item?.image ? item?.image : publishedBook}
                            style={{ height: "100%", width: "65px" }}
                          />
                        );
                      })}
                    </Stack>
                  </>
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          ) : (
            <Typography textAlign={"center"}>No Data Found</Typography>
          )}
          <Button
            sx={{
              // top: "7em",
              right: "10px",
              position: "absolute",
              fontWeight: "700",
              fontSize: "0.8em",
              lineHeight: "25px",
              textalign: "center",
              color: "#FFFFFF",
              background: "#FF414C",
              boxShadow: "0px 5.47764px 27.3882px rgba(255, 0, 0, 0.25)",
              borderRadius: "32.8658px",
              width: "12em",
              paddingX: "1em",
              ":hover": {
                color: "#FFFFFF",
                background: "#FF414C",
              },
            }}
            onClick={() => setShowAgentMatchingDlg(true)}
          >
            Find More Matches
          </Button>
          <Box marginTop={"2em"} marginBottom={"1em"}>
            <StyledTabs value={0} aria-label="basic tabs example">
              <Tab
                sx={{
                  color: "#FF414C !important",
                  fontWeight: 700,
                }}
                label="Agents Assign"
                {...a11yProps(0)}
              />
            </StyledTabs>
          </Box>

          <div ref={ref} style={{ overflowY: "scroll", height: "400px" }}>
            <Grid xs={12} container gap={3}>
              {paidAgents?.length ? (
                paidAgents.slice(0, visibleCount).map((item, i) => {
                  return (
                    <Grid
                      item
                      key={i}
                      xs={11.8}
                      md={5.8}
                      lg={3.8}
                      sx={{
                        borderRadius: "16.2692px",
                        border: "1.34024px solid #FF414C",
                        boxShadow: "0px 2.68047px 7.14793px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <Stack
                        flexDirection={"row"}
                        onClick={() => {
                          dispatch(getSingleAgent(item));
                          navigate("/agent-bio");
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <img
                          alt=""
                          src={
                            item?.user_profile?.profile_image || paidAgentPic
                          }
                          style={{
                            width: "7em",
                            height: "7em",
                            objectFit: "cover",
                            borderTopLeftRadius: "15px",
                            borderBottomLeftRadius: "15px",
                          }}
                        />
                        <Stack
                          justifyContent={"center"}
                          paddingLeft={"1em"}
                          width={"62%"}
                        >
                          <Typography fontWeight={700}>
                            {item?.full_name}
                          </Typography>
                          <Typography
                            color={"#00A3FF"}
                            sx={{
                              cursor: "pointer",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(
                                item?.user_profile?.website,
                                "_blank",
                                "noreferrer"
                              );
                            }}
                          >
                            {item?.user_profile?.website}
                          </Typography>
                        </Stack>
                        <img
                          alt=""
                          src={rightArrow}
                          style={{
                            marginLeft: "auto",
                            paddingRight: "2em",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            dispatch(getSingleAgent(item));
                            navigate("/agent-bio");
                          }}
                        />
                      </Stack>
                    </Grid>
                  );
                })
              ) : (
                <Typography textAlign={"center"}>No Data Found</Typography>
              )}
            </Grid>
          </div>
          {paidAgents?.length ? (
            <Typography
              marginTop={"1em"}
              alignSelf={"center"}
              color={"#FF414C"}
            >
              {/* Load More */}
            </Typography>
          ) : (
            ""
          )}
        </>
      )}
      <AgentMatchingDlg
        open={showAgentMatchingDlg}
        handleClose={() => setShowAgentMatchingDlg(false)}
      />
    </Stack>
  );
};
