import React, { useState } from "react";
import { Stack } from "@mui/system";
import { OtpInput } from "./OtpInput";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { BiRightArrowAlt } from "react-icons/bi";
import { Typography } from "@mui/material";
import { updatePhoneNumberVerify } from "../../store/auth/actions";
import { PhoneNumberFormatter } from "../../utils/Helpers";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";

export const UpdatePhoneVerifification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pinCode, setPinCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { newPhone } = useSelector((state) => state.auth);

  const handleVerify = () => {
    if (pinCode.length === 4) {
      setIsLoading(true);
      let data = new FormData();
      data.append("phoneNo", newPhone);
      data.append("otpCode", pinCode);
      dispatch(updatePhoneNumberVerify({ data, navigate, setIsLoading }));
    } else {
      toast.error("Enter Correct Pin");
    }
  };
  return (
    <Stack justifyContent={"center"} alignItems={"center"} height={"100vh"}>
      <Stack width={{ xs: "60%", sm: "45%", md: "40%", lg: "25%" }}>
        <Typography variant="h4" fontWeight={"700"} textAlign={"center"}>
          Verification Code
        </Typography>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typography textAlign={"center"}>
            Please type the verification code sent on your phone
          </Typography>
          <Typography color={"#FF414C"} fontWeight={700} textAlign={"center"}>
            {PhoneNumberFormatter(newPhone)}
          </Typography>
          <Stack paddingTop={"5em"}>
            <OtpInput handlePin={(pin) => setPinCode(pin)} />
            <Typography
              variant="subtitle2"
              paddingLeft={{ md: "0", lg: "3em" }}
              textAlign={"center"}
            >
              Didn’t receive the code? <b>RESEND</b>
            </Typography>
          </Stack>
        </Stack>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="submit"
          size="large"
          sx={{
            background: "#FF414C",
            color: "white",
            fontSize: "0.8em",
            fontWeight: "700",
            borderRadius: "20px",
            paddingY: "1em",
            marginTop: "4em",
            boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
            ":hover": { background: "#FF414C" },
            width: "90%",
            alignSelf: "center",
          }}
          onClick={handleVerify}
          endIcon={<BiRightArrowAlt size={20} />}
        >
          Verify{" "}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
