import React from "react";
import styled from "@emotion/styled";
import { Tabs } from "@mui/material";

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const StyledTabs = styled(({ className, ...other }) => {
  return (
    <Tabs
      sx={{ color: "#FF414C" }}
      {...other}
      classes={{
        root: className,
        flexContainer: "flexContainer",
        indicator: "indicator",
      }}
      TabIndicatorProps={{ children: <span /> }}
    />
  );
})({
  "& .indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 60,
      width: "100%",
      backgroundColor: "#FF414C",
    },
  },
  "& .flexContainer": {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
  },
});

export const PhoneNumberFormatter = (number) => {
  const countryCode = number.slice(0, 2);
  const areaCode = number.slice(2, 5);
  const firstPart = number.slice(5, 8);
  const secondPart = number.slice(8);

  return `(${countryCode}) ${areaCode}-${firstPart}-${secondPart}`;
};

export const TimeAgo = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  }

  const minutes = Math.floor(seconds / 60);

  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }

  const hours = Math.floor(minutes / 60);

  if (hours < 24) {
    return `${hours} hours ago`;
  }

  const days = Math.floor(hours / 24);

  if (days < 30) {
    return `${days} days ago`;
  }

  const months = Math.floor(days / 30);

  return `${months} months ago`;
};

export const handleActiveTab = (tab) => {
  let path = window.location.pathname;
  if (tab === "Home" && path === "/") {
    return 700;
  } else if (tab === "Agent" && path === "/all-agents") {
    return 700;
  } else if (tab === "Submissions" && path === "/all-submissions") {
    return 700;
  } else {
    return 400;
  }
};
