import axios from "axios";
// const BASE_URL_API = "http://192.168.0.112:8000/api/";
// const BASE_URL_API = "http://127.0.0.1:8000/api/";
const BASE_URL_API = "https://admin.bookwizardhub.com/api/";

const axiosConfig = axios.create({
  baseURL: BASE_URL_API,
});

// Add a request interceptor
axiosConfig.interceptors.request.use(
  (config) => {
    const localToken = localStorage.getItem("bw-token");
    const sessionToken = sessionStorage.getItem("bw-token");
    if (localToken) {
      config.headers["Authorization"] = "Bearer " + localToken;
    }
    if (sessionToken) {
      config.headers["Authorization"] = "Bearer " + sessionToken;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      // logout();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosConfig;
