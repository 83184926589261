import { Stack } from "@mui/system";
import React from "react";
import LoginComponent from "../components/login/LoginComponent";

const LoginScreen = () => {
  return (
    <Stack height={"100vh"}>
      <LoginComponent />
    </Stack>
  );
};

export default LoginScreen;
