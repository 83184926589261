import React from "react";
import { Navigate } from "react-router";

const PrivateRoutes = ({ children }) => {
  const localToken = localStorage.getItem("bw-token");
  const sessionToken = sessionStorage.getItem("bw-token");

  if (!localToken && !sessionToken) {
    return <Navigate to={"/login"} replace />;
  }
  return children;
};

export default PrivateRoutes;
