import React from "react";
import { Stack } from "@mui/system";
import { IconButton, Modal, Typography } from "@mui/material";
import { defaultModalStyle } from "../../utils/Constants";
import closeIcon from "../../assets/images/close-icon.svg";

export const AgentMatchesDlg = ({ handleClose, open }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing="1em"
        alignItems={"center"}
        sx={defaultModalStyle}
        width={{ xs: "90%", sm: "40vw" }}
      >
        <IconButton
          component="span"
          onClick={handleClose}
          sx={{ position: "absolute", right: "6px", top: "10px" }}
        >
          <img alt="" src={closeIcon} style={{ width: "15px" }} />
        </IconButton>
        <Typography fontWeight={700} variant={"h4"} mt={"0.3em !important"}>
          Agent Matches
        </Typography>
        <Typography paddingTop={"0.5em"}>
          No matter if your book is Fiction or Nonfiction, you will have a
          subgenre (How-To, Horror, etc.).
        </Typography>
        <Typography>
          If you do have a book proposal (Nonfiction) or query letter, synopsis,
          and finished manuscript (Fiction), once you sign into the BookWizard
          App and fill in your information, the app will ‘match’ you with Agents
          that are accepting similar genre queries.
        </Typography>
        <Typography>
          If you do not have a finished book proposal or manuscript, don’t
          worry. Once you sign into the BookWizard App and fill in the
          information you do have, the app will ‘match’ you with Agencies that
          are accepting similar genre queries.
        </Typography>
      </Stack>
    </Modal>
  );
};
