import { Stack } from "@mui/material";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ForgetPassword } from "../../components/auth/ForgetPassword";
import { ResetPassword } from "../../components/auth/ResetPassword";
import { UpdateEmail } from "../../components/auth/UpdateEmail";
import { UpdateEmailVerifification } from "../../components/auth/UpdateEmailVerifification";
import { UpdatePhoneVerifification } from "../../components/auth/UpdatePhoneVerifification";
import { VerificationCode } from "../../components/auth/VerificationCode";
import GlobalWrapper from "../../components/globalwrapper/GlobalWrapper";
import SignupComponent1 from "../../components/signup/SignupComponent1";
import SignupComponent2 from "../../components/signup/SignupComponent2";
import SignupComponent3 from "../../components/signup/SignupComponent3";
import AllPitchesDetails from "../../components/submissions/AllPitchesDetails";
import Layout from "../../layout/Layout";
import { AboutUs } from "../../screens/AboutUs";
import AgencyDetailScreen from "../../screens/AgencyDetailScreen";
import { AgentBio } from "../../screens/AgentBio";
import { AllAgents } from "../../screens/AllAgents";
import DashboardScreen from "../../screens/DashboardScreen";
import ErrorPage from "../../screens/ErrorPage";
import LoginScreen from "../../screens/LoginScreen";
import MySubmissionDetailScreen from "../../screens/MySubmissionDetailScreen";
import SubmissionsScreen from "../../screens/SubmissionsScreen";
import { TermsAndConditions } from "../../screens/TermsAndConditions";
import { UserProfile } from "../../screens/UserProfile";
import PrivateRoutes from "./PrivateRoutes";
import { ForgetPasswordVerification } from "../../components/auth/ForgetPasswordVerifification";

const routes = createBrowserRouter([
  {
    path: "/login",
    errorElement: <ErrorPage />,
    element: <LoginScreen />,
  },
  {
    path: "/sign-up-1",
    errorElement: <ErrorPage />,
    element: <SignupComponent1 />,
  },
  {
    path: "/sign-up-2",
    errorElement: <ErrorPage />,
    element: <SignupComponent2 />,
  },
  {
    path: "/sign-up-3",
    errorElement: <ErrorPage />,
    element: <SignupComponent3 />,
  },
  {
    path: "/verification",
    errorElement: <ErrorPage />,
    element: <VerificationCode />,
  },
  {
    path: "/update-email-verify",
    errorElement: <ErrorPage />,
    element: <UpdateEmailVerifification />,
  },
  {
    path: "/forget-password-verify",
    errorElement: <ErrorPage />,
    element: <ForgetPasswordVerification />,
  },
  {
    path: "/update-phone-verify",
    errorElement: <ErrorPage />,
    element: <UpdatePhoneVerifification />,
  },
  {
    path: "/forget-password",
    errorElement: <ErrorPage />,
    element: <ForgetPassword />,
  },
  {
    path: "/reset-password",
    errorElement: <ErrorPage />,
    element: <ResetPassword />,
  },
  {
    path: "/update-email",
    errorElement: <ErrorPage />,
    element: <UpdateEmail />,
  },
  {
    path: "/user-profile",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <UserProfile />
        </Layout>
      </PrivateRoutes>
    ),
  },
  {
    path: "/terms-and-conditions",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <TermsAndConditions />
        </Layout>
      </PrivateRoutes>
    ),
  },
  {
    path: "/about-us",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <AboutUs />
        </Layout>
      </PrivateRoutes>
    ),
  },
  {
    path: "/all-agents",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <AllAgents />
        </Layout>
      </PrivateRoutes>
    ),
  },
  {
    path: "/agent-bio",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <AgentBio />
        </Layout>
      </PrivateRoutes>
    ),
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <GlobalWrapper>
            <DashboardScreen />
          </GlobalWrapper>
        </Layout>
      </PrivateRoutes>
    ),
  },
  {
    path: "/agency-detail/:id",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <GlobalWrapper>
            <AgencyDetailScreen />
          </GlobalWrapper>
        </Layout>
      </PrivateRoutes>
    ),
  },
  {
    path: "/all-submissions",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <GlobalWrapper>
            <SubmissionsScreen />
          </GlobalWrapper>
        </Layout>
      </PrivateRoutes>
    ),
  },
  {
    path: "/all-submissions/my-submissions/:id",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <GlobalWrapper>
            <MySubmissionDetailScreen />
          </GlobalWrapper>
        </Layout>
      </PrivateRoutes>
    ),
  },
  {
    path: "/all-submissions/all-pitches/:id",
    errorElement: <ErrorPage />,
    element: (
      <PrivateRoutes>
        <Layout>
          <GlobalWrapper>
            <AllPitchesDetails />
          </GlobalWrapper>
        </Layout>
      </PrivateRoutes>
    ),
  },
]);

const MainRoutes = () => {
  return (
    <>
      <Stack minHeight={"100vh"}>
        <RouterProvider router={routes} />
      </Stack>
    </>
  );
};

export default MainRoutes;
