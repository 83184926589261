import React, { useState } from "react";
import { useSelector } from "react-redux";
import { UserProfileMenu } from "./UserProfileMenu";
import { PhoneNumberFormatter } from "../utils/Helpers";
import profilePicture from "../assets/images/user-avatar.svg";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import TruncatedText from "../components/reuseableFunctions/TruncatedText";
// import { updateProfile } from "../store/author/actions";

export const UserProfile = () => {
  // const disptach = useDispatch();

  const [imageUrl, setImageUrl] = useState(null);

  const { user } = useSelector((state) => state.auth);
  console.log("user -->>>", user);
  const { agentFound, agentFoundLoading, free_agents, user_genres } =
    useSelector((state) => ({
      free_agents: state.auth.free_agents,
      user: state.auth.user,
    }));

  const handlePicture = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = () => {
        setImageUrl(reader.result);
        // disptach(updateProfile({ data: { profile_image: img } }));
      };
    }
  };

  return (
    <Stack
      paddingTop={"1.5em"}
      gap={{ sm: "1em", md: "1em", lg: "0em" }}
      flexDirection={{ xs: "column", sm: "row" }}
      alignItems={{ xs: "center", sm: "flex-start" }}
    >
      <Stack
        alignItems={"center"}
        width={{ xs: "40%", sm: "30%", md: "20%", lg: "15%" }}
      >
        <input
          accept="image/*"
          id="upload-avatar-pic"
          type="file"
          hidden
          onChange={handlePicture}
        />
        <label htmlFor="upload-avatar-pic">
          <IconButton component="span">
            <Avatar
              alt=""
              sx={{ width: 104, height: 104 }}
              src={
                imageUrl
                  ? imageUrl
                  : user?.user_profile?.profile_image
                    ? user?.user_profile?.profile_image
                    : profilePicture
              }
            />
          </IconButton>
        </label>
        <Typography fontWeight={700} variant={"h6"} textAlign={"center"}>
          {user?.full_name}
        </Typography>
        <Typography textAlign={"center"}>@{user?.username}</Typography>
        <Typography variant="body2" textAlign={"center"}>
          {user?.total_submissions
            ? `${user?.total_submissions} Total Submissions`
            : "No Submissions Yet"}
        </Typography>
      </Stack>
      <Stack width={"85%"} paddingTop={"1.5em"}>
        <Typography fontWeight={700}>Bio</Typography>
        <Typography width={{ sm: "60%", md: "40%", lg: "25%" }}>
          <TruncatedText text={`${user?.user_profile.bio}`} limit={"40"} />
        </Typography>
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          gap={{ xs: "0.2em", sm: "1em" }}
        >
          <Stack
            paddingTop={"1.5em"}
            width={{ xs: "80%", sm: "50%", md: "30%", lg: "20%" }}
          >
            <Stack>
              <Typography fontWeight={700}>Genre / Subgenres</Typography>
              <Typography>
                {user?.is_fictional ? "Fictional/" : "Non-Fictional/"}
              </Typography>
              <Typography>
                {user?.user_genres?.length
                  ? user?.user_genres.map((item, i) => {
                    return <span key={i}>{item.genre.name}</span>;
                  })
                  : []}
              </Typography>
            </Stack>
            <Stack paddingTop={"1.5em"}>
              <Typography fontWeight={700}>Phone</Typography>
              <Typography>
                {user?.phone ? PhoneNumberFormatter(user.phone) : ""}
              </Typography>
            </Stack>
            <Stack paddingTop={"1.5em"}>
              <Typography fontWeight={700}>City</Typography>
              <Typography>{user?.user_profile?.user_city}</Typography>
            </Stack>
            <Stack paddingTop={"1.5em"}>
              <Typography fontWeight={700}>State / Province</Typography>
              <Typography>{user?.user_profile?.state?.name}</Typography>
            </Stack>
            <Stack paddingTop={"1.5em"}>
              <Typography fontWeight={700}>Street Address</Typography>
              <Typography>{user?.user_profile?.street_address}</Typography>
            </Stack>
          </Stack>
          <Stack paddingTop={"1.5em"}>
            <Stack>
              <Typography fontWeight={700}>Email</Typography>
              <Typography color={"rgba(0, 163, 255, 1)"}>
                {user?.email}
              </Typography>
            </Stack>
            <Stack paddingTop={"1.5em"}>
              <Typography fontWeight={700}>Language</Typography>
              <Typography>{user?.user_profile?.language?.name}</Typography>
            </Stack>
            <Stack paddingTop={"1.5em"}>
              <Typography fontWeight={700}>Country</Typography>
              <Typography>{user?.user_profile?.country?.name}</Typography>
            </Stack>
            <Stack paddingTop={"1.5em"}>
              <Typography fontWeight={700}>Zip/Postal Code</Typography>
              <Typography>{user?.user_profile?.zip_code}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          top: "5em",
          right: "1em",
          position: "absolute",
        }}
      >
        <UserProfileMenu />
      </Stack>
    </Stack>
  );
};
