import React from "react";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import mapImage from "../assets/images/map-image.svg";
import fbLogo from "../assets/images/fb-logo.svg";
import phoneIcon from "../assets/images/phone-icon.svg";
import instaLogo from "../assets/images/insta-logo.svg";
import websiteIcon from "../assets/images/website-icon.svg";
import twitterLogo from "../assets/images/twitter-logo.svg";
import locationIcon from "../assets/images/location-icon.svg";
import TruncatedText from "../components/reuseableFunctions/TruncatedText";

export const AboutUs = () => {
  return (
    <Stack padding={"2em"} height={"100vh"}>
      <Stack flexDirection={{ sm: "column", md: "row" }} gap={"3em"}>
        <Stack width={{ sm: "100%", md: "30vw" }}>
          <Typography fontWeight={700}>Bio</Typography>
          <Typography>
            Many ideas come from trying to solve a problem. BookWizard came to
            life because of an author watching Dragon's Den. One of the Dragons
            asked the question, "What is your complaint? What do you bitch
            about? Now find a solution."
          </Typography>
          <Typography paddingTop={"1em"}>
            <TruncatedText
              text={`This was Connie's complaint. Constance Santego, founder and CEO of
            BookWizard, had been trying to find an agent. Not just trying to find
            an agent that would offer her a book deal, but to actually just find
            an agent that matched her criteria. If you are an author and have been
            pitching your book idea, you know exactly what she is complaining
            about. Trying to find an agent isn't easy. Solution . . . An easy way
            to find an agent!`}
              limit={"135"}
            />
          </Typography>
        </Stack>
        <Stack width={{ sm: "100%", md: "30vw" }}>
          <Typography fontWeight={700}>Vision</Typography>
          <Typography>
            Genre Matching Authors to Agents and vice versa, from anywhere in
            the world!
          </Typography>
          <Typography fontWeight={700} paddingTop={"1em"}>
            Mission
          </Typography>
          <Typography>
            At BookWizard, we genre match Authors to Agents in a way that is
            simple, quick, and easy to use. Goal To continually be the world’s
            most recognized and sought-after genre matching app. Purpose To make
            the life of matching an Author to an Agent easier!
          </Typography>
          <Typography fontWeight={700} paddingTop={"1em"}>
            Goal
          </Typography>
          <Typography>
            To continually be the world’s most recognized and sought-after genre
            matching app. Purpose To make the life of matching an Author to an
            Agent easier!
          </Typography>
          <Typography fontWeight={700} paddingTop={"1em"}>
            Purpose
          </Typography>
          <Typography>
            To make the life of matching an Author to an Agent easier!
          </Typography>
        </Stack>
        <Stack width={{ sm: "100%", md: "30vw" }}>
          <Typography fontWeight={700} paddingBottom={"1em"}>
            Map
          </Typography>
          <img
            alt={""}
            src={mapImage}
            style={{ width: "25vw", height: "19vw" }}
          />
        </Stack>
      </Stack>

      <Stack
        marginTop={"auto"}
        paddingTop={"2em"}
        gap={{ xs: "2em", sm: "0em" }}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Stack flexDirection={"row"} gap={"1em"}>
          <Stack>
            <img
              alt=""
              src={websiteIcon}
              style={{ width: "25px", height: "25px", alignSelf: "center" }}
            />
            <Typography fontWeight={700} textAlign={"center"}>
              Website
            </Typography>
            <Typography textAlign={"center"} variant={"body2"}>
              bookwizard.ca
            </Typography>
          </Stack>
          <Stack>
            <img
              alt=""
              src={phoneIcon}
              style={{ width: "25px", height: "25px", alignSelf: "center" }}
            />
            <Typography fontWeight={700} textAlign={"center"}>
              Call Us!
            </Typography>
            <Typography textAlign={"center"} variant={"body2"}>
              778-214-6831
            </Typography>
          </Stack>
          <Stack>
            <img
              alt=""
              src={locationIcon}
              style={{ width: "25px", height: "25px", alignSelf: "center" }}
            />
            <Typography fontWeight={700} textAlign={"center"}>
              Location
            </Typography>
            <Typography textAlign={"center"} variant={"body2"}>
              Kelowna, BC Canada
            </Typography>
          </Stack>
        </Stack>
        <Stack flexDirection={"row"} alignItems={"center"} gap={"0.5em"}>
          <Typography fontWeight={700}>Connect:</Typography>
          <img
            alt=""
            src={fbLogo}
            style={{ width: "25px", height: "25px", alignSelf: "center" }}
          />
          <img
            alt=""
            src={instaLogo}
            style={{ width: "25px", height: "25px", alignSelf: "center" }}
          />
          <img
            alt=""
            src={twitterLogo}
            style={{ width: "25px", height: "25px", alignSelf: "center" }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
