import React from "react";
import AgencyDetailComponent from "../components/agency/AgencyDetailComponent";

const AgencyDetailScreen = () => {
  return (
    <>
      <AgencyDetailComponent />
    </>
  );
};

export default AgencyDetailScreen;
