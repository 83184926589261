import React, { useState } from "react";
import { Modal } from "@mui/material";
import { Stack } from "@mui/system";
import {
  IconButton,
  TextField,
  Typography,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { BiRightArrowAlt } from "react-icons/bi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../store/auth/actions";
import { LoadingButton } from "@mui/lab";
import closeIcon from "../../assets/images/close-icon.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
export const UpdatePasswordDlg = ({ handleClose, open }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleSubmit = () => {
    if (values.newPassword && values.newPassword && values.confirmPassword) {
      if (
        values.newPassword.length >= 8 &&
        values.newPassword === values.confirmPassword
      ) {
        setIsLoading(true);
        let data = {
          old_password: values.oldPassword,
          new_password: values.newPassword,
        };
        // let data = new FormData();
        // data.append("old_password", values.oldPassword);
        // data.append("new_password", values.newPassword);
        dispatch(updatePassword({ data, setIsLoading, handleClose }));
      } else {
        toast.error("Passwords Should Match");
      }
    } else {
      toast.error("Passwords Should Be 8 or more Characters");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        sx={style}
        spacing="1em"
        width={{ xs: "90%", sm: "35vw" }}
        alignItems={"center"}
      >
        <Stack width={"80%"}>
          <IconButton
            component="span"
            onClick={handleClose}
            sx={{ position: "absolute", right: "6px", top: "10px" }}
          >
            <img alt="" src={closeIcon} style={{ width: "15px" }} />
          </IconButton>
          <Typography
            textAlign={"center"}
            variant="h4"
            fontWeight={700}
            mt={"0.3em !important"}
          >
            Update Password
          </Typography>
          <Typography textAlign={"center"}>
            Your new password must be different from your previous passwords
          </Typography>

          <Stack>
            <FormControl
              sx={{
                m: 1,
                marginTop: "3em",
                border: "none",
                backgroundColor: "#F5F5F5",
                borderRadius: "30px",
              }}
            >
              <TextField
                placeholder="Old Password"
                type={showPassword.old ? "text" : "password"}
                value={values.oldPassword}
                onChange={(e) =>
                  setValues({ ...values, oldPassword: e.target.value })
                }
                sx={{
                  "& label": { paddingLeft: (theme) => theme.spacing(2) },
                  "& input": {
                    paddingLeft: (theme) => theme.spacing(3.5),
                    borderRadius: "30px",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            old: !showPassword.old,
                          })
                        }
                      >
                        {showPassword.old ? (
                          <AiFillEye />
                        ) : (
                          <AiFillEyeInvisible />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Typography variant="caption" color="#C0C0C0" paddingLeft={"2em"}>
              Enter your old password
            </Typography>
          </Stack>
          <Stack>
            <FormControl
              sx={{
                m: 1,
                marginTop: "1em",
                border: "none",
                backgroundColor: "#F5F5F5",
                borderRadius: "30px",
              }}
            >
              <TextField
                placeholder="New Password"
                type={showPassword.new ? "text" : "password"}
                value={values.newPassword}
                onChange={(e) =>
                  setValues({ ...values, newPassword: e.target.value })
                }
                sx={{
                  "& label": { paddingLeft: (theme) => theme.spacing(2) },
                  "& input": {
                    paddingLeft: (theme) => theme.spacing(3.5),
                    borderRadius: "30px",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            new: !showPassword.new,
                          })
                        }
                      >
                        {showPassword.new ? (
                          <AiFillEye />
                        ) : (
                          <AiFillEyeInvisible />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Typography variant="caption" color="#C0C0C0" paddingLeft={"2em"}>
              Password must be atleast 8 characters
            </Typography>
          </Stack>

          <Stack paddingTop={"1em"}>
            <FormControl
              sx={{
                m: 1,
                border: "none",
                backgroundColor: "#F5F5F5",
                borderRadius: "30px",
              }}
            >
              <TextField
                placeholder="Re-Enter New Password"
                type={showPassword.confirm ? "text" : "password"}
                value={values.confirmPassword}
                onChange={(e) =>
                  setValues({ ...values, confirmPassword: e.target.value })
                }
                sx={{
                  "& label": { paddingLeft: (theme) => theme.spacing(2) },
                  "& input": {
                    paddingLeft: (theme) => theme.spacing(3.5),
                    borderRadius: "30px",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirm: !showPassword.confirm,
                          })
                        }
                      >
                        {showPassword.confirm ? (
                          <AiFillEye />
                        ) : (
                          <AiFillEyeInvisible />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Typography variant="caption" color="#C0C0C0" paddingLeft={"2em"}>
              Both passwords must match
            </Typography>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              size="large"
              sx={{
                background: "#FF414C",
                color: "white",
                fontSize: "0.8em",
                fontWeight: "700",
                borderRadius: "20px",
                paddingY: "0.7em",
                marginTop: "4em",
                boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
                ":hover": { background: "#FF414C" },
              }}
              onClick={handleSubmit}
              endIcon={<BiRightArrowAlt size={20} />}
            >
              Reset{" "}
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
