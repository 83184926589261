import React, { useEffect, useState } from "react";
import {
  Avatar,
  FormControlLabel,
  IconButton,
  Link,
  MobileStepper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import "../../utils/css/styles.css";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router";
import BWLogo from "../../assets/images/BWLogo.svg";
import { getGenres } from "../../store/other/actions";
import { useDispatch, useSelector } from "react-redux";
import { signUpStepTwo } from "../../store/auth/actions";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import requiredstr from "../../assets/images/requiredstr.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import imagePlaceholder from "../../assets/images/imagePlaceholder.svg";

const signupForm2Values = {
  bio: "",
  subgenres: [],
  category: "fiction",
};
const validationSchema = Yup.object().shape({
  bio: Yup.string().required(),
  category: Yup.string().required(),
  subgenres: Yup.array().min(1, "Select at least one option").required(),
});

const SignupComponent3 = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [genresToShow, setGenresToShow] = useState([]);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("fiction");

  const { signUp1Data } = useSelector((state) => state.auth);

  const { genres } = useSelector((state) => state.other);

  useEffect(() => {
    dispatch(getGenres());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCategory === "fiction") {
      if (genres && genres.fiction && genres.fiction.length) {
        setGenresToShow([...genres.fiction]);
      }
    }
    if (selectedCategory === "nonFiction") {
      if (genres && genres.nonfiction && genres.nonfiction.length) {
        setGenresToShow([...genres.nonfiction]);
      }
    }
  }, [genres, selectedCategory]);

  const handlePicture = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      setSelectedPicture(img);
    }
  };

  return (
    <Stack height={"100vh"} overflow="auto">
      <Stack justifyContent={"center"} alignItems={"center"} pt={10} pb={3}>
        <Stack alignItems={"center"} width="100%" mb={2}>
          <img src={BWLogo} alt="" />
          <Typography>Create an account to get started</Typography>
        </Stack>
        <Stack>
          <Typography fontWeight={"700"} color={"#FF414C"} variant={"h5"}>
            Step 3
          </Typography>
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={2}
            style={{
              backgroundColor: "transparent",
              justifyContent: "center",
              marginBottom: "1em",
            }}
          />
        </Stack>
        <Formik
          initialValues={signupForm2Values}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setIsLoading(true);
            let step2Values = JSON.parse(
              sessionStorage.getItem("signup-step-2-values")
            );

            let isFictional = values.category === "fiction" ? 1 : 2;
            let data = new FormData();
            data.append("email", signUp1Data.email);
            data.append("language_id", step2Values.language);
            data.append("country_id", step2Values.country);
            data.append("state_id", step2Values.province);
            data.append("city", step2Values.city);
            data.append("street_address", step2Values.street);
            data.append("zip_code", Number(step2Values.zip));
            data.append("bio", values.bio);
            if (selectedPicture) {
              data.append("profile_image", selectedPicture);
            }
            data.append("is_fictional", isFictional);
            data.append("fmc_token", "asdasd");
            values.subgenres.forEach((item, index) => {
              data.append(`genre_ids[${index}]`, item.id);
            });
            dispatch(signUpStepTwo({ data, nav, setIsLoading }));
          }}
        >
          {({
            values,
            handleChange,
            errors,
            field,
            setFieldValue,
            touched,
          }) => (
            <Form style={{ width: "100%" }}>
              <Stack spacing={5} alignItems={"center"}>
                <Stack width={{ xs: "90%", sm: "60%", md: "30%" }} spacing={2}>
                  <Stack alignItems={"center"}>
                    <input
                      accept="image/*"
                      id="upload-avatar-pic"
                      type="file"
                      hidden
                      onChange={handlePicture}
                    />
                    <label htmlFor="upload-avatar-pic">
                      <IconButton component="span">
                        <Avatar
                          alt=""
                          sx={{ width: 104, height: 104 }}
                          src={imageUrl ? imageUrl : imagePlaceholder}
                        />
                      </IconButton>
                    </label>
                  </Stack>
                  <Stack position={"relative"} borderRadius={"30px"}>
                    <Field
                      name="bio"
                      type={"text"}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline={true}
                          rows={4}
                          placeholder={"Bio"}
                          sx={{
                            "& label": {
                              paddingLeft: (theme) => theme.spacing(2),
                            },
                            "& fieldset": {
                              border:
                                touched.bio && errors.bio
                                  ? "1px solid red"
                                  : "1px solid #F5F5F5",
                              borderRadius: "30px",
                            },
                            backgroundColor: "#F5F5F5",
                            borderRadius: "30px",
                          }}
                        />
                      )}
                    />
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>
                  <RadioGroup
                    {...field}
                    name={"category"}
                    value={values.category}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      handleChange(e);
                    }}
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      flexWrap: "nowrap",
                      gap: "1em",
                    }}
                  >
                    <Stack
                      position={"relative"}
                      borderRadius={"30px"}
                      width={"50%"}
                    >
                      <FormControlLabel
                        defaultChecked
                        name="category"
                        value={"fiction"}
                        control={
                          <Radio
                            sx={{
                              color: "#FF414C",
                              "&.Mui-checked": {
                                color: "#FF414C",
                              },
                            }}
                          />
                        }
                        label={"Fiction"}
                        sx={{
                          backgroundColor: "#F5F5F5",
                          borderRadius: "30px",
                          paddingY: "1em",
                          paddingLeft: "1em",
                          margin: 0,
                          color: "#C0C0C0",
                          fontSize: "0.7vw",
                          "&.Mui-checked": {
                            color: "green",
                          },
                        }}
                      />
                      <img
                        src={requiredstr}
                        alt=""
                        width={"3%"}
                        style={{ position: "absolute" }}
                      />
                    </Stack>
                    <Stack
                      position={"relative"}
                      borderRadius={"30px"}
                      width={"50%"}
                    >
                      <FormControlLabel
                        value={"nonFiction"}
                        name="category"
                        control={
                          <Radio
                            sx={{
                              color: "#FF414C",
                              "&.Mui-checked": {
                                color: "#FF414C",
                              },
                            }}
                          />
                        }
                        label={"Non Fiction"}
                        sx={{
                          backgroundColor: "#F5F5F5",
                          borderRadius: "30px",

                          paddingY: "1em",
                          paddingLeft: "1em",
                          margin: 0,
                          color: "#C0C0C0",
                          fontSize: "0.7vw",
                          "&.Mui-checked": {
                            color: "green",
                          },
                        }}
                      />
                      <img
                        alt=""
                        width={"3%"}
                        src={requiredstr}
                        style={{ position: "absolute" }}
                      />
                    </Stack>
                  </RadioGroup>
                  <Stack
                    position={"relative"}
                    border={
                      touched.subgenres && errors.subgenres
                        ? "1px solid red"
                        : "1px solid #F5F5F5"
                    }
                    borderRadius={"30px"}
                  >
                    <Field
                      as={MultiSelect}
                      name={"subgenres"}
                      options={genresToShow}
                      value={values.subgenres}
                      handleChange={setFieldValue}
                    />
                    <img
                      alt=""
                      width={"1.5%"}
                      src={requiredstr}
                      style={{ position: "absolute" }}
                    />
                  </Stack>
                  {/* <Stack
                    position={"relative"}
                    border={
                      errors.language ? "1px solid red" : "1px solid #F5F5F5"
                    }
                    borderRadius={"30px"}
                  >
                    <Field
                      as={Select}
                      name={"subgenres"}
                      value={values.subgenres}
                      onChange={handleChange}
                      sx={{
                        "& .MuiSelect-select .notranslate::after": "Subgenres"
                          ? {
                              content: `"Subgenres"`,
                              opacity: 0.42,
                            }
                          : {},
                        "& fieldset": {
                          border: "none",
                          borderRadius: "30px",
                        },
                        border: "none",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                      }}
                    >
                      {genresToShow && genresToShow.length
                        ? genresToShow.map((item, i) => {
                            return (
                              <MenuItem value={item.id} key={i}>
                                {item.name}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Field>
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack> */}
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                    size="large"
                    sx={{
                      borderRadius: "54.6591px",
                      bgcolor: "#F83D4B",
                      fontWeight: "700",
                      mt: "64px !Important",
                      p: 2,
                      width: "100%",
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
        <Typography alignSelf={"center"} mt={5}>
          Already have an account?{" "}
          <Link
            component="button"
            onClick={() => nav("/login")}
            underline="none"
            fontWeight={"700"}
            color={"black"}
          >
            Log in
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SignupComponent3;
