import React, { useEffect, useState } from "react";
import {
  Radio,
  Button,
  FormLabel,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { Stack } from "@mui/system";
import { MultiSelect } from "./MultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountries,
  getGenres,
  getLanguages,
} from "../../store/other/actions";
import {
  filterAgencies,
  filterPitches,
  getAgencies,
  getAllPitches,
} from "../../store/author/actions";

const FilterBar = () => {
  const dispatch = useDispatch();
  let pathName = window.location.pathname;

  const [genreType, setGenreType] = useState("fiction");
  const [filterValues, setFilterValues] = useState({
    country: "",
    language: "",
    genres: [],
  });

  const { user } = useSelector((state) => state.auth);
  console.log("🚀 ~ file: FilterBar.jsx:38 ~ FilterBar ~ user:", user)
  const { genres, countries, languages } = useSelector((state) => state.other);

  useEffect(() => {
    if (!genres?.fiction?.length && !genres?.nonfiction?.length) {
      dispatch(getGenres());
    }
    if (!countries?.length) {
      dispatch(getCountries());
    }
    if (!languages?.length) {
      dispatch(getLanguages());
    }
  }, [dispatch, genres, countries, languages]);

  useEffect(() => {
    if (user?.is_fictional === 1) {
      setGenreType("fiction");
    } else {
      setGenreType("non-fiction");
    }
  }, [user]);

  const handleLanguageSelect = (value) => {
    setFilterValues({
      ...filterValues,
      language: { label: value?.name, value: value?.name },
    });
    let data = {
      language: value?.name,
      country: filterValues?.country?.value ? filterValues?.country?.value : "",
      genres: filterValues.genres,
    };
    if (pathName === "/all-submissions") {
      dispatch(filterPitches(data));
    } else {
      dispatch(filterAgencies(data));
    }
  };

  const handleCountrySelect = (value) => {
    setFilterValues({
      ...filterValues,
      country: { label: value?.name, value: value?.name },
    });
    let data = {
      country: value?.name,
      genres: filterValues.genres,
      language: filterValues?.language?.value
        ? filterValues?.language?.value
        : "",
    };
    if (pathName === "/all-submissions") {
      dispatch(filterPitches(data));
    } else {
      dispatch(filterAgencies(data));
    }
  };

  const handleGenreSelect = (values) => {
    let valuesData = values.length
      ? values.map((item) => {
          return { label: item?.label, value: item?.value };
        })
      : [];
    setFilterValues({ ...filterValues, genres: valuesData });

    let genresArray = values.length ? values.map((item) => item.label) : [];
    let data = {
      country: filterValues?.country?.value ? filterValues?.country?.value : "",
      language: filterValues?.language?.value
        ? filterValues?.language?.value
        : "",
      genres: genresArray,
    };
    if (pathName === "/all-submissions") {
      dispatch(filterPitches(data));
    } else {
      dispatch(filterAgencies(data));
    }
  };

  const handleClearFilters = () => {
    if (pathName === "/all-submissions") {
      dispatch(getAllPitches());
    } else {
      dispatch(getAgencies());
    }
    setFilterValues({
      country: "",
      language: "",
      genres: [],
    });
  };

  return (
    <Stack justifyContent={"space-between"} height={"100%"}>
      <Stack spacing={1}>
        <Typography
          textAlign={"center"}
          fontWeight={700}
          fontSize={"1.5em"}
          pt={2}
        >
          Filters
        </Typography>
        <FormControl>
          <FormLabel
            id="genre-radio"
            sx={{
              fontWeight: 600,
              color: "#000000",
            }}
          >
            Genres
          </FormLabel>
          <RadioGroup
            row
            value={genreType}
            aria-labelledby="genre-radio"
            name="row-radio-buttons-group"
            onChange={(e) => setGenreType(e.target.value)}
          >
            <FormControlLabel
              value="fiction"
              disabled={genreType === "non-fiction"}
              control={
                <Radio
                  sx={{
                    color: "#FF414C",
                    "&.Mui-checked": {
                      color: "#FF414C",
                    },
                  }}
                />
              }
              label="Fiction"
            />
            <FormControlLabel
              value="non-fiction"
              disabled={genreType === "fiction"}
              control={
                <Radio
                  sx={{
                    color: "#FF414C",
                    "&.Mui-checked": {
                      color: "#FF414C",
                    },
                  }}
                />
              }
              label="Nonfiction"
            />
          </RadioGroup>
        </FormControl>
        <Typography fontWeight={600}>Subgenres</Typography>
        <MultiSelect
          isMulti={true}
          value={filterValues.genres}
          placeholder={"Choose Genres"}
          options={genreType === "fiction" ? genres.fiction : genres.nonfiction}
          onChange={handleGenreSelect}
        />
        <Typography fontWeight={600}>Country</Typography>
        <MultiSelect
          isMulti={false}
          options={countries}
          placeholder={"Choose a Country"}
          value={filterValues.country}
          onChange={handleCountrySelect}
        />
        <Typography fontWeight={600}>Language</Typography>
        <MultiSelect
          isMulti={false}
          options={languages}
          value={filterValues.language}
          onChange={handleLanguageSelect}
          placeholder={"Choose a Language"}
        />
      </Stack>
      <Button
        fullWidth
        size="large"
        py=".5em"
        type="submit"
        variant="contained"
        sx={{
          background: "#FF414C",
          color: "white",
          boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
          borderRadius: "35.5689px",
        }}
        onClick={handleClearFilters}
      >
        Clear Filters
      </Button>
    </Stack>
  );
};

export default FilterBar;
