import axios from "../../axios/AxiosConfig";
import {
  stopLoading,
  loginSuccess,
  startLoading,
  errorHandler,
  signUpStepOneSuccess,
  signUpStepTwoSuccess,
  verifySignUpStepOneSuccess,
  updateEmailSuccess,
  updateEmailVerifySuccess,
  updatePhoneNumberSuccess,
  updatePhoneNumberVerifySuccess,
  updatePasswordSuccess,
  logoutUser,
  getUpdatedAuthorProfileSuccess,
  getUpdatedAuthorProfile,
  forgetPasswordSuccess,
  forgetPasswordVerifySuccess,
  resetPasswordSuccess,
  fetchUserSuccess,
} from "./actions";
import {
  FETCH_USER,
  FORGET_PASSWORD,
  FORGET_PASSWORD_VERIFY,
  GET_UPDATED_AUTHOR_PROFILE,
  LOGIN,
  RESET_PASSWORD,
  SIGN_UP_STEP_ONE,
  SIGN_UP_STEP_TWO,
  UPDATE_EMAIL,
  UPDATE_EMAIL_VERIFICATION,
  UPDATE_PASSWORD,
  UPDATE_PHONE_NUMBER,
  UPDATE_PHONE_VERIFICATION,
  VERIFY_STEP_ONE,
} from "./actionTypes";
import { fork, put, all, takeLatest } from "redux-saga/effects";

function* loginAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("login", payload.data);
    yield put(
      loginSuccess({ ...response.data, rememberMe: payload.rememberMe })
    );
    payload.nav("/");
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* fetchUserAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("user-detail-like-login");
    yield put(fetchUserSuccess({ ...response?.data }));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* signUpStepOneAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("register/step-one", payload.data);
    yield put(signUpStepOneSuccess(response.data));
    payload.nav("/sign-up-2");
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* verifySignUpStepOneAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("register/verify-step-one", payload.data);
    yield put(verifySignUpStepOneSuccess(response.data));
    payload.nav("/sign-up-2");
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* signUpStepTwoAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("register/step-two", payload.data);
    yield put(signUpStepTwoSuccess(response.data));
    payload.nav("/login");
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    if (payload.setIsLoading) {
      payload.setIsLoading(false);
    }
    yield put(stopLoading());
  }
}

function* updateEmailAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("agency/email/reset", payload.data);
    yield put(updateEmailSuccess({ ...response.data, email: payload.email }));
    payload.navigate("/update-email-verify");
    payload.handleClose();
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    payload.setIsLoading(false);
    yield put(stopLoading());
  }
}

function* updateEmailVerifyAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("agency/email/update", payload.data);
    yield put(updateEmailVerifySuccess(response.data));
    payload.setIsLoading(false);
    yield put(logoutUser());
    payload.navigate("/login");
  } catch (error) {
    payload.setIsLoading(false);
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* updatePhoneNumberAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("author/update-phone", payload.data);
    yield put(
      updatePhoneNumberSuccess({ ...response.data, newPhone: payload.newPhone })
    );
    payload.handleClose();
    payload.navigate("/update-phone-verify");
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    payload.setIsLoading(false);
    yield put(stopLoading());
  }
}

function* updatePhoneNumberVerifyAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("author/verify-code", payload.data);
    yield put(updatePhoneNumberVerifySuccess(response.data));
    yield put(getUpdatedAuthorProfile());
    payload.navigate("/user-profile");
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    payload.setIsLoading(false);
    yield put(stopLoading());
  }
}

function* updatePasswordAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.patch("agency/password-update", payload.data);
    yield put(updatePasswordSuccess(response.data));
    payload.handleClose();
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    payload.setIsLoading(false);
    yield put(stopLoading());
  }
}

function* forgetPasswordAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("agency/password/forget", payload.data);
    yield put(forgetPasswordSuccess(response.data));
    if (payload?.navigate) {
      payload?.navigate("/forget-password-verify");
    }
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    payload.setIsLoading(false);
    yield put(stopLoading());
  }
}

function* forgetPasswordVerifyAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("agency/match/code", payload.data);
    yield put(forgetPasswordVerifySuccess(response.data));
    if (payload?.navigate) {
      payload?.navigate("/reset-password");
    }
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    payload.setIsLoading(false);
    yield put(stopLoading());
  }
}

function* resetPasswordAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("agency/password/reset", payload.data);
    yield put(resetPasswordSuccess(response.data));
    if (payload?.navigate) {
      payload?.navigate("/login");
    }
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    payload.setIsLoading(false);
    yield put(stopLoading());
  }
}

function* getUpdatedAuthorProfileAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("author", payload);
    yield put(getUpdatedAuthorProfileSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* watchLogin() {
  yield takeLatest(LOGIN, loginAction);
}

function* watchFetchUser() {
  yield takeLatest(FETCH_USER, fetchUserAction);
}

function* watchSignUpStepOne() {
  yield takeLatest(SIGN_UP_STEP_ONE, signUpStepOneAction);
}

function* watchVerifySignUpStepOne() {
  yield takeLatest(VERIFY_STEP_ONE, verifySignUpStepOneAction);
}

function* watchSignUpStepTwo() {
  yield takeLatest(SIGN_UP_STEP_TWO, signUpStepTwoAction);
}

function* watchUpdateEmail() {
  yield takeLatest(UPDATE_EMAIL, updateEmailAction);
}

function* watchUpdateEmailVerify() {
  yield takeLatest(UPDATE_EMAIL_VERIFICATION, updateEmailVerifyAction);
}

function* watchUpdatePhoneNumber() {
  yield takeLatest(UPDATE_PHONE_NUMBER, updatePhoneNumberAction);
}

function* watchUpdatePhoneNumberVerify() {
  yield takeLatest(UPDATE_PHONE_VERIFICATION, updatePhoneNumberVerifyAction);
}

function* watchUpdatePassword() {
  yield takeLatest(UPDATE_PASSWORD, updatePasswordAction);
}

function* watchGetUpdatedAuthorProfile() {
  yield takeLatest(GET_UPDATED_AUTHOR_PROFILE, getUpdatedAuthorProfileAction);
}

function* watchForgetPassword() {
  yield takeLatest(FORGET_PASSWORD, forgetPasswordAction);
}

function* watchForgetPasswordVerify() {
  yield takeLatest(FORGET_PASSWORD_VERIFY, forgetPasswordVerifyAction);
}

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordAction);
}

export default function* authSaga() {
  yield all([
    fork(watchLogin),
    fork(watchFetchUser),
    fork(watchSignUpStepOne),
    fork(watchSignUpStepTwo),
    fork(watchVerifySignUpStepOne),
    fork(watchUpdateEmail),
    fork(watchUpdateEmailVerify),
    fork(watchUpdatePhoneNumber),
    fork(watchUpdatePhoneNumberVerify),
    fork(watchUpdatePassword),
    fork(watchGetUpdatedAuthorProfile),
    fork(watchForgetPassword),
    fork(watchForgetPasswordVerify),
    fork(watchResetPassword),
  ]);
}
