import React, { useEffect } from "react";
import {
  Stack,
  Select,
  MenuItem,
  TextField,
  Typography,
  MobileStepper,
  Link,
} from "@mui/material";
import * as Yup from "yup";
import "../../utils/css/styles.css";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { Formik, Form, Field } from "formik";
import BWLogo from "../../assets/images/BWLogo.svg";
import requiredstr from "../../assets/images/requiredstr.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getStates,
  getCountries,
  getLanguages,
} from "../../store/other/actions";

const signupForm2Values = {
  language: "",
  country: "",
  province: "",
  city: "",
  zip: "",
  street: "",
};
const validationSchema = Yup.object().shape({
  language: Yup.string().required(),
  country: Yup.string().required(),
  province: Yup.string().required(),
  city: Yup.string().required(),
  zip: Yup.string().required(),
  street: Yup.string().required(),
});

const SignupComponent2 = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getCountries());
  }, [dispatch]);

  const { countries, languages, states, cities } = useSelector(
    (state) => state.other
  );

  return (
    <Stack height={"100vh"} overflow="auto">
      <Stack justifyContent={"center"} alignItems={"center"} pt={10} pb={3}>
        <Stack alignItems={"center"} width="100%" mb={2}>
          <img src={BWLogo} alt="" />
          <Typography>Create an account to get started</Typography>
        </Stack>
        <Stack>
          <Typography fontWeight={"700"} color={"#FF414C"} variant={"h5"}>
            Step 2
          </Typography>
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={1}
            style={{
              backgroundColor: "transparent",
              justifyContent: "center",
              marginBottom: "1em",
            }}
          />
        </Stack>
        <Formik
          initialValues={signupForm2Values}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            sessionStorage.setItem(
              "signup-step-2-values",
              JSON.stringify(values)
            );
            nav("/sign-up-3");
          }}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form style={{ width: "100%" }}>
              <Stack spacing={5} alignItems={"center"}>
                <Stack width={{ xs: "90%", sm: "60%", md: "25%" }} spacing={2}>
                  <Stack
                    position={"relative"}
                    border={
                      touched.language && errors.language
                        ? "1px solid red"
                        : "1px solid #F5F5F5"
                    }
                    borderRadius={"30px"}
                  >
                    <Field
                      as={Select}
                      name={"language"}
                      value={values.language}
                      onChange={handleChange}
                      sx={{
                        "& .MuiSelect-select .notranslate::after": "Language"
                          ? {
                              content: `"Language"`,
                              opacity: 0.42,
                            }
                          : {},
                        "& fieldset": {
                          border: "none",
                          borderRadius: "30px",
                        },
                        border: "none",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                      }}
                    >
                      {languages && languages.length
                        ? languages.map((item, i) => {
                            return (
                              <MenuItem value={item.id} key={i}>
                                {item.name}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Field>
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>

                  <Stack
                    position={"relative"}
                    border={
                      touched.country && errors.country
                        ? "1px solid red"
                        : "1px solid #F5F5F5"
                    }
                    borderRadius={"30px"}
                  >
                    <Field
                      as={Select}
                      name={"country"}
                      value={values.country}
                      onChange={(e) => {
                        dispatch(getStates(e.target.value));
                        handleChange(e);
                      }}
                      sx={{
                        "& .MuiSelect-select .notranslate::after": "Country"
                          ? {
                              content: `"Country"`,
                              opacity: 0.42,
                            }
                          : {},
                        "& fieldset": {
                          border: "none",
                          borderRadius: "30px",
                        },
                        border: "none",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                      }}
                    >
                      {countries && countries.length
                        ? countries.map((item, i) => {
                            return (
                              <MenuItem value={item.id} key={i}>
                                {item.name}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Field>
                    <img
                      alt=""
                      width={"1.5%"}
                      src={requiredstr}
                      style={{ position: "absolute" }}
                    />
                  </Stack>

                  <Stack
                    position={"relative"}
                    border={
                      touched.province && errors.province
                        ? "1px solid red"
                        : "1px solid #F5F5F5"
                    }
                    borderRadius={"30px"}
                  >
                    <Field
                      as={Select}
                      name={"province"}
                      value={values.province}
                      onChange={(e) => {
                        dispatch(getCities(e.target.value));
                        handleChange(e);
                      }}
                      sx={{
                        "& .MuiSelect-select .notranslate::after":
                          "State / Province"
                            ? {
                                content: `"State / Province"`,
                                opacity: 0.42,
                              }
                            : {},
                        "& fieldset": {
                          border: "none",
                          borderRadius: "30px",
                        },
                        border: "none",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                      }}
                    >
                      {states && states.length
                        ? states.map((item, i) => {
                            return (
                              <MenuItem value={item.id} key={i}>
                                {item.name}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Field>
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>

                  <Stack
                    position={"relative"}
                    border={
                      touched.city && errors.city
                        ? "1px solid red"
                        : "1px solid #F5F5F5"
                    }
                    borderRadius={"30px"}
                  >
                    <Field
                      as={TextField}
                      name={"city"}
                      value={values.city}
                      onChange={handleChange}
                      placeholder="City"
                      sx={{
                        "& .MuiSelect-select .notranslate::after": "City"
                          ? {
                              content: `"City"`,
                              opacity: 0.42,
                            }
                          : {},
                        "& fieldset": {
                          border: "none",
                          borderRadius: "30px",
                        },
                        border: "none",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                      }}
                    />
                    {/* {cities && cities.length
                        ? cities.map((item, i) => {
                            return (
                              <MenuItem value={item.name} key={i}>
                                {item.name}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Field> */}
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>

                  <Stack
                    position={"relative"}
                    border={
                      touched.street && errors.street
                        ? "1px solid red"
                        : "1px solid #F5F5F5"
                    }
                    borderRadius={"30px"}
                  >
                    <Field
                      type={"text"}
                      as={TextField}
                      name="street"
                      placeholder={"Street Address"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        "& input": {
                          paddingLeft: (theme) => theme.spacing(3.5),
                          backgroundColor: "#F5F5F5",
                          borderRadius: "35px",
                        },
                        "& fieldset": {
                          border: "1px solid #F5F5F5",
                          borderRadius: "30px",
                        },
                      }}
                    />
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>

                  <Stack
                    position={"relative"}
                    border={
                      touched.zip && errors.zip
                        ? "1px solid red"
                        : "1px solid #F5F5F5"
                    }
                    borderRadius={"30px"}
                  >
                    <Field
                      type={"text"}
                      as={TextField}
                      name="zip"
                      placeholder={"Zip/Postal Code"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        "& input": {
                          paddingLeft: (theme) => theme.spacing(3.5),
                          backgroundColor: "#F5F5F5",
                          borderRadius: "35px",
                        },
                        "& fieldset": {
                          border: "1px solid #F5F5F5",
                          borderRadius: "30px",
                        },
                      }}
                    />
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    size="large"
                    sx={{
                      borderRadius: "54.6591px",
                      bgcolor: "#F83D4B",
                      fontWeight: "700",
                      mt: "64px !Important",
                      p: 2,
                      width: "100%",
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
        <Typography alignSelf={"center"} mt={5}>
          Already have an account?{" "}
          <Link
            component="button"
            onClick={() => nav("/login")}
            underline="none"
            fontWeight={"700"}
            color={"black"}
          >
            Log in
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SignupComponent2;
