import React from "react";
import { Stack } from "@mui/system";
import { InputAdornment, TextField } from "@mui/material";
import searchIcon from "../../assets/images/searchIcon.svg";

const GlobalWrapper = ({ children }) => {
  return (
    <Stack minHeight={"85vh"} px={"3.125em"} py={2}>
      <Stack height={"85vh"}>{children}</Stack>
    </Stack>
  );
};

export default GlobalWrapper;
