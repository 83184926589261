import { Button, IconButton, Modal, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { defaultModalStyle } from "../../utils/Constants";
import closeIcon from "../../assets/images/close-icon.svg";

export const HowToSubmitDlg = ({ handleClose, open }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing="1em"
        height={"85vh"}
        overflow={"auto"}
        alignItems={"center"}
        sx={defaultModalStyle}
        width={{ xs: "90%", sm: "80vw", md: "45vw" }}
      >
        <IconButton
          component="span"
          onClick={handleClose}
          sx={{ position: "absolute", right: "6px", top: "10px" }}
        >
          <img alt="" src={closeIcon} style={{ width: "15px" }} />
        </IconButton>
        <Typography fontWeight={700} variant={"h4"} mt={"0.3em !important"}>
          How to submit?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "0 !important",
            width: { sm: "60%", md: "40%" },
          }}
        >
          Here is what you will need for your manuscript submission.
        </Typography>
        <Stack
          sx={{
            gap: "2em",
            width: "100%",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
          }}
        >
          <Stack width={{ xs: "100%", sm: "50%" }}>
            <Typography>Fiction</Typography>
            <Stack
              sx={{
                background: "#F9F9F9",
                paddingX: "2em",
                paddingY: "1em",
                borderRadius: "30px",
              }}
            >
              <Typography>- Book Title</Typography>
              <Typography>- 280 characters of Pitch</Typography>
              <Typography>- Genre and Subgenre</Typography>
              <Typography>- Category (age appropriate)</Typography>
              <Typography>- Potential Target Audience</Typography>
              <Typography>- Plot</Typography>
              <Typography>- Theme</Typography>
              <Typography>- Characters</Typography>
              <Typography>- Wordcount</Typography>
              <Typography>- Manuscript Language</Typography>
              <Typography>- Manuscript or Published Book</Typography>
              <Typography>- Single Book or Series</Typography>
              <Typography paddingTop={"1em"} fontWeight={700}>
                Required Attachments
              </Typography>
              <Typography> Query Letter .pdf</Typography>
              <Typography>- Synopsis .pdf</Typography>
              <Typography>- The first 50 pages .pdf (Chapter 1 on)</Typography>
            </Stack>
          </Stack>
          <Stack width={{ xs: "100%", sm: "50%" }}>
            <Typography>Nonfiction</Typography>
            <Stack
              sx={{
                background: "#F9F9F9",
                paddingX: "2em",
                paddingY: "1em",
                borderRadius: "30px",
              }}
            >
              <Typography>- Book Title</Typography>
              <Typography>- 280 characters of Pitch</Typography>
              <Typography>- Genre and Subgenre</Typography>
              <Typography>- Category (age appropriate)</Typography>
              <Typography>- Potential Target Audience</Typography>
              <Typography>- Wordcount</Typography>
              <Typography>- Manuscript Language</Typography>
              <Typography>- Manuscript or Published Book</Typography>
              <Typography>- Single Book or Series</Typography>
              <Typography paddingTop={"1em"} fontWeight={700}>
                Required Attachments
              </Typography>
              <Typography>- Book Proposal</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Button
          sx={{
            background: "#FF414C",
            color: "white",
            fontSize: "0.8em",
            fontWeight: "700",
            borderRadius: "20px",
            width: { xs: "70%", sm: "50%" },
            paddingY: "0.7em",
            marginTop: "4em",
            boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
            ":hover": { background: "#FF414C" },
          }}
          onClick={handleClose}
        >
          Start Your Submission
        </Button>
      </Stack>
    </Modal>
  );
};
