import React from "react";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";

export const TermsAndConditions = () => {
  return (
    <Stack padding={"2em"}>
      <Typography fontWeight={700}>Terms and Conditions</Typography>
      <Typography fontWeight={700} paddingTop={"1.5em"}>
        What is Lorem Ipsum?
      </Typography>
      <Typography>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged.
      </Typography>
      <Typography fontWeight={700} paddingTop={"1.5em"}>
        Where does it come from?
      </Typography>
      <Typography>
        Contrary to popular belief, Lorem Ipsum is not simply random text. It
        has roots in a piece of classical Latin literature from 45 BC, making it
        over 2000 years old.
      </Typography>
      <Typography paddingTop={"1em"}>
        The standard chunk of Lorem Ipsum used since the 1500s is reproduced
        below for those interested. Sections 1.10.32 and 1.10.33 from "de
        Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact
        original form, accompanied by English versions from the 1914 translation
        by H. Rackham.
      </Typography>
      <Typography fontWeight={700} paddingTop={"1.5em"}>
        Why do we use it?
      </Typography>
      <Typography>
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that it has a more-or-less normal distribution of
        letters, as opposed to using 'Content here, content here', making it
        look like readable English. Many desktop publishing packages and web
        page editors now use Lorem Ipsum as their default model text, and a
        search for 'lorem ipsum' will uncover many web sites still in their
        infancy. Various versions have evolved over the years, sometimes by
        accident, sometimes on purpose (injected humour and the like).
      </Typography>
    </Stack>
  );
};
