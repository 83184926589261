import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { agencyCarouselSliderSettings } from "../../utils/Constants";
import "../../utils/css/styles.css";
import AgencyCard from "../cards/AgencyCard";

const AgencyCarousal = ({ agencies }) => {
  const ref = useRef(null);

  const CustomeSlide = ({ item, index }) => {
    return (
      <div key={index} className="agency-tab-carousal">
        <AgencyCard agency={item} />
      </div>
    );
  };
  return (
    <>
      <div>
        {agencies && agencies.length ? (
          <Slider
            {...agencyCarouselSliderSettings}
            onSwipe={() => (ref.current = true)}
          >
            {agencies.map((item, i) => {
              return <CustomeSlide item={item} index={i} />;
            })}
          </Slider>
        ) : 
        null}
      </div>
    </>
  );
};

export default AgencyCarousal;
