import axios from "../../axios/AxiosConfig";
import {
  stopLoading,
  startLoading,
  errorHandler,
  getCoutriesSuccess,
  getStatesSuccess,
  getCitiesSuccess,
  getPlotsSuccess,
  getCategoriesSuccess,
  getGenresSuccess,
  getLanguagesSuccess,
  getAdvertisementsSuccess,
} from "./actions";
import { fork, put, all, takeLatest } from "redux-saga/effects";
import {
  GET_ADVERTISEMENTS,
  GET_CATEGORIES,
  GET_CITIES,
  GET_COUNTRIES,
  GET_GENRES,
  GET_LANGUAGES,
  GET_PLOTS,
  GET_STATES,
} from "./actionTypes";

function* getCoutriesAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("countries");
    yield put(getCoutriesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getStatesAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get(`states?country_id=${payload}`);
    yield put(getStatesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getCitiesAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get(`cities?state_id=${payload}`);
    yield put(getCitiesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getPlotsAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("plots");
    yield put(getPlotsSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getCategoriesAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("categories");
    yield put(getCategoriesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getGenresAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("genres");
    yield put(getGenresSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getLanguagesAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("languages");
    yield put(getLanguagesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getAdvertisementsAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("advertisements");
    yield put(getAdvertisementsSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* watchGetCoutries() {
  yield takeLatest(GET_COUNTRIES, getCoutriesAction);
}

function* watchGetStates() {
  yield takeLatest(GET_STATES, getStatesAction);
}

function* watchGetCities() {
  yield takeLatest(GET_CITIES, getCitiesAction);
}

function* watchGetPlots() {
  yield takeLatest(GET_PLOTS, getPlotsAction);
}

function* watchGetCategories() {
  yield takeLatest(GET_CATEGORIES, getCategoriesAction);
}

function* watchGetGenres() {
  yield takeLatest(GET_GENRES, getGenresAction);
}

function* watchGetLanguages() {
  yield takeLatest(GET_LANGUAGES, getLanguagesAction);
}

function* watchGetAdvertisements() {
  yield takeLatest(GET_ADVERTISEMENTS, getAdvertisementsAction);
}

export default function* otherSaga() {
  yield all([
    fork(watchGetCoutries),
    fork(watchGetStates),
    fork(watchGetCities),
    fork(watchGetPlots),
    fork(watchGetCategories),
    fork(watchGetGenres),
    fork(watchGetLanguages),
    fork(watchGetAdvertisements),
  ]);
}
