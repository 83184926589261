import React, { useState } from "react";
import { Stack } from "@mui/system";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import isEmail from "validator/lib/isEmail";
import { BiRightArrowAlt } from "react-icons/bi";
import { updateEmail } from "../../store/auth/actions";
import { Button, TextField, Typography } from "@mui/material";

export const UpdateEmail = () => {
  const dispatch = useDispatch();
  const [emailValue, setEmailValue] = useState("");

  const handleSubmit = () => {
    if (emailValue && isEmail(emailValue)) {
      dispatch(updateEmail({ email: emailValue }));
    } else {
      toast.error("Enter a Valid Email");
    }
  };
  return (
    <Stack justifyContent={"center"} alignItems={"center"} height={"100vh"}>
      <Stack width={{ xs: "60%", sm: "45%", md: "40%", lg: "25%" }}>
        <Typography variant="h4" fontWeight={"700"} textAlign={"center"}>
          Update Email
        </Typography>
        <Typography textAlign={"center"}>
          Enter your email and we’ll send you a code to update your email
        </Typography>
        <TextField
          required
          type="email"
          placeholder="Email"
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
          sx={{
            marginTop: "4em",
            "& label": { paddingLeft: (theme) => theme.spacing(2) },
            "& input": {
              paddingLeft: (theme) => theme.spacing(3.5),
              backgroundColor: "#F5F5F5",
              borderRadius: "30px",
            },
            "& fieldset": {
              border: "none",
              borderRadius: "30px",
            },
          }}
        />
        <Button
          sx={{
            color: "white",
            paddingY: "1em",
            marginTop: "4em",
            fontSize: "0.8em",
            fontWeight: "700",
            borderRadius: "20px",
            background: "#FF414C",
            ":hover": { background: "#FF414C" },
            boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
          }}
          onClick={handleSubmit}
        >
          Send <BiRightArrowAlt size={20} />
        </Button>
      </Stack>
    </Stack>
  );
};
