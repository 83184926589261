import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  MobileStepper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import "../../utils/css/styles.css";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Formik, Form, Field } from "formik";
import BWLogo from "../../assets/images/BWLogo.svg";
import requiredstr from "../../assets/images/requiredstr.svg";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { signupForm1Values } from "../../utils/Constants";
import { signUpStepOne } from "../../store/auth/actions";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  userName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().required("Required"),
  reEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "Email must match")
    .required("Required"),
  phoneNumber: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  terms: Yup.bool(),
});

const SignupComponent1 = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfimPassword, setShowConfirmPassword] = useState(false);

  const { isLoading } = useSelector((state) => state.auth);
  const [telInput, setTelInput] = useState();
  console.log(
    "🚀 ~ file: SignupComponent1.jsx:65 ~ SignupComponent1 ~ telInput:",
    typeof telInput
  );

  return (
    <Stack minHeight={"100vh"} overflow="auto">
      <Stack justifyContent={"center"} alignItems={"center"} pt={10} pb={3}>
        <Stack alignItems={"center"} width="100%" mb={2}>
          <img src={BWLogo} alt="" />
          <Typography>Create an account to get started</Typography>
        </Stack>
        <Stack>
          <Typography fontWeight={"700"} color={"#FF414C"} variant={"h5"}>
            Step 1
          </Typography>
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={0}
            style={{
              backgroundColor: "transparent",
              justifyContent: "center",
              marginBottom: "1em",
            }}
          />
        </Stack>
        <Formik
          initialValues={signupForm1Values}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            let data = new FormData();
            data.append("first_name", values.firstName);
            data.append("last_name", values.lastName);
            data.append("username", values.userName);
            data.append("email", values.email);
            data.append("phone", values.phoneNumber);
            data.append("password", values.password);
            dispatch(signUpStepOne({ data, nav }));
          }}
        >
          {({ values, handleChange, errors, touched, setFieldValue }) => (
            <Form style={{ width: "100%" }}>
              {console.log("values", values)}
              <Stack spacing={5} alignItems={"center"}>
                <Stack width={{ xs: "90%", sm: "60%", md: "25%" }} spacing={2}>
                  <Stack direction={"row"} spacing={1}>
                    <Stack position={"relative"} width={"50%"}>
                      <Field
                        type={"text"}
                        as={TextField}
                        name="firstName"
                        placeholder={"First Name"}
                        error={touched.firstName && errors.firstName}
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            paddingLeft: (theme) => theme.spacing(3.5),
                            backgroundColor: "#F5F5F5",
                            borderRadius: "35px",
                          },
                          "& fieldset": {
                            border: "1px solid #F5F5F5",
                            borderRadius: "30px",
                          },
                        }}
                      />
                      {touched.firstName && errors.firstName && (
                        <Typography color={"red"}>
                          {errors.firstName}
                        </Typography>
                      )}
                      <img
                        alt=""
                        width={"3%"}
                        src={requiredstr}
                        style={{ position: "absolute" }}
                      />
                    </Stack>
                    <Stack position={"relative"} width={"50%"}>
                      <Field
                        type={"text"}
                        as={TextField}
                        name="lastName"
                        error={touched.lastName && errors.lastName}
                        placeholder={"Last Name"}
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            paddingLeft: (theme) => theme.spacing(3.5),
                            backgroundColor: "#F5F5F5",
                            borderRadius: "35px",
                          },
                          "& fieldset": {
                            border: "1px solid #F5F5F5",
                            borderRadius: "30px",
                          },
                        }}
                      />
                      {touched.lastName && errors.lastName && (
                        <Typography color={"red"}>{errors.lastName}</Typography>
                      )}
                      <img
                        alt=""
                        width={"3%"}
                        src={requiredstr}
                        style={{ position: "absolute" }}
                      />
                    </Stack>
                  </Stack>
                  <Stack position={"relative"}>
                    <Field
                      type={"text"}
                      as={TextField}
                      name="userName"
                      error={touched.userName && errors.userName}
                      placeholder={"User Name"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        "& input": {
                          paddingLeft: (theme) => theme.spacing(3.5),
                          backgroundColor: "#F5F5F5",
                          borderRadius: "35px",
                        },
                        "& fieldset": {
                          border: "1px solid #F5F5F5",
                          borderRadius: "30px",
                        },
                      }}
                    />
                    {touched.userName && errors.userName && (
                      <Typography color={"red"}>{errors.userName}</Typography>
                    )}
                    <img
                      alt=""
                      width={"1.5%"}
                      src={requiredstr}
                      style={{ position: "absolute" }}
                    />
                  </Stack>
                  <Stack position={"relative"}>
                    <Field
                      type={"email"}
                      as={TextField}
                      name="email"
                      error={touched.email && errors.email}
                      placeholder={"Email"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        "& input": {
                          paddingLeft: (theme) => theme.spacing(3.5),
                          backgroundColor: "#F5F5F5",
                          borderRadius: "35px",
                        },
                        "& fieldset": {
                          border: "1px solid #F5F5F5",
                          borderRadius: "30px",
                        },
                      }}
                    />
                    {touched.email && errors.email && (
                      <Typography color={"red"}>{errors.email}</Typography>
                    )}
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>
                  <Stack position={"relative"}>
                    <Field
                      type={"email"}
                      as={TextField}
                      name="reEmail"
                      error={touched.reEmail && errors.reEmail}
                      placeholder={"Re-Enter Email"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        "& input": {
                          paddingLeft: (theme) => theme.spacing(3.5),
                          backgroundColor: "#F5F5F5",
                          borderRadius: "35px",
                        },
                        "& fieldset": {
                          border: "1px solid #F5F5F5",
                          borderRadius: "30px",
                        },
                      }}
                    />
                    {touched.reEmail && errors.reEmail && (
                      <Typography color={"red"}>{errors.reEmail}</Typography>
                    )}
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>
                  <Stack
                    position={"relative"}
                    sx={{
                      width: "100%",
                      border:
                        touched.phoneNumber && errors.phoneNumber
                          ? "2px solid red"
                          : "none",
                      marginTop: "1em",
                      flexDirection: "row",
                      alignItems: "center",
                      backgroundColor: "#F5F5F5",
                      justifyContent: "space-between",
                      "& fieldset": {
                        border: "1px solid #F5F5F5",
                        borderRadius: "30px",
                      },
                      borderRadius: "35px",
                      height: "3em",
                    }}
                  >
                    <Field name="phoneNumber">
                      {({ field }) => (
                        <PhoneInput
                          {...field}
                          defaultCountry="US"
                          placeholder="Enter phone number"
                          value={values.phoneNumber}
                          onChange={(e) => setFieldValue("phoneNumber", e)}
                          style={{
                            height: "100%",
                            width: "100%",
                            alignSelf: "flex-end",
                            border: "none",
                            paddingLeft: "1em",
                          }}
                        />
                      )}
                    </Field>
                    <img
                      alt=""
                      width={"1.5%"}
                      src={requiredstr}
                      style={{ position: "absolute", top: 0 }}
                    />
                  </Stack>
                  {touched.phoneNumber && errors.phoneNumber && (
                    <Typography color={"red"}>{errors.phoneNumber}</Typography>
                  )}
                  {/* <MuiTelInput /> */}

                  <Stack position={"relative"}>
                    <Field
                      as={TextField}
                      name={"password"}
                      placeholder="Password"
                      error={touched.password && errors.password}
                      type={showPassword ? "text" : "password"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                        "& fieldset": {
                          border: "1px solid #F5F5F5",
                          borderRadius: "30px",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <AiFillEye />
                              ) : (
                                <AiFillEyeInvisible />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {touched.password && errors.password && (
                      <Typography color={"red"}>{errors.password}</Typography>
                    )}
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>
                  <Stack position={"relative"}>
                    <Field
                      as={TextField}
                      name={"confirmPassword"}
                      error={touched.confirmPassword && errors.confirmPassword}
                      placeholder="Re-Enter Password"
                      type={showConfimPassword ? "text" : "password"}
                      sx={{
                        "& label": {
                          paddingLeft: (theme) => theme.spacing(2),
                        },
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                        "& fieldset": {
                          border: "1px solid #F5F5F5",
                          borderRadius: "30px",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowConfirmPassword(!showConfimPassword)
                              }
                            >
                              {showConfimPassword ? (
                                <AiFillEye />
                              ) : (
                                <AiFillEyeInvisible />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {touched.confirmPassword && errors.confirmPassword && (
                      <Typography color={"red"}>
                        {errors.confirmPassword}
                      </Typography>
                    )}
                    <img
                      src={requiredstr}
                      alt=""
                      width={"1.5%"}
                      style={{ position: "absolute" }}
                    />
                  </Stack>
                  <FormControlLabel
                    name={"terms"}
                    value={values.terms}
                    onChange={handleChange}
                    sx={{ mt: "-1px !important" }}
                    control={
                      <Checkbox
                        sx={{
                          borderRadius: "5px",
                          color: "black",
                          "&.Mui-checked": {
                            color: "black",
                          },
                        }}
                      />
                    }
                    label={`I accept the T&C and Privacy Policy`}
                  />
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                    size="large"
                    sx={{
                      borderRadius: "54.6591px",
                      bgcolor: "#F83D4B",
                      fontWeight: "700",
                      mt: "64px !Important",
                      p: 2,
                      width: "100%",
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
        <Typography display={"flex"} gap={"0.2em"} alignSelf={"center"} mt={5}>
          Already have an account?{" "}
          <Link
            component="button"
            onClick={() => nav("/login")}
            underline="none"
            fontWeight={"700"}
            color={"black"}
          >
            Log in
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SignupComponent1;
