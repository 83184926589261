import Select from "react-select";
import React, { useEffect, useState } from "react";
import "../../utils/css/styles.css";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export const MultiSelect = ({
  options,
  isMulti,
  placeholder,
  onChange,
  value,
}) => {
  const [optionsState, setOptionsState] = useState([]);

  useEffect(() => {
    if (options && options.length) {
      let data = options.map((item, i) => {
        return {
          ...item,
          label: item.name,
          value: item.id,
        };
      });
      setOptionsState([...data]);
    }
  }, [options]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      border: "1px solid #000000",
      borderRadius: 30,
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #000000",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "rgba(0, 0, 0, 0.54)",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 20,
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: 20,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      ":hover": {
        backgroundColor: "transparent",
        color: "red",
      },
    }),
  };

  return (
    <div>
      <Select
        name="colors"
        isMulti={isMulti}
        isClearable={false}
        styles={customStyles}
        value={value}
        onChange={onChange}
        options={optionsState}
        closeMenuOnSelect={isMulti ? false : true}
        menuPlacement={"auto"}
        placeholder={placeholder}
        classNamePrefix={"select"}
        components={animatedComponents}
        className={"basic-multi-select select__control css-13cymwt-control"}
      />
    </div>
  );
};
