import { combineReducers } from "redux";

// Authentication Module
import auth from "./auth/reducer";
import author from "./author/reducer";
import other from "./other/reducer";

const rootReducer = () =>
  combineReducers({
    auth,
    author,
    other,
  });

export default rootReducer;
