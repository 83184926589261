import React, { useState } from "react";
import {
  Box,
  Menu,
  Stack,
  Avatar,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../assets/images/close-icon.svg";
import { getNotifications } from "../store/author/actions";
import notificationsIcon from "../assets/images/notifications.svg";
import { TimeAgo } from "../utils/Helpers";
import likedIcon from "../assets/images/liked-icon.svg";
import removedSubmissionIcon from "../assets/images/removed-submission.svg";

export const Notifications = () => {
  const dispatch = useDispatch();

  const [notification, setNotification] = useState(null);
  const { notifications } = useSelector((state) => state.author);

  useQuery({
    queryKey: ["getNotifications"],
    queryFn: () => dispatch(getNotifications()),
  });

  const ITEM_HEIGHT = 48;

  const handleOpenNotification = (event) => {
    setNotification(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setNotification(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="notifications">
        <IconButton sx={{ p: 0 }} onClick={handleOpenNotification}>
          <img src={notificationsIcon} alt="" width={"70%"} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        sx={{ mt: "40px" }}
        anchorEl={notificationsIcon}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: 0,
            width: "40ch",
            overflow: "hidden",
            minHeight: ITEM_HEIGHT * 9.3,
            height: "90vh",
            borderRadius: "5px !important",
          },
        }}
        open={Boolean(notification)}
        onClose={handleCloseNotification}
      >
        <Stack
          paddingX={"1em"}
          direction={"row"}
          paddingY={"1em"}
          position={"relative"}
          justifyContent={"center"}
        >
          <Typography fontSize={"20px"} fontWeight={700}>
            Notification
          </Typography>
          <IconButton
            component="span"
            onClick={handleCloseNotification}
            sx={{ position: "absolute", right: "6px", top: "10px" }}
          >
            <img alt="" src={closeIcon} style={{ width: "15px" }} />
          </IconButton>
        </Stack>
        <Stack
          gap={"0.5em"}
          paddingY={"0.8em"}
          alignItems={"center"}
          height="73vh"
          sx={{ overflow: "auto" }}
        >
          {notifications?.length ? (
            notifications.map((item, i) => {
              return (
                <Stack
                  key={i}
                  mb={"2px"}
                  spacing={1}
                  width={"80%"}
                  paddingY={"1em"}
                  paddingX={"1em"}
                  direction={"row"}
                  sx={{
                    borderRadius: "21px",
                    boxShadow: "0px 3px 8px 0px #00000033",
                    backgroundColor: item.isRead ? "#F5F5F5" : "white",
                  }}
                  onClick={() => setNotification(null)}
                >
                  {item?.notification_type !== "request_menuscript" ? (
                    <img
                      alt={item?.user?.full_name}
                      src={
                        item?.notification_type === "submission_liked"
                          ? likedIcon
                          : removedSubmissionIcon
                      }
                      style={{
                        width: "30px",
                        height: "35px",
                        alignSelf: "center",
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{ alignSelf: "center" }}
                      alt={item?.agent?.full_name}
                      src={item?.agent?.user_profile?.profile_image}
                    />
                  )}
                  <Stack width={"100%"}>
                    <Stack>
                      <Typography
                        flexDirection={"row"}
                        color={item.isRead ? "#C0C0C0" : "black"}
                        fontSize={"14px"}
                        fontWeight={item.email && item.pitchId ? "500" : "700"}
                      >
                        {item.notification_type === "submission_liked"
                          ? item.body
                          : item.notification_type === "request_menuscript"
                          ? ""
                          : item.title}{" "}
                        {item.notification_type !== "submission_liked" &&
                          item.body && (
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: item.isRead ? "#C0C0C0" : "black",
                              }}
                            >
                              {item.body}
                            </span>
                          )}
                      </Typography>
                      {item?.notification_type === "request_menuscript" && (
                        <>
                          <Typography
                            display={"flex"}
                            fontSize={"12px"}
                            color={item.isRead ? "#C0C0C0" : "black"}
                          >
                            <b
                              style={{
                                width: "4em",
                                display: "flex",
                                color: item.isRead ? "#C0C0C0" : "black",
                              }}
                            >
                              Picth ID
                            </b>{" "}
                            PID#{item?.pitch_id}
                          </Typography>
                          <Typography
                            display={"flex"}
                            fontSize={"12px"}
                            color={item.isRead ? "#C0C0C0" : "black"}
                          >
                            <b
                              style={{
                                width: "4em",
                                display: "flex",
                                color: item.isRead ? "#C0C0C0" : "black",
                              }}
                            >
                              Email
                            </b>{" "}
                            {item?.agent?.email}
                          </Typography>
                        </>
                      )}
                    </Stack>
                    <Typography fontSize={"12px"} color={"#C0C0C0"}>
                      {TimeAgo(new Date(item?.created_at))}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })
          ) : (
            <Typography alignItems={"center"}>No Data Found</Typography>
          )}
        </Stack>
      </Menu>
    </Box>
  );
};
