export const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      style={{
        ...style,
        display: "block",
        left: "0%",
        top: "40%",
        zIndex: 1,
        background: "transparent !important",
      }}
      onClick={onClick}
    />
  );
};

export const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      style={{
        ...style,
        display: "block",
        right: "1%",
        top: "40%",
        zIndex: 1,
        background: "transparent !important",
      }}
      onClick={onClick}
    />
  );
};
