import React, { useState, useEffect, useRef } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../../utils/css/styles.css";
import AgencyCard from "../cards/AgencyCard";
import {
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import WishlistCard from "../cards/WishlistCard";
import { useQuery } from "@tanstack/react-query";
import PuffLoader from "react-spinners/PuffLoader";
import { useDispatch, useSelector } from "react-redux";
import { a11yProps, StyledTabs } from "../../utils/Helpers";
import whishlistbg from "../../assets/images/whishlistbg.svg";
import { getAgencies, getWishlist } from "../../store/author/actions";
import { toast } from "react-hot-toast";
import AgencyCarousal from "./AgencyCarousal";
import SearchInput from "./SearchInput";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "50vh", overflow: "auto", marginTop: "1em" }}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
export default function AgenciesTabs() {
  const agencyRef = useRef(null);
  const wishListRef = useRef(null);
  const dispatch = useDispatch();

  const getLastActiveTab = sessionStorage.getItem("agencyActiveTab");
  const lastActiveTab = parseInt(getLastActiveTab);

  const [value, setValue] = useState(0);
  const [visibleAgencyCount, setVisibleAgencyCount] = useState(10);
  const [visibleWishlistCount, setVisibleWishlistCount] = useState(10);

  const { agencies, wishlist, getWishlistAgencyLoading, getAgencyLoading } =
    useSelector((state) => state.author);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (getLastActiveTab !== null) {
      setValue(lastActiveTab);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    sessionStorage.setItem("agencyActiveTab", value);
  }, [value]);

  useQuery({
    queryKey: ["getAgencies"],
    queryFn: () => dispatch(getAgencies()),
  });
  useQuery({
    queryKey: ["getWishlist"],
    queryFn: () => dispatch(getWishlist()),
  });

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = agencyRef.current;

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        const toastId = toast.loading("Loading...");

        setTimeout(() => {
          setVisibleAgencyCount((prevCount) => prevCount + 5);
          toast.dismiss(toastId);
        }, 1000);
      }
    };
    const handleWishListScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = wishListRef.current;

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        const toastId = toast.loading("Loading...");
        setTimeout(() => {
          setVisibleWishlistCount((prevCount) => prevCount + 5);
          toast.dismiss(toastId);
        }, 100);
      }
    };
    if (agencyRef?.current) {
      agencyRef.current.addEventListener("scroll", handleScroll);
    }
    if (wishListRef?.current) {
      wishListRef.current.addEventListener("scroll", handleWishListScroll);
    }

    return () => {
      if (agencyRef?.current) {
        // eslint-disable-next-line
        agencyRef?.current.removeEventListener("scroll", handleScroll);
      }
      if (wishListRef?.current) {
        // eslint-disable-next-line
        wishListRef?.current.removeEventListener(
          "scroll",
          handleWishListScroll
        );
      }
    };
  }, []);
  const [agenciestdata, setAgenciestdata] = useState(agencies);
  const handleSearch = (query) => {
    const filtered = agencies?.filter((slide) =>
      slide?.first_name?.toLowerCase().includes(query?.toLowerCase())
    );
    setAgenciestdata(filtered);
  };
  useEffect(() => {
    if (agencies) {
      setAgenciestdata(agencies);
    }
  }, [agencies]);

  return (
    <Box sx={{ width: "100%", position: "relative", mt: "2em" }}>
      <Box className="testtesttes">
        <Grid container>
          <Grid xs={12} md={9}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{
                  color:
                    value === 0 ? "#FF414C !important" : "#000000 !important",
                  fontWeight: value === 0 ? 700 : 400,
                }}
                label="All Agencies"
                {...a11yProps(0)}
              />
              <Tab
                label="Wishlist"
                sx={{
                  color:
                    value === 1 ? "#FF414C !important" : "#000000 !important",
                  fontWeight: value === 1 ? 700 : 400,
                }}
                {...a11yProps(1)}
              />
            </StyledTabs>
          </Grid>
          <Grid sm={12} md={3}>
            <Box sx={{ marginLeft: { xs: "23px", md: "0px" } }}>
              <SearchInput onSearch={handleSearch} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <TabPanel value={value} index={0}>
        {!agencies?.length && getAgencyLoading ? (
          <PuffLoader
            color={"#FF414C"}
            loading={true}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <div style={{ margin: "0px 1em" }}>
            {agenciestdata?.length ? (
              <>
                <AgencyCarousal agencies={agenciestdata} />
              </>
            ) : (
              <Typography
                alignItems={"center"}
                display={!getAgencyLoading ? "inherit" : "none"}
              >
                No Data Found
              </Typography>
            )}
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {!wishlist?.length && getWishlistAgencyLoading ? (
          <PuffLoader
            color={"#FF414C"}
            loading={true}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <div
            ref={wishListRef}
            style={{ overflowY: "scroll", height: "400px" }}
          >
            {wishlist?.length ? (
              <Grid container xs={12} spacing={2}>
                {wishlist?.slice(0, visibleWishlistCount).map((item, i) => {
                  return (
                    <Grid key={i} item xs={12} sm={6} md={6} lg={4}>
                      <WishlistCard bg={whishlistbg} agency={item} />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography
                alignItems={"center"}
                display={!getWishlistAgencyLoading ? "inherit" : "none"}
              >
                No Data Found
              </Typography>
            )}{" "}
          </div>
        )}
      </TabPanel>
    </Box>
  );
}
