import React, { useState } from "react";
import { IconButton, MenuItem, Modal, Select } from "@mui/material";
import { Stack } from "@mui/system";
import { BiRightArrowAlt } from "react-icons/bi";
import { TextField, Typography } from "@mui/material";
import { defaultModalStyle } from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { updatePhoneNumber } from "../../store/auth/actions";
import { toast } from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import isMobilePhone from "validator/lib/isMobilePhone";
import closeIcon from "../../assets/images/close-icon.svg";

export const UpdatePhoneDlg = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [phoneValues, setPhoneValues] = useState({ code: "+92", number: "" });

  const handleSbmit = () => {
    if (
      phoneValues.code &&
      phoneValues.number &&
      isMobilePhone(phoneValues.code + phoneValues.number)
    ) {
      setIsLoading(true);
      let data = new FormData();
      data.append("phoneNo", phoneValues.code + phoneValues.number);
      dispatch(
        updatePhoneNumber({
          data,
          setIsLoading,
          handleClose,
          navigate,
          newPhone: phoneValues.code + phoneValues.number,
        })
      );
    } else {
      toast.error("Enter a valid phone number");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing="1em"
        alignItems={"center"}
        sx={defaultModalStyle}
        width={{ xs: "70%", sm: "50vw", md: "35vw" }}
      >
        <Stack width={"80%"} justifyContent={"center"} alignItems={"center"}>
          <IconButton
            component="span"
            onClick={handleClose}
            sx={{ position: "absolute", right: "6px", top: "10px" }}
          >
            <img alt="" src={closeIcon} style={{ width: "15px" }} />
          </IconButton>
          <Typography variant="h4" fontWeight={"700"} mt={"0.3em !important"}>
            Update Phone
          </Typography>
          <Typography textAlign={"center"}>
            Enter your phone and we’ll send you a code to update your phone
            number
          </Typography>
          <Stack
            sx={{
              gap: "0.5em",
              width: "100%",
              border: "none",
              marginTop: "1em",
              borderRadius: "30px",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
              justifyContent: "space-between",
            }}
          >
            <Select
              sx={{
                border: "none",
                borderRadius: "30px",
                "& fieldset": {
                  border: "none",
                },
              }}
              IconComponent={() => <span></span>}
              inputProps={{
                style: { padding: 0 },
              }}
              defaultValue={phoneValues.code}
              onChange={(e) =>
                setPhoneValues({ ...phoneValues, code: e.target.value })
              }
            >
              <MenuItem value={"+91"}>+91</MenuItem>
              <MenuItem value={"+92"}>+92</MenuItem>
              <MenuItem value={"+93"}>+93</MenuItem>
            </Select>
            <Stack sx={{ borderRight: "1px solid black", height: "2em" }} />
            <TextField
              type={"number"}
              placeholder="Phone"
              value={phoneValues.number}
              onChange={(e) =>
                setPhoneValues({ ...phoneValues, number: e.target.value })
              }
              sx={{
                "& label": { paddingLeft: (theme) => theme.spacing(2) },
                "& input": {
                  paddingLeft: (theme) => theme.spacing(1),
                },
                "& fieldset": {
                  border: "none",
                },
                width: "90%",
                alignSelf: "flex-end",
              }}
            />
          </Stack>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            size="large"
            sx={{
              background: "#FF414C",
              color: "white",
              fontSize: "0.8em",
              fontWeight: "700",
              borderRadius: "20px",
              paddingY: "0.7em",
              marginTop: "4em",
              boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
              ":hover": { background: "#FF414C" },
              width: "100%",
            }}
            onClick={handleSbmit}
            endIcon={<BiRightArrowAlt size={20} />}
          >
            Send{" "}
          </LoadingButton>
        </Stack>
      </Stack>
    </Modal>
  );
};
