import React, { useEffect } from "react";
import { useState } from "react";
import { Button, createTheme, ThemeProvider, Typography } from "@mui/material";

const TruncatedText = ({ text, limit, weight, fontSize }) => {
  const theme = createTheme({
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    },
  });
  const [showFullText, setShowFullText] = useState(false);

  const words = text ? text.split(" ") : "";

  const truncatedText = words ? words.slice(0, limit).join(" ") : "";
  const fullText = words ? words.join(" ") : "";

  const toggleShowText = (e) => {
    e.stopPropagation();
    setShowFullText(!showFullText);
  };

  useEffect(() => {
    if (words && words.length < limit) {
      setShowFullText(true);
    }
  }, [words, limit]);
  return (
    <ThemeProvider theme={theme}>
      <Typography variant={weight || "normal"}>
        {showFullText ? fullText : `${truncatedText}...`}
        {words.length > limit && (
          <span>
            <Button onClick={toggleShowText}>
              <Typography
                sx={{ fontSize: fontSize, textTransform: "lowercase" }}
              >
                {showFullText ? "see less" : "see More"}
              </Typography>
            </Button>
          </span>
        )}
      </Typography>
    </ThemeProvider>
  );
};

export default TruncatedText;
