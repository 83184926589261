import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import "../../utils/css/styles.css";
import FilterBar from "./FilterBar";
import AgenciesTabs from "./AgenciesTabs";
import divider from "../../assets/images/divider.svg";
import AgenciesAdvertisements from "./AgenciesAdvertisements";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../store/auth/actions";

const DashboardComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  return (
    <Stack height={"100%"} direction={"row"} paddingTop={"2vh"}>
      <Stack
        width={"25%"}
        height={"100%"}
        display={{ xs: "none", md: "inherit" }}
      >
        <FilterBar />
      </Stack>
      <Stack
        width={"2%"}
        height={"100%"}
        display={{ xs: "none", md: "inherit" }}
      >
        <img
          src={divider}
          alt=""
          height={"100%"}
          style={{ objectFit: "contain" }}
        />
      </Stack>
      <Stack width={{ xs: "100%", md: "73%" }}>
        <AgenciesAdvertisements />
        <AgenciesTabs />
      </Stack>
    </Stack>
  );
};

export default DashboardComponent;
