export const GET_COUNTRIES = "get_countries";
export const GET_COUNTRIES_SUCCESS = "get_countries_success";

export const GET_STATES = "get_states";
export const GET_STATES_SUCCESS = "get_states_success";

export const GET_CITIES = "get_cities";
export const GET_CITIES_SUCCESS = "get_cities_success";

export const GET_PLOTS = "get_plots";
export const GET_PLOTS_SUCCESS = "get_plots_success";

export const GET_CATEGORIES = "get_categories";
export const GET_CATEGORIES_SUCCESS = "get_categories_success";

export const GET_GENRES = "get_genres";
export const GET_GENRES_SUCCESS = "get_genres_success";

export const GET_LANGUAGES = "get_languages";
export const GET_LANGUAGES_SUCCESS = "get_languages_success";

export const GET_ADVERTISEMENTS = "get_advertisements";
export const GET_ADVERTISEMENTS_SUCCESS = "get_advertisements_success";

export const ERROR = "other_error";

export const STOP_LOADING = "stop_loading";
export const START_LOADING = "start_loading";
