import Select from "react-select";
import React, { useEffect, useState } from "react";
import "../../utils/css/styles.css";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export const MultiSelect = ({
  name,
  value,
  handleChange,
  options,
  placeholder,
  bg,
  border,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [optionsState, setOptionsState] = useState([]);

  useEffect(() => {
    if (options && options.length) {
      let data = options.map((item, i) => {
        return {
          ...item,
          label: item.name,
          value: item.id,
        };
      });
      setOptionsState([...data]);
    }
  }, [options]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: "100%",
      minHeight: "3.2em",
      background: bg,
      borderRadius: "35px",
      border: border,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "rgba(0, 0, 0, 0.54)",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 20,
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: 20,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      ":hover": {
        backgroundColor: "transparent",
        color: "red",
      },
    }),
  };

  const handleBlur = () => {
    setMenuIsOpen(false);
  };

  return (
    <div onBlur={handleBlur}>
      <Select
        name={name}
        value={value}
        isMulti={true}
        isClearable={false}
        styles={customStyles}
        options={optionsState}
        menuPlacement={"auto"}
        menuIsOpen={menuIsOpen}
        classNamePrefix={"select"}
        components={animatedComponents}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        onChange={(value) => handleChange(name, value)}
        placeholder={placeholder ? placeholder : "Subgenres"}
        className={"basic-multi-select select__control css-13cymwt-control"}
      />
    </div>
  );
};
