import React, { useState } from "react";
import { Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { BiRightArrowAlt } from "react-icons/bi";
import { IconButton, TextField, Typography } from "@mui/material";
import { forgetPassword } from "../../store/auth/actions";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    let data = { email: value };
    sessionStorage.setItem("forget-password-email", value);
    dispatch(forgetPassword({ data, setIsLoading, navigate }));
  };

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      position={"relative"}
      className={"testtestts"}
    >
      <Stack width={{ xs: "70%", sm: "45%", md: "40%", lg: "25%" }}>
        <Typography variant="h4" fontWeight={"700"} textAlign={"center"}>
          Verification Code
        </Typography>
        <Typography textAlign={"center"}>
          Enter your email, and we'll send you a code to reset your password
        </Typography>
        <TextField
          value={value}
          placeholder="Email"
          onChange={(e) => setValue(e.target.value)}
          sx={{
            marginTop: "5em",
            "& label": { paddingLeft: (theme) => theme.spacing(2) },
            "& input": {
              paddingLeft: (theme) => theme.spacing(3.5),
              backgroundColor: "#F5F5F5",
              borderRadius: "30px",
            },
            "& fieldset": {
              border: "none",
              borderRadius: "30px",
            },
          }}
        />
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="submit"
          size="large"
          sx={{
            color: "white",
            paddingY: "1em",
            marginTop: "2em",
            fontSize: "0.8em",
            fontWeight: "700",
            borderRadius: "20px",
            background: "#FF414C",
            ":hover": { background: "#FF414C" },
            boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
          }}
          endIcon={<BiRightArrowAlt size={20} />}
          onClick={handleSubmit}
        >
          Send
        </LoadingButton>

        <Typography marginTop={"4em"} textAlign={"center"}>
          Don’t have an account?{" "}
          <b
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/sign-up-1")}
          >
            SIGN UP
          </b>
        </Typography>
        <IconButton
          sx={{
            color: "white",
            marginTop: "2em",
            borderRadius: "10px",
            background: "#FF414C",
            width: "fit-content",
            ":hover": { background: "#FF414C" },
            boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
            alignSelf: "center",
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
