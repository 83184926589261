import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import App from "./App.jsx";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

(async () => {
  const publishKey =
    "pk_test_51LNFXoGG9FijXOUgOaX2C7WvsZ4wteVzdVVOsYnU31JeCLEnv0kTuoy2a6ll1qQVpnoeJpwfrRWl0b8UHJpfsivz00qg2Sebq9";
  const stripePromise = loadStripe(publishKey);
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
        <Toaster />
      </PersistGate>
    </Provider>
    // </React.StrictMode>
  );
})();
