import React, { useState } from "react";
import { Stack } from "@mui/system";
import { OtpInput } from "./OtpInput";
import { Typography } from "@mui/material";
import { BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { verifySignUpStepOne } from "../../store/auth/actions";
import { PhoneNumberFormatter } from "../../utils/Helpers";
import { LoadingButton } from "@mui/lab";

export const VerificationCode = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [pinCode, setPinCode] = useState({ mobile: "", email: "" });

  const { signUp1Data, isLoading } = useSelector((state) => state.auth);

  const handleSubmit = () => {
    if (pinCode.email.length === 4 && pinCode.mobile.length === 4) {
      let data = new FormData();
      data.append("email", signUp1Data.email);
      data.append("phone_number", signUp1Data.phone);
      data.append("otpCode", pinCode.mobile);
      data.append("email_code", pinCode.email);

      dispatch(verifySignUpStepOne({ data, nav }));
    } else {
      toast.error("Enter both codes");
    }
  };
  return (
    <Stack justifyContent={"center"} alignItems={"center"} height={"100vh"}>
      <Stack width={{ xs: "60%", sm: "45%", md: "40%", lg: "25%" }}>
        <Typography variant="h4" fontWeight={"700"} textAlign={"center"}>
          Verification Code
        </Typography>
        <Typography textAlign={"center"}>
          Please type the verification code sent on your phone
        </Typography>
        <Typography color={"#FF414C"} fontWeight={700} textAlign={"center"}>
          {/* (123) 345 56789 */}
          {signUp1Data &&
            signUp1Data.phone &&
            PhoneNumberFormatter(signUp1Data.phone)}
        </Typography>
        <Stack paddingTop={"2em"}>
          <OtpInput
            handlePin={(pin) => setPinCode({ ...pinCode, mobile: pin })}
          />
          <Typography
            variant="subtitle2"
            textAlign={"center"}
            paddingLeft={{ md: "0", lg: "2em" }}
          >
            Didn’t receive the code? <b style={{ cursor: "pointer" }}>RESEND</b>
          </Typography>
        </Stack>
        <Stack
          paddingTop={"2em"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="body2" textAlign={"center"}>
            Please type the verification code sent on your email
          </Typography>
          <Typography color={"#FF414C"} variant="body2" fontWeight={"700"}>
            {signUp1Data && signUp1Data.email}
          </Typography>
        </Stack>
        <Stack paddingTop={"2em"}>
          <OtpInput
            handlePin={(pin) => setPinCode({ ...pinCode, email: pin })}
          />
          <Typography
            variant="subtitle2"
            textAlign={"center"}
            paddingLeft={{ md: "0", lg: "2em" }}
          >
            Didn’t receive the code? <b style={{ cursor: "pointer" }}>RESEND</b>
          </Typography>
        </Stack>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="submit"
          size="large"
          sx={{
            background: "#FF414C",
            color: "white",
            fontSize: "0.8em",
            fontWeight: "700",
            borderRadius: "20px",
            paddingY: "1em",
            marginTop: "2em",
            boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
            ":hover": { background: "#FF414C" },
            width: "90%",
            alignSelf: "center",
          }}
          endIcon={<BiRightArrowAlt size={20} />}
          onClick={handleSubmit}
        >
          Verify
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
