import {
  STOP_LOADING,
  START_LOADING,
  ERROR,
  GET_AGENCIES,
  GET_AGENCIES_SUCCESS,
  ADD_REMOVE_AGENCY,
  ADD_REMOVE_AGENCY_SUCCESS,
  GET_WISHLIST,
  GET_WISHLIST_SUCCESS,
  GET_FREE_AGENT,
  GET_FREE_AGENT_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_PAYMENT_HISTORY,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_MY_AGENTS,
  GET_MY_AGENTS_SUCCESS,
  GET_WISHLIST_AGENCIES,
  GET_WISHLIST_AGENCIES_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  GET_ALL_PITCHES,
  GET_ALL_PITCHES_SUCCESS,
  GET_MY_SUBMISSIONS,
  GET_MY_SUBMISSIONS_SUCCESS,
  UNPUBLISHED_SUBMISSION,
  UNPUBLISHED_SUBMISSION_SUCCESS,
  BOOK_DEAL,
  BOOK_DEAL_SUCCESS,
  FILTER_AGENCIES,
  FILTER_AGENCIES_SUCCESS,
  FILTER_PITCHES,
  FILTER_PITCHES_SUCCESS,
  CONTACT_US,
  CONTACT_US_SUCCESS,
  GET_SINGLE_PITCH,
  POST_SUBMISSION,
  GET_SINGLE_SUBMISSION,
  GET_SINGLE_AGENT,
  GET_SINGLE_AGENCY,
  GET_AGENCY_START_LOADING,
  GET_AGENCY_STOP_LOADING,
  GET_WISHLIST_AGENCY_START_LOADING,
  GET_WISHLIST_AGENCY_STOP_LOADING,
  GET_SUBMISSION_START_LOADING,
  GET_SUBMISSION_STOP_LOADING,
  GET_PITCHES_START_LOADING,
  GET_PITCHES_STOP_LOADING,
  RESET_STATE,
  FIND_AGENT_BY_GENRE,
  FIND_AGENT_BY_GENRE_SUCCESS,
  FIND_AGENT_BY_GENRE_START_LOADING,
  FIND_AGENT_BY_GENRE_STOP_LOADING,
  AGENT_PAID,
  AGENT_PAID_SUCCESS,
  CLEAR_AGENT,
  POST_SUBMISSION_SUCCESS,
  BOOK_SUBMISSION_PURCHASE,
  BOOK_SUBMISSION_PURCHASE_SUCCESS,
} from "./actionTypes";

export const getAgencies = (data) => {
  return {
    type: GET_AGENCIES,
    payload: data,
  };
};

export const getAgenciesSuccess = (data) => {
  return {
    type: GET_AGENCIES_SUCCESS,
    payload: data,
  };
};

export const addRemoveAgency = (data) => {
  return {
    type: ADD_REMOVE_AGENCY,
    payload: data,
  };
};

export const addRemoveAgencySuccess = (data) => {
  return {
    type: ADD_REMOVE_AGENCY_SUCCESS,
    payload: data,
  };
};

export const getWishlist = (data) => {
  return {
    type: GET_WISHLIST,
    payload: data,
  };
};

export const getWishlistSuccess = (data) => {
  return {
    type: GET_WISHLIST_SUCCESS,
    payload: data,
  };
};

export const getFreeAgent = (data) => {
  return {
    type: GET_FREE_AGENT,
    payload: data,
  };
};

export const getFreeAgentSuccess = (data) => {
  return {
    type: GET_FREE_AGENT_SUCCESS,
    payload: data,
  };
};

export const getNotifications = (data) => {
  return {
    type: GET_NOTIFICATIONS,
    payload: data,
  };
};

export const getNotificationsSuccess = (data) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const getPaymentHistory = (data) => {
  return {
    type: GET_PAYMENT_HISTORY,
    payload: data,
  };
};

export const getPaymentHistorySuccess = (data) => {
  return {
    type: GET_PAYMENT_HISTORY_SUCCESS,
    payload: data,
  };
};

export const getMyPaidAgents = (data) => {
  return {
    type: GET_MY_AGENTS,
    payload: data,
  };
};

export const getMyPaidAgentsSuccess = (data) => {
  return {
    type: GET_MY_AGENTS_SUCCESS,
    payload: data,
  };
};

export const getWishlistAgencies = (data) => {
  return {
    type: GET_WISHLIST_AGENCIES,
    payload: data,
  };
};

export const getWishlistAgenciesSuccess = (data) => {
  return {
    type: GET_WISHLIST_AGENCIES_SUCCESS,
    payload: data,
  };
};

export const getAllPitches = (data) => {
  return {
    type: GET_ALL_PITCHES,
    payload: data,
  };
};

export const getAllPitchesSuccess = (data) => {
  return {
    type: GET_ALL_PITCHES_SUCCESS,
    payload: data,
  };
};

export const getSinglePitch = (data) => {
  return {
    type: GET_SINGLE_PITCH,
    payload: data,
  };
};

export const getSingleSubmission = (data) => {
  return {
    type: GET_SINGLE_SUBMISSION,
    payload: data,
  };
};

export const getSingleAgent = (data) => {
  return {
    type: GET_SINGLE_AGENT,
    payload: data,
  };
};

export const getSingleAgency = (data) => {
  return {
    type: GET_SINGLE_AGENCY,
    payload: data,
  };
};

export const getMySubmissions = (data) => {
  return {
    type: GET_MY_SUBMISSIONS,
    payload: data,
  };
};

export const getMySubmissionsSuccess = (data) => {
  return {
    type: GET_MY_SUBMISSIONS_SUCCESS,
    payload: data,
  };
};

export const unpublishedSubmissions = (data) => {
  return {
    type: UNPUBLISHED_SUBMISSION,
    payload: data,
  };
};

export const unpublishedSubmissionsSuccess = (data) => {
  return {
    type: UNPUBLISHED_SUBMISSION_SUCCESS,
    payload: data,
  };
};

export const bookDeal = (data) => {
  return {
    type: BOOK_DEAL,
    payload: data,
  };
};

export const bookDealSuccess = (data) => {
  return {
    type: BOOK_DEAL_SUCCESS,
    payload: data,
  };
};

export const filterAgencies = (data) => {
  return {
    type: FILTER_AGENCIES,
    payload: data,
  };
};

export const filterAgenciesSuccess = (data) => {
  return {
    type: FILTER_AGENCIES_SUCCESS,
    payload: data,
  };
};

export const filterPitches = (data) => {
  return {
    type: FILTER_PITCHES,
    payload: data,
  };
};

export const filterPitchesSuccess = (data) => {
  return {
    type: FILTER_PITCHES_SUCCESS,
    payload: data,
  };
};

export const contactUs = (data) => {
  return {
    type: CONTACT_US,
    payload: data,
  };
};

export const contactUsSuccess = (data) => {
  return {
    type: CONTACT_US_SUCCESS,
    payload: data,
  };
};

export const updateProfile = (data) => {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
};

export const updateProfileSuccess = (data) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  };
};

export const postSubmission = (data) => {
  return {
    type: POST_SUBMISSION,
    payload: data,
  };
};

export const postSubmissionSuccess = (data) => {
  return {
    type: POST_SUBMISSION_SUCCESS,
    payload: data,
  };
};

export const findAgentByGenre = (data) => {
  return {
    type: FIND_AGENT_BY_GENRE,
    payload: data,
  };
};

export const findAgentByGenreSuccess = (data) => {
  return {
    type: FIND_AGENT_BY_GENRE_SUCCESS,
    payload: data,
  };
};

export const bookSubmissionBuy = (data) => {
  return {
    type: BOOK_SUBMISSION_PURCHASE,
    payload: data,
  };
};

export const bookSubmissionBuySuccess = (data) => {
  return {
    type: BOOK_SUBMISSION_PURCHASE_SUCCESS,
    payload: data,
  };
};

export const agentPaid = (data) => {
  return {
    type: AGENT_PAID,
    payload: data,
  };
};

export const agentPaidSuccess = (data) => {
  return {
    type: AGENT_PAID_SUCCESS,
    payload: data,
  };
};

export const deleteAccount = (data) => {
  return {
    type: DELETE_ACCOUNT,
    payload: data,
  };
};

export const deleteAccountSuccess = (data) => {
  return {
    type: DELETE_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const errorHandler = (data) => ({
  type: ERROR,
  payload: data,
});

export const startLoading = (data) => ({
  type: START_LOADING,
  payload: data,
});

export const stopLoading = (data) => ({
  type: STOP_LOADING,
  payload: {
    action: data,
  },
});

export const findAgentByGenreStartLoading = (data) => ({
  type: FIND_AGENT_BY_GENRE_START_LOADING,
  payload: data,
});

export const findAgentByGenreStopLoading = (data) => ({
  type: FIND_AGENT_BY_GENRE_STOP_LOADING,
  payload: {
    action: data,
  },
});

export const getAgencyStartLoading = (data) => ({
  type: GET_AGENCY_START_LOADING,
  payload: data,
});

export const getAgencyStopLoading = (data) => ({
  type: GET_AGENCY_STOP_LOADING,
  payload: {
    action: data,
  },
});

export const getWishlistAgencyStartLoading = (data) => ({
  type: GET_WISHLIST_AGENCY_START_LOADING,
  payload: data,
});

export const getWishlistAgencyStopLoading = (data) => ({
  type: GET_WISHLIST_AGENCY_STOP_LOADING,
  payload: {
    action: data,
  },
});

export const getSubmissionStartLoading = (data) => ({
  type: GET_SUBMISSION_START_LOADING,
  payload: data,
});

export const getSubmissionStopLoading = (data) => ({
  type: GET_SUBMISSION_STOP_LOADING,
  payload: {
    action: data,
  },
});

export const getPicthesStartLoading = (data) => ({
  type: GET_PITCHES_START_LOADING,
  payload: data,
});

export const getPitchesStopLoading = (data) => ({
  type: GET_PITCHES_STOP_LOADING,
  payload: {
    action: data,
  },
});

export const resetAuthorState = (data) => ({
  type: RESET_STATE,
  payload: data,
});

export const resetAgentState = () => ({
  type: CLEAR_AGENT,
});
