import produce from "immer";
import { toast } from "react-hot-toast";
import {
  STOP_LOADING,
  LOGIN_SUCCESS,
  START_LOADING,
  ERROR,
  SIGN_UP_STEP_ONE_SUCCESS,
  VERIFY_STEP_ONE_SUCCESS,
  SIGN_UP_STEP_TWO_SUCCESS,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_PHONE_NUMBER_SUCCESS,
  UPDATE_PHONE_VERIFICATION_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  LOGOUT,
  GET_UPDATED_AUTHOR_PROFILE_SUCCESS,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_VERIFY_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  FETCH_USER_SUCCESS,
} from "./actionTypes";

const initialState = {
  user: null,
  token: null,
  rememberMe: false,
  signUp1Data: null,
  newEmail: null,
  newPhone: null,
  resetPasswordEmialVerfiied: null,
  free_agents: null,
  user_genres: [],
  draft_submissions: null,
  isLoading: false,
};

const Auth = produce((state, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case LOGIN_SUCCESS:
      if (action.payload.rememberMe) {
        localStorage.setItem("bw-token", action.payload.access_token);
      } else {
        sessionStorage.setItem("bw-token", action.payload.access_token);
      }
      console.log("action?.payload", action?.payload);
      state.free_agents = action?.payload?.data?.total_free_agent_matches_left;
      const genresArray = action.payload?.data?.user_genres?.map(
        (item) => item.genre.name
      );
      state.user_genres = genresArray;
      state.user = action.payload.data;
      state.draft_submissions = action?.payload?.draft_count_submission;
      state.token = action.payload.access_token;
      state.rememberMe = action.payload.rememberMe;
      break;
    case FETCH_USER_SUCCESS:
      state.free_agents = action?.payload?.data?.total_free_agent_matches_left;
      const genresArrayUser = action.payload?.data?.user_genres?.map(
        (item) => item.genre.name
      );
      state.user_genres = genresArrayUser;
      state.user = action.payload.data;
      state.draft_submissions = action?.payload?.draft_count_submission;
      state.token = action.payload.access_token;
      state.rememberMe = action.payload.rememberMe;
      break;
    case LOGOUT:
      state.user = null;
      state.token = null;
      localStorage.removeItem("bw-token");
      sessionStorage.removeItem("bw-token");
      break;
    case SIGN_UP_STEP_ONE_SUCCESS:
      state.signUp1Data = action.payload.data.user;
      toast.success("Step One Success");
      break;
    case VERIFY_STEP_ONE_SUCCESS:
      toast.success("Email and Phone Verified");
      break;
    case SIGN_UP_STEP_TWO_SUCCESS:
      sessionStorage.removeItem("signup-step-2-values");
      state.signUp1Data = null;
      toast.success("Registration Complete");
      break;
    case UPDATE_EMAIL_SUCCESS:
      toast.success(action.payload.message);
      state.newEmail = action.payload.email;
      break;
    case UPDATE_EMAIL_VERIFICATION_SUCCESS:
      toast.success(action.payload.message);
      state.newEmail = null;
      break;
    case UPDATE_PHONE_NUMBER_SUCCESS:
      toast.success(action.payload.message);
      state.newPhone = action.payload.newPhone;
      break;
    case UPDATE_PHONE_VERIFICATION_SUCCESS:
      toast.success(action.payload.message);
      break;
    case UPDATE_PASSWORD_SUCCESS:
      toast.success(action.payload.message);
      break;
    case GET_UPDATED_AUTHOR_PROFILE_SUCCESS:
      state.user = action.payload.data.author;
      break;
    case FORGET_PASSWORD_SUCCESS:
      toast.success(action.payload.message);
      break;
    case FORGET_PASSWORD_VERIFY_SUCCESS:
      state.resetPasswordEmialVerfiied = true;
      toast.success(action.payload.message);
      break;
    case RESET_PASSWORD_SUCCESS:
      state.resetPasswordEmialVerfiied = false;
      toast.success(action.payload.message);
      break;
    case ERROR:
      if (action?.payload?.data?.message) {
        toast.error(action.payload.data.message);
      } else {
        toast.error("Error");
      }
      break;
    default:
      break;
  }
}, initialState);

export default Auth;
