import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Stack } from "@mui/system";
import {
  Radio,
  Modal,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  Typography,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import { Formik, Form, Field } from "formik";
import { defaultModalStyle, fictionalValues } from "../../utils/Constants";
import { postSubmission } from "../../store/author/actions";
import closeIcon from "../../assets/images/close-icon.svg";
import { AlertDialog } from "./AlertDialog";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  genreType: Yup.string(),
  subgenres: Yup.array().min(1, "Select at least one option").required(),
  pitch: Yup.string().min(2, "Too Short!").max(280, "Too Long!").required(),
  targetAudience: Yup.string()
    .min(2, "Too Short!")
    .max(280, "Too Long!")
    .required(),
  plot: Yup.string().min(2, "Too Short!").max(280, "Too Long!").required(),
  theme: Yup.string().min(2, "Too Short!").max(280, "Too Long!").required(),
  characters: Yup.string()
    .min(2, "Too Short!")
    .max(280, "Too Long!")
    .required(),
  country: Yup.array().min(1, "Select at least one option").required(),
  language: Yup.array().min(1, "Select at least one option").required(),
  category: Yup.string().required(),
  wordCount: Yup.number().required(),
  singleSeries: Yup.number().required(),
  scriptPublished: Yup.number().required(),
  queryLetters: Yup.string().required(),
  synopsis: Yup.string().required(),
  fiftyPages: Yup.string().required(),
});

export const FictionalSubmissionDlg = ({
  open,
  edit,
  submission,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const [saveAction, setSaveAction] = useState("post");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [confirmationDlg, setConfirmationDlg] = useState(false);
  const [pictures, setPictures] = useState({
    query: "",
    synopsis: "",
    fiftyPages: "",
  });

  const { genres, languages, countries, categories, plots } = useSelector(
    (state) => state.other
  );

  useEffect(() => {
    setPictures({
      query: "",
      synopsis: "",
      fiftyPages: "",
    });
  }, [dispatch]);

  const handlePicture = (e, name, fn) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setPictures({ ...pictures, [name]: img });
      fn();
    }
  };

  useEffect(() => {
    let query = "";
    let fiftyPages = "";
    let synopsis = "";
    if (submission && submission?.query_letter) {
      let name = submission?.query_letter?.split("/");
      query = name[name.length - 1];
    }
    if (submission && submission?.first_fifty_pages) {
      let name = submission?.first_fifty_pages?.split("/");
      fiftyPages = name[name.length - 1];
    }
    if (submission && submission?.synopsis) {
      let name = submission?.synopsis?.split("/");
      synopsis = name[name.length - 1];
    }

    setPictures({
      query: { name: query },
      synopsis: { name: synopsis },
      fiftyPages: { name: fiftyPages },
    });

    // eslint-disable-next-line
  }, [submission]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => setConfirmationDlg(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          spacing="1em"
          height={"80vh"}
          overflow={"auto"}
          alignItems={"center"}
          sx={defaultModalStyle}
          width={{ xs: "80%", sm: "40vw" }}
        
        >
          <IconButton
            component="span"
            onClick={() => setConfirmationDlg(true)}
            sx={{ position: "absolute", right: "6px", top: "10px" }}
          >
            <img alt="" src={closeIcon} style={{ width: "15px" }} />
          </IconButton>
          <Typography
            variant="h4"
            fontWeight={700}
            textAlign={"center"}
            paddingBottom={"0.5em"}
          >
            New Submission
          </Typography>
          <Formik
            initialValues={{
              ...fictionalValues,
              title: submission?.title ? submission?.title : "",
              genreType: "fictional",
              subgenres: submission?.genres?.length
                ? submission?.genres?.map((item) => {
                    return { label: item.name, value: item.id };
                  })
                : [],
              pitch: submission?.pitch ? submission?.pitch : "",
              targetAudience: submission?.target_audience
                ? submission?.target_audience
                : "",
              plot: submission?.plot ? submission?.plot?.id : "",
              theme: submission?.theme ? submission?.theme : "",
              characters: submission?.characters ? submission?.characters : "",
              country: submission?.countries?.length
                ? submission?.countries?.map((item) => {
                    return { label: item.name, value: item.id };
                  })
                : [],
              language: submission?.languages?.length
                ? submission?.languages?.map((item) => {
                    return { label: item.name, value: item.id };
                  })
                : [],
              category: submission?.category ? submission?.category?.id : "",
              wordCount: submission?.words_count ? submission?.words_count : "",
              singleSeries: submission?.submission_types
                ? submission?.submission_types?.value
                : "",
              scriptPublished: submission?.publication_types
                ? submission?.publication_types?.value
                : "",
              queryLetters: submission?.query_letter
                ? submission?.query_letter
                : "",
              synopsis: submission?.synopsis ? submission?.synopsis : "",
              fiftyPages: submission?.first_fifty_pages
                ? submission?.first_fifty_pages
                : "",
            }}
            // initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (saveAction === "post") {
                setIsLoading(true);
              } else {
                setIsSaveLoading(true);
              }

              let data = new FormData();
              data.append("title", values.title);
              data.append("is_fictional", 1);
              data.append("pitch", values.pitch);
              data.append("target_audience", values.targetAudience);
              data.append("plot_id", values.plot);
              data.append("theme", values.theme);
              data.append("characters", values.characters);
              data.append("words_count", values.wordCount);
              data.append("category_id", values.category);
              data.append("single_series", values.singleSeries);
              data.append("manuscript_published", values.scriptPublished);
              // data.append("submission_id", values.);
              if (saveAction === "save") {
                console.log(
                  "🚀 ~ file: FictionalSubmissionDlg.jsx:214 ~ saveAction:",
                  saveAction
                );
                data.append("posted", false);
              } else if (saveAction === "post") {
                console.log(
                  "🚀 ~ file: FictionalSubmissionDlg.jsx:217 ~ saveAction:",
                  saveAction
                );
                data.append("posted", true);
              }
              data.append("settings", "asdasd");

              if (!submission?.query_letter) {
                data.append("query_letter", pictures.query);
              }
              if (!submission?.synopsis) {
                data.append("synopsis", pictures.synopsis);
              }
              if (!submission?.first_fifty_pages) {
                data.append("first_fifty_pages", pictures.fiftyPages);
              }

              values.subgenres.forEach((item, index) => {
                data.append(`genres[${index}]`, item.id);
              });
              values.country.forEach((item, index) => {
                data.append(`countries[${index}]`, item.id);
              });
              values.language.forEach((item, index) => {
                data.append(`languages[${index}]`, item.id);
              });
              dispatch(
                postSubmission({
                  data,
                  setIsLoading,
                  setIsSaveLoading,
                  handleClose,
                })
              );
            }}
          >
            {({
              values,
              handleChange,
              field,
              setFieldValue,
              errors,
              touched,
              handleSubmit,
            }) => (
              <Form style={{ width: "100%" }}>
                <Stack width="100%" spacing={2}>
                  <Field
                    type={"text"}
                    as={TextField}
                    name="title"
                    placeholder={"Title"}
                    sx={{
                      "& label": {
                        paddingLeft: (theme) => theme.spacing(2),
                      },
                      "& input": {
                        paddingLeft: (theme) => theme.spacing(3.5),
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                      },
                      "& fieldset": {
                        border:
                          touched.title && errors.title
                            ? "1px solid red"
                            : "none",
                        borderRadius: "30px",
                      },
                    }}
                  />
                  <RadioGroup
                    {...field}
                    name={"genreType"}
                    value={values.genreType}
                    onChange={handleChange}
                    sx={{
                      gap: "1em",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <FormControlLabel
                      disabled
                      value={"fictional"}
                      control={<Radio />}
                      label={"Fiction"}
                      sx={{
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                        paddingY: "1em",
                        paddingLeft: "1em",
                        width: { sm: "100%", md: "45%" },
                        margin: 0,
                        color: "#C0C0C0",
                        fontSize: "0.7vw",
                        outline:
                          values.genreType === "fiction"
                            ? "2px solid #FF414C"
                            : "none",
                      }}
                    />
                    <FormControlLabel
                      disabled
                      value={"nonFictional"}
                      control={<Radio />}
                      label={"Nonfiction"}
                      sx={{
                        backgroundColor: "#F5F5F5",
                        borderRadius: "35px",
                        paddingY: "1em",
                        paddingLeft: "1em",
                        width: { sm: "100%", md: "45%" },
                        margin: 0,
                        color: "#C0C0C0",
                        fontSize: "0.7vw",
                        outline:
                          values.genreType === "nonFiction"
                            ? "2px solid #FF414C"
                            : "none",
                      }}
                    />
                  </RadioGroup>
                  <Field
                    as={MultiSelect}
                    bg={"#F5F5F5"}
                    border={
                      touched.subgenres && errors.subgenres
                        ? "1px solid red"
                        : "none"
                    }
                    name={"subgenres"}
                    value={values.subgenres}
                    handleChange={setFieldValue}
                    options={genres?.fiction?.length ? genres.fiction : []}
                  />
                  <Stack position={"relative"}>
                    <Field
                      name="pitch"
                      type={"text"}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline={true}
                          rows={6}
                          placeholder={"Pitch"}
                          sx={{
                            "& label": {
                              paddingLeft: (theme) => theme.spacing(2),
                            },
                            "& fieldset": {
                              border:
                                touched.pitch && errors.pitch
                                  ? "1px solid red"
                                  : "none",
                              borderRadius: "17.246px",
                              marginBottom: "-25px",
                            },
                            backgroundColor: "#F5F5F5",
                            borderRadius: "17.246px",
                            paddingBottom: "1.5em",
                          }}
                        />
                      )}
                    />
                    <Typography
                      color={"#C0C0C0"}
                      position={"absolute"}
                      sx={{ bottom: "7px", left: "15px" }}
                    >
                      {values?.pitch?.length}/280 Chars Max
                    </Typography>
                  </Stack>
                  <Stack position={"relative"}>
                    <Field
                      name="targetAudience"
                      type={"text"}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline={true}
                          rows={4}
                          placeholder={"Potential Target Audience"}
                          sx={{
                            "& label": {
                              paddingLeft: (theme) => theme.spacing(2),
                            },
                            "& fieldset": {
                              border:
                                touched.targetAudience && errors.targetAudience
                                  ? "1px solid red"
                                  : "none",
                              borderRadius: "17.246px",
                              marginBottom: "-25px",
                            },
                            backgroundColor: "#F5F5F5",
                            borderRadius: "17.246px",
                            paddingBottom: "1.5em",
                          }}
                        />
                      )}
                    />
                    <Typography
                      color={"#C0C0C0"}
                      position={"absolute"}
                      sx={{ bottom: "7px", left: "15px" }}
                    >
                      {values?.targetAudience?.length}/280 Chars Max
                    </Typography>
                  </Stack>
                  <Select
                    value={values.plot}
                    onChange={handleChange}
                    name="plot"
                    placeholder="Plot"
                    sx={{
                      "& .MuiSelect-select .notranslate::after": "Plot"
                        ? {
                            content: `"Plot"`,
                            opacity: 0.42,
                          }
                        : {},
                      "& fieldset": {
                        border: "none",
                        borderRadius: "30px",
                      },
                      border:
                        touched.plot && errors.plot ? "1px solid red" : "none",
                      backgroundColor: "#F5F5F5",
                      borderRadius: "35px",
                    }}
                  >
                    {plots && plots.length
                      ? plots.map((item, i) => {
                          return (
                            <MenuItem value={item.id} key={i}>
                              {item.name}
                            </MenuItem>
                          );
                        })
                      : []}
                  </Select>
                  <Stack position={"relative"}>
                    <Field
                      name="theme"
                      type={"text"}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline={true}
                          rows={3}
                          placeholder={"Theme"}
                          sx={{
                            "& label": {
                              paddingLeft: (theme) => theme.spacing(2),
                            },
                            "& fieldset": {
                              border:
                                touched.theme && errors.theme
                                  ? "1px solid red"
                                  : "none",
                              borderRadius: "17.246px",
                            },
                            backgroundColor: "#F5F5F5",
                            borderRadius: "17.246px",
                          }}
                        />
                      )}
                    />
                    <Typography
                      color={"#C0C0C0"}
                      position={"absolute"}
                      sx={{ bottom: "7px", left: "15px" }}
                    >
                      {values?.theme?.length}/280 Chars Max
                    </Typography>
                  </Stack>
                  <Stack position={"relative"}>
                    <Field
                      name="characters"
                      type={"text"}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline={true}
                          rows={3}
                          placeholder={"Characters"}
                          sx={{
                            "& label": {
                              paddingLeft: (theme) => theme.spacing(2),
                            },
                            "& fieldset": {
                              border:
                                touched.characters && errors.characters
                                  ? "1px solid red"
                                  : "none",
                              borderRadius: "17.246px",
                              marginBottom: "-25px",
                            },
                            backgroundColor: "#F5F5F5",
                            borderRadius: "17.246px",
                            paddingBottom: "1.5em",
                          }}
                        />
                      )}
                    />
                    <Typography
                      color={"#C0C0C0"}
                      position={"absolute"}
                      sx={{ bottom: "7px", left: "15px" }}
                    >
                      {values?.characters?.length}/280 Chars Max
                    </Typography>
                  </Stack>
                  <Stack sx={{ flexDirection: "row", gap: "1em" }}>
                    <Stack width={"50%"}>
                      <Field
                        as={MultiSelect}
                        bg={"#F5F5F5"}
                        border={
                          touched.country && errors.country
                            ? "1px solid red"
                            : "none"
                        }
                        name={"country"}
                        placeholder={"Country"}
                        value={values.country}
                        handleChange={setFieldValue}
                        options={countries?.length ? countries : []}
                      />
                    </Stack>
                    <Stack width={"50%"}>
                      <Field
                        as={MultiSelect}
                        bg={"#F5F5F5"}
                        border={
                          touched.language && errors.language
                            ? "1px solid red"
                            : "none"
                        }
                        name={"language"}
                        placeholder={"Language"}
                        value={values.language}
                        handleChange={setFieldValue}
                        options={languages?.length ? languages : []}
                      />
                    </Stack>
                  </Stack>
                  <Stack sx={{ flexDirection: "row", gap: "1em" }}>
                    <Stack width={"50%"}>
                      <Select
                        name={"category"}
                        value={values.category}
                        onChange={handleChange}
                        sx={{
                          "& .MuiSelect-select .notranslate::after": "Category"
                            ? {
                                content: `"Category"`,
                                opacity: 0.42,
                              }
                            : {},
                          "& fieldset": {
                            border:
                              touched.category && errors.category
                                ? "1px solid red"
                                : "none",
                            borderRadius: "30px",
                          },
                          border: "none",
                          backgroundColor: "#F5F5F5",
                          borderRadius: "35px",
                        }}
                      >
                        {categories && categories.length
                          ? categories.map((item, i) => {
                              return (
                                <MenuItem value={item.id} key={i}>
                                  {item.name}
                                </MenuItem>
                              );
                            })
                          : []}
                      </Select>
                    </Stack>
                    <Stack width={"50%"}>
                      <Field
                        type={"number"}
                        as={TextField}
                        name="wordCount"
                        placeholder={"Word Count"}
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            paddingLeft: (theme) => theme.spacing(3.5),
                            backgroundColor: "#F5F5F5",
                            borderRadius: "35px",
                          },
                          "& fieldset": {
                            border:
                              touched.wordCount && errors.wordCount
                                ? "1px solid red"
                                : "none",
                            borderRadius: "30px",
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Select
                    name={"singleSeries"}
                    value={values.singleSeries}
                    onChange={handleChange}
                    sx={{
                      "& .MuiSelect-select .notranslate::after": "Single/Series"
                        ? {
                            content: `"Single/Series"`,
                            opacity: 0.42,
                          }
                        : {},
                      "& fieldset": {
                        border:
                          touched.singleSeries && errors.singleSeries
                            ? "1px solid red"
                            : "none",
                        borderRadius: "30px",
                      },
                      border: "none",
                      backgroundColor: "#F5F5F5",
                      borderRadius: "35px",
                    }}
                  >
                    <MenuItem value={1}>Single</MenuItem>
                    <MenuItem value={2}>Series</MenuItem>
                  </Select>
                  <Select
                    name={"scriptPublished"}
                    value={values.scriptPublished}
                    onChange={handleChange}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "Manuscript / Published"
                          ? {
                              content: `"Manuscript / Published"`,
                              opacity: 0.42,
                            }
                          : {},
                      "& fieldset": {
                        border:
                          touched.scriptPublished && errors.scriptPublished
                            ? "1px solid red"
                            : "none",
                        borderRadius: "30px",
                      },
                      border: "none",
                      backgroundColor: "#F5F5F5",
                      borderRadius: "35px",
                    }}
                  >
                    <MenuItem value={1}>Published</MenuItem>
                    <MenuItem value={2}>Manuscript</MenuItem>
                  </Select>
                  <Typography fontWeight={700}>Attachments</Typography>
                  <label htmlFor="upload-avatar-pic">
                    <Stack
                      component="div"
                      sx={{
                        border: "none",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const fileInput =
                          document.getElementById("upload-avatar-pic");
                        if (fileInput) {
                          fileInput.click();
                        }
                      }}
                    >
                      <Field
                        type={"text"}
                        as={TextField}
                        name="queryLetter"
                        disabled
                        value={pictures?.query?.name}
                        placeholder={"Query Letters"}
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            paddingLeft: (theme) => theme.spacing(3.5),
                          },
                          "& fieldset": {
                            border:
                              touched.queryLetters && errors.queryLetters
                                ? "1px solid red"
                                : "none",
                            borderColor:
                              touched.queryLetters && errors.queryLetters
                                ? "red !important"
                                : "none",
                            borderRadius: "30px",
                          },
                        }}
                      />
                    </Stack>
                    <input
                      accept="application/pdf"
                      // accept="image/*"
                      id="upload-avatar-pic"
                      type="file"
                      hidden
                      onChange={(e) =>
                        handlePicture(e, "query", () =>
                          setFieldValue("queryLetters", "picture")
                        )
                      }
                    />
                  </label>

                  <label htmlFor="upload-avatar-pic-1">
                    <Stack
                      component="div"
                      sx={{
                        border: "none",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const fileInput = document.getElementById(
                          "upload-avatar-pic-1"
                        );
                        if (fileInput) {
                          fileInput.click();
                        }
                      }}
                    >
                      <Field
                        type={"text"}
                        as={TextField}
                        name="synopsis"
                        disabled
                        value={pictures?.synopsis?.name}
                        placeholder={"Synopsis"}
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            paddingLeft: (theme) => theme.spacing(3.5),
                          },
                          "& fieldset": {
                            border:
                              touched.synopsis && errors.synopsis
                                ? "1px solid red"
                                : "none",
                            borderColor:
                              touched.synopsis && errors.synopsis
                                ? "red !important"
                                : "none",
                            borderRadius: "30px",
                          },
                        }}
                      />
                    </Stack>

                    <input
                      accept="application/pdf"
                      // accept="image/*"
                      id="upload-avatar-pic-1"
                      type="file"
                      hidden
                      onChange={(e) =>
                        handlePicture(e, "synopsis", () =>
                          setFieldValue("synopsis", "picture")
                        )
                      }
                    />
                  </label>

                  <label htmlFor="upload-avatar-pic-2">
                    <Stack
                      component="div"
                      sx={{
                        border: "none",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const fileInput = document.getElementById(
                          "upload-avatar-pic-2"
                        );
                        if (fileInput) {
                          fileInput.click();
                        }
                      }}
                    >
                      <Field
                        type={"text"}
                        as={TextField}
                        name="fiftyPages"
                        placeholder={"1st 50 Pages"}
                        disabled
                        value={pictures?.fiftyPages?.name}
                        sx={{
                          "& label": {
                            paddingLeft: (theme) => theme.spacing(2),
                          },
                          "& input": {
                            paddingLeft: (theme) => theme.spacing(3.5),
                          },
                          "& fieldset": {
                            border:
                              touched.fiftyPages && errors.fiftyPages
                                ? "1px solid red"
                                : "none",
                            borderColor:
                              touched.fiftyPages && errors.fiftyPages
                                ? "red !important"
                                : "none",
                            borderRadius: "30px",
                          },
                        }}
                      />
                    </Stack>

                    <input
                      accept="application/pdf"
                      // accept="image/*"
                      id="upload-avatar-pic-2"
                      type="file"
                      hidden
                      onChange={(e) =>
                        handlePicture(e, "fiftyPages", () =>
                          setFieldValue("fiftyPages", "picture")
                        )
                      }
                    />
                  </label>

                  <Stack sx={{ flexDirection: "row", gap: "1em" }}>
                    <Stack width={"50%"}>
                      <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        onClick={() => {
                          setSaveAction("post");
                          handleSubmit();
                        }}
                        size="large"
                        sx={{
                          alignSelf: "center",
                          background: "#FF414C",
                          color: "white",
                          fontSize: "0.8em",
                          fontWeight: "700",
                          borderRadius: "20px",
                          paddingY: "0.7em",
                          marginTop: "4em",
                          width: "100%",
                          boxShadow:
                            "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
                          ":hover": { background: "#FF414C" },
                        }}
                      >
                        Post Submission
                      </LoadingButton>
                    </Stack>
                    <Stack width={"50%"}>
                      <LoadingButton
                        loading={isSaveLoading}
                        variant="contained"
                        onClick={() => {
                          setSaveAction("save");
                          handleSubmit();
                        }}
                        size="large"
                        sx={{
                          alignSelf: "center",
                          background: "white",
                          color: "#FF414C",
                          border: "1px solid #FF414C",
                          fontSize: "0.8em",
                          fontWeight: "700",
                          borderRadius: "20px",
                          width: "100%",
                          paddingY: "0.7em",
                          marginTop: "4em",
                          boxShadow:
                            "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
                          ":hover": {
                            background: "white",
                            color: "#FF414C",
                          },
                        }}
                      >
                        Save Submission
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Modal>
      <AlertDialog
        primaryButton={
          <Typography
            textTransform={"capitalize"}
            display={"flex"}
            alignItems={"center"}
            gap={"0.2em"}
            fontWeight={700}
          >
            Save
          </Typography>
        }
        secondaryButton={
          <Typography
            textTransform={"capitalize"}
            display={"flex"}
            alignItems={"center"}
            gap={"0.2em"}
          >
            Delete
          </Typography>
        }
        secondaryStyle={{ color: "#C0C0C0" }}
        primaryStyle={{ color: "#FF0000", fontWeight: 700 }}
        open={confirmationDlg}
        heading={"Are you sure?"}
        text={
          <Typography textAlign={"center"}>
            Submission details will be saved in drafts.
          </Typography>
        }
        handleClose={() => setConfirmationDlg(false)}
        secondaryAction={() => {
          setConfirmationDlg(false);
          handleClose();
        }}
        primaryAction={() => {
          setConfirmationDlg(false);
          handleClose();
        }}
        buttonWidth={"95%"}
      />
    </>
  );
};
