import { InputAdornment, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import searchIcon from "../../assets/images/searchIcon.svg";

const SearchInput = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    onSearch(value); // Pass the search query up to the parent component
  };
  return (
    <Stack
  
      justifyContent={"center"}
      position={"relative"}
     
      
    >
      <TextField
    
        className="TextField-without-border-radius fieldset "
        id="outlined-start-adornment"
        placeholder={"Search in Agencies"}
        value={searchQuery}
        onChange={handleInputChange}
        sx={{
          bgcolor: "#FFFFFF",
          boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "35.5689px",
          // width: { xs: "40vw", sm: "40vw", md: "30vw", lg: "20vw" },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment     position="start">
              <img src={searchIcon} alt="" />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default SearchInput;
