import {
  STOP_LOADING,
  START_LOADING,
  ERROR,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_PLOTS,
  GET_PLOTS_SUCCESS,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_GENRES,
  GET_GENRES_SUCCESS,
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_ADVERTISEMENTS,
  GET_ADVERTISEMENTS_SUCCESS,
} from "./actionTypes";

export const getCountries = (data) => {
  return {
    type: GET_COUNTRIES,
    payload: data,
  };
};

export const getCoutriesSuccess = (data) => {
  return {
    type: GET_COUNTRIES_SUCCESS,
    payload: data,
  };
};

export const getStates = (data) => {
  return {
    type: GET_STATES,
    payload: data,
  };
};

export const getStatesSuccess = (data) => {
  return {
    type: GET_STATES_SUCCESS,
    payload: data,
  };
};

export const getCities = (data) => {
  return {
    type: GET_CITIES,
    payload: data,
  };
};

export const getCitiesSuccess = (data) => {
  return {
    type: GET_CITIES_SUCCESS,
    payload: data,
  };
};

export const getPlots = (data) => {
  return {
    type: GET_PLOTS,
    payload: data,
  };
};

export const getPlotsSuccess = (data) => {
  return {
    type: GET_PLOTS_SUCCESS,
    payload: data,
  };
};

export const getCategories = (data) => {
  return {
    type: GET_CATEGORIES,
    payload: data,
  };
};

export const getCategoriesSuccess = (data) => {
  return {
    type: GET_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const getGenres = (data) => {
  return {
    type: GET_GENRES,
    payload: data,
  };
};

export const getGenresSuccess = (data) => {
  return {
    type: GET_GENRES_SUCCESS,
    payload: data,
  };
};

export const getLanguages = (data) => {
  return {
    type: GET_LANGUAGES,
    payload: data,
  };
};

export const getLanguagesSuccess = (data) => {
  return {
    type: GET_LANGUAGES_SUCCESS,
    payload: data,
  };
};

export const getAdvertisements = (data) => {
  return {
    type: GET_ADVERTISEMENTS,
    payload: data,
  };
};

export const getAdvertisementsSuccess = (data) => {
  return {
    type: GET_ADVERTISEMENTS_SUCCESS,
    payload: data,
  };
};

export const errorHandler = (data) => ({
  type: ERROR,
  payload: data,
});

export const startLoading = (data) => ({
  type: START_LOADING,
  payload: data,
});

export const stopLoading = (data) => ({
  type: STOP_LOADING,
  payload: {
    action: data,
  },
});
