import React, { useState, useEffect } from "react";
import { Stack } from "@mui/system";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import TruncatedText from "../reuseableFunctions/TruncatedText";
import { Link, Paper, ToggleButton, Typography } from "@mui/material";
import { addRemoveAgency } from "../../store/author/actions";

const AgencyDetailComponent = () => {
  const dispatch = useDispatch();
  const [genres, setGenres] = useState("");

  const { singleAgency } = useSelector((state) => state.author);

  useEffect(() => {
    if (singleAgency?.genres?.length) {
      let data = "";
      singleAgency?.genres.forEach((item) => {
        data = data + item.name + ", ";
      });
      setGenres(data);
    }
  }, [singleAgency]);

  return (
    <Stack height={"100%"} direction={"row"} paddingTop={"1.2em"}>
      <Stack width={"30%"}>
        <Paper
          component={Stack}
          position={"relative"}
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${singleAgency?.user_profile?.profile_image})`,
          }}
          width={"100%"}
          height={"15em"}
          borderRadius={"35px !important"}
        >
          <ToggleButton
            value="check"
            onChange={() =>
              dispatch(addRemoveAgency({ agency_id: singleAgency?.id }))
            }
            sx={{
              right: "47px",
              bottom: "-22px",
              backgroundColor: "white",
              border: "none !important",
              borderRadius: "50px !important",
              position: "absolute !important",
            }}
          >
            <FavoriteIcon
              sx={{
                fill: singleAgency?.favourite_agency ? "red" : "currentcolor",
              }}
            />
          </ToggleButton>
        </Paper>
      </Stack>
      <Stack width={"70%"} px={2} style={{ height: "78vh", overflow: "auto" }}>
        <Stack width={"45%"} spacing={2}>
          <Stack>
            <Typography fontWeight={700}>{singleAgency?.full_name}</Typography>
            <Typography variant="caption">
              {singleAgency?.user_countries?.length
                ? singleAgency?.user_countries?.map((item, i) => {
                    return (
                      <span key={i}>
                        {item.name}
                        {i !== singleAgency?.user_countries?.length - 1
                          ? ", "
                          : ""}
                      </span>
                    );
                  })
                : ""}
            </Typography>
          </Stack>
          <Stack>
            <Typography fontWeight={700}>Bio</Typography>
            <TruncatedText
              limit={"34"}
              text={singleAgency?.user_profile?.bio}
            />
          </Stack>
          <Stack>
            <Typography fontWeight={700}>Genres</Typography>
            {genres?.length ? <TruncatedText text={genres} limit={"10"} /> : ""}
          </Stack>
          <Stack>
            <Typography fontWeight={700}>Language</Typography>
            <Typography variant="caption">
              {singleAgency?.user_languages?.length
                ? singleAgency?.user_languages?.map((item, i) => {
                    return (
                      <span key={i}>
                        {item.name}
                        {i !== singleAgency?.user_languages?.length - 1
                          ? ", "
                          : ""}
                      </span>
                    );
                  })
                : ""}
            </Typography>
          </Stack>
          <Stack>
            <Typography fontWeight={700}>Website</Typography>
            <Link
              underline="none"
              sx={{ cursor: "pointer" }}
              onClick={() =>
                window.open(singleAgency?.user_profile?.website, "_blank")
              }
            >
              {singleAgency?.user_profile?.website}
            </Link>
          </Stack>
          <Stack>
            <Typography fontWeight={700}>No. of Agents</Typography>
            <Typography variant="caption">
              {singleAgency?.user_profile?.no_of_agents}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AgencyDetailComponent;
