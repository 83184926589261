import React, { useState } from "react";
import { Stack } from "@mui/system";
import { OtpInput } from "./OtpInput";
import { toast } from "react-hot-toast";
import { BiRightArrowAlt } from "react-icons/bi";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { forgetPasswordVerify } from "../../store/auth/actions";

export const ForgetPasswordVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pinCode, setPinCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let email = sessionStorage.getItem("forget-password-email");

  const handleVerify = () => {
    if (pinCode.length === 4) {
      setIsLoading(true);
      let data = new FormData();
      data.append("email", email);
      data.append("token", pinCode);
      dispatch(forgetPasswordVerify({ data, setIsLoading, navigate }));
    } else {
      toast.error("Enter Correct Pin");
    }
  };
  return (
    <Stack justifyContent={"center"} alignItems={"center"} height={"100vh"}>
      <Stack width={{ xs: "60%", sm: "45%", md: "40%", lg: "25%" }}>
        <Typography variant="h4" fontWeight={"700"} textAlign={"center"}>
          Verification Code
        </Typography>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typography variant="body2">
            Please type the verification code sent on your email
          </Typography>
          <Typography color={"#FF414C"} variant="body2" fontWeight={"700"}>
            {email}
          </Typography>
        </Stack>
        <Stack paddingTop={"5em"}>
          <OtpInput handlePin={(pin) => setPinCode(pin)} />
          <Typography
            variant="subtitle2"
            textAlign={"center"}
            paddingLeft={{ md: "0", lg: "2em" }}
          >
            Didn’t receive the code? <b>RESEND</b>
          </Typography>
        </Stack>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="submit"
          size="large"
          sx={{
            width: "90%",
            color: "white",
            paddingY: "1em",
            marginTop: "4em",
            fontSize: "0.8em",
            fontWeight: "700",
            alignSelf: "center",
            borderRadius: "20px",
            background: "#FF414C",
            ":hover": { background: "#FF414C" },
            boxShadow: "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
          }}
          onClick={handleVerify}
          endIcon={<BiRightArrowAlt size={20} />}
        >
          Verify{" "}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
