import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
// import closeIcon from "../../assets/images/close-icon.svg";

export const AlertDialog = ({
  open,
  text,
  heading,
  handleClose,
  primaryButton,
  secondaryButton,
  primaryStyle,
  secondaryStyle,
  primaryAction,
  secondaryAction,
  buttonWidth,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <Stack>
          <IconButton
            component="span"
            onClick={handleClose}
            sx={{ position: "absolute", right: "6px", top: "10px" }}
          >
            <img alt="" src={closeIcon} style={{ width: "15px" }} />
          </IconButton>
        </Stack> */}
        <DialogTitle id="alert-dialog-title" sx={{ pb: 0 }}>
          <Typography
            fontWeight={700}
            fontSize={"22.35px"}
            textAlign={"center"}
            alignSelf={"center"}
          >
            {heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "black" }}
          >
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            width: buttonWidth ? buttonWidth : "85%",
            alignSelf: "center",
            justifyContent: "space-around",
            borderTop: "1px solid rgba(0, 0, 0, 0.25)",
          }}
        >
          {primaryButton && (
            <Button onClick={primaryAction} sx={{ ...primaryStyle }}>
              {primaryButton}
            </Button>
          )}
          {secondaryButton && (
            <Button onClick={secondaryAction} sx={{ ...secondaryStyle }}>
              {secondaryButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
