import axios from "../../axios/AxiosConfig";
import {
  stopLoading,
  startLoading,
  errorHandler,
  getAgenciesSuccess,
  addRemoveAgencySuccess,
  getWishlistSuccess,
  getFreeAgentSuccess,
  getNotificationsSuccess,
  getPaymentHistorySuccess,
  getMyPaidAgentsSuccess,
  getWishlistAgenciesSuccess,
  updateProfileSuccess,
  deleteAccountSuccess,
  getAllPitchesSuccess,
  getMySubmissionsSuccess,
  unpublishedSubmissionsSuccess,
  bookDealSuccess,
  filterAgenciesSuccess,
  filterPitchesSuccess,
  contactUsSuccess,
  postSubmissionSuccess,
  getAgencies,
  getWishlist,
  getAgencyStartLoading,
  getAgencyStopLoading,
  getWishlistAgencyStartLoading,
  getWishlistAgencyStopLoading,
  getSubmissionStartLoading,
  getSubmissionStopLoading,
  getPicthesStartLoading,
  getPitchesStopLoading,
  getMySubmissions,
  findAgentByGenreSuccess,
  findAgentByGenreStartLoading,
  findAgentByGenreStopLoading,
  agentPaidSuccess,
  bookSubmissionBuySuccess,
} from "./actions";
import { fork, put, all, takeLatest } from "redux-saga/effects";
import {
  ADD_REMOVE_AGENCY,
  AGENT_PAID,
  BOOK_DEAL,
  BOOK_SUBMISSION_PURCHASE,
  CONTACT_US,
  DELETE_ACCOUNT,
  FILTER_AGENCIES,
  FILTER_PITCHES,
  FIND_AGENT_BY_GENRE,
  GET_AGENCIES,
  GET_ALL_PITCHES,
  GET_FREE_AGENT,
  GET_MY_AGENTS,
  GET_MY_SUBMISSIONS,
  GET_NOTIFICATIONS,
  GET_PAYMENT_HISTORY,
  GET_WISHLIST,
  GET_WISHLIST_AGENCIES,
  POST_SUBMISSION,
  UNPUBLISHED_SUBMISSION,
  UPDATE_PROFILE,
} from "./actionTypes";
import { getUpdatedAuthorProfile, logoutUser } from "../auth/actions";

function* getAgenciesAction() {
  try {
    yield put(getAgencyStartLoading());
    const response = yield axios.get("author/agencies");
    yield put(getAgenciesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(getAgencyStopLoading());
  }
}

function* addRemoveAgencyAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("author/wishlist-update", payload);
    yield put(addRemoveAgencySuccess(response.data));
    yield put(getAgencies());
    yield put(getWishlist());
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getWishlistAction({ payload }) {
  try {
    yield put(getWishlistAgencyStartLoading());
    const response = yield axios.get("author/wishlist", payload);
    yield put(getWishlistSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(getWishlistAgencyStopLoading());
  }
}

function* findAgentByGenreAction({ payload }) {
  try {
    yield put(findAgentByGenreStartLoading());
    const response = yield axios.get(
      `author/find-agent?sub_genre=${payload.genre}`
    );
    console.log(response.data);
    if (response.status === 201) {
      yield put(findAgentByGenreSuccess({ freeAgent: true }));
    } else {
      yield put(
        findAgentByGenreSuccess({ ...response.data, freeAgent: false })
      );
    }
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(findAgentByGenreStopLoading());
  }
}

function* getFreeAgentAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("author/free-agent", payload);
    yield put(getFreeAgentSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* agentPaidAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("author/purchase-agent", payload);
    yield put(agentPaidSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getNotificationsAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("author/notification-history", payload);
    yield put(getNotificationsSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getPaymentHistoryAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("author/payment-history", payload);
    yield put(getPaymentHistorySuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getPaidAgentsAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get(`author/author-agents?page=${payload}`);
    yield put(getMyPaidAgentsSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getWishlistAgenciesAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.get("author/wishlist", payload);
    yield put(getWishlistAgenciesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* getAllPitchesAction({ payload }) {
  try {
    yield put(getPicthesStartLoading());
    const response = yield axios.get(`author/pitches?page=${payload.page}`);
    yield put(getAllPitchesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    if (payload?.setIsLoading) {
      payload.setIsLoading(false);
    }
    yield put(getPitchesStopLoading());
  }
}

function* getMySubmissionsAction({ payload }) {
  try {
    yield put(getSubmissionStartLoading());
    const response = yield axios.get(`author/submissions?page=${payload.page}`);
    yield put(getMySubmissionsSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    if (payload?.setIsLoading) {
      payload.setIsLoading(false);
    }
    yield put(getSubmissionStopLoading());
  }
}

function* unpublishedSubmissionsAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.patch(
      `author/submissions/${payload.id}/unpublish`,
      payload
    );
    yield put(unpublishedSubmissionsSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    if (payload?.setShowUnpublish) {
      payload?.setShowUnpublish(false);
    }
    yield put(stopLoading());
  }
}

function* bookSubmissionBuyAction({ payload }) {
  console.log(
    "🚀 ~ file: saga.js:247 ~ function*bookSubmissionBuyAction ~ payload:",
    payload
  );
  try {
    yield put(startLoading());
    const response = yield axios.post(`author/author-submission`, payload);
    yield put(bookSubmissionBuySuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* bookDealAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post(
      `author/submissions/${payload?.id}/book-deal`,
      payload
    );
    yield put(bookDealSuccess(response.data));
    if (payload?.setShowBookDealSuccess) {
      payload?.setShowBookDealSuccess(true);
    }
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    if (payload?.setShowBookDeal) {
      payload?.setShowBookDeal(false);
    }
    yield put(stopLoading());
  }
}

function* filterAgenciesAction({ payload }) {
  try {
    const urlGenres = payload.genres.length
      ? payload.genres.map((genre) => `genres[]=${genre}`).join("&")
      : "";
    yield put(getAgencyStartLoading());
    const response = yield axios.get(
      `author/agencies?${urlGenres}${
        payload?.language ? `&language=${payload.language}` : ""
      } ${payload?.country ? `&country=${payload.country}` : ""}`
    );
    yield put(filterAgenciesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(getAgencyStopLoading());
  }
}

function* filterPitchesAction({ payload }) {
  try {
    const urlGenres = payload.genres.length
      ? payload.genres.map((genre) => `genres[]=${genre}`).join("&")
      : "";
    yield put(startLoading());
    const response = yield axios.get(
      `author/pitches?${urlGenres}${
        payload?.language ? `&language=${payload.language}` : ""
      } ${payload?.country ? `&country=${payload.country}` : ""}`,
      payload
    );
    yield put(filterPitchesSuccess(response.data));
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* contactUsAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("author/send-message", payload.values);
    yield put(contactUsSuccess(response.data));
    payload.handleClose();
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    payload.setIsLoading(false);
    yield put(stopLoading());
  }
}

function* updateProfileAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("author", payload.data);
    yield put(updateProfileSuccess(response.data));
    yield put(getUpdatedAuthorProfile());
    if (payload?.handleClose) {
      payload.handleClose();
    }
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    if (payload?.setIsLoading) {
      payload.setIsLoading(false);
    }
    yield put(stopLoading());
  }
}

function* postSubmissionAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("author/submissions", payload.data);
    yield put(postSubmissionSuccess(response.data));
    yield put(getMySubmissions());

    if (payload?.handleClose) {
      payload.handleClose();
    }
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    if (payload?.setIsLoading) {
      payload.setIsLoading(false);
    }
    if (payload?.setIsSaveLoading) {
      payload.setIsSaveLoading(false);
    }
    yield put(stopLoading());
  }
}

function* deleteAccountAction({ payload }) {
  try {
    yield put(startLoading());
    const response = yield axios.post("delete-account", payload);
    yield put(deleteAccountSuccess(response.data));
    yield put(logoutUser());
    payload.navigate("/login");
  } catch (error) {
    yield put(errorHandler(error.response));
  } finally {
    yield put(stopLoading());
  }
}

function* watchGetAgencies() {
  yield takeLatest(GET_AGENCIES, getAgenciesAction);
}

function* watchAddRemoveAgency() {
  yield takeLatest(ADD_REMOVE_AGENCY, addRemoveAgencyAction);
}

function* watchGetWishlist() {
  yield takeLatest(GET_WISHLIST, getWishlistAction);
}

function* watchGetFreeAgent() {
  yield takeLatest(GET_FREE_AGENT, getFreeAgentAction);
}

function* watchGetNotifications() {
  yield takeLatest(GET_NOTIFICATIONS, getNotificationsAction);
}

function* watchGetPaymentHistory() {
  yield takeLatest(GET_PAYMENT_HISTORY, getPaymentHistoryAction);
}

function* watchGetPaidAgents() {
  yield takeLatest(GET_MY_AGENTS, getPaidAgentsAction);
}

function* watchGetWishlistAgencies() {
  yield takeLatest(GET_WISHLIST_AGENCIES, getWishlistAgenciesAction);
}

function* watchGetAllPitches() {
  yield takeLatest(GET_ALL_PITCHES, getAllPitchesAction);
}

function* watchGetMySubmissions() {
  yield takeLatest(GET_MY_SUBMISSIONS, getMySubmissionsAction);
}

function* watchUnpublishedSubmissions() {
  yield takeLatest(UNPUBLISHED_SUBMISSION, unpublishedSubmissionsAction);
}

function* watchBookDeal() {
  yield takeLatest(BOOK_DEAL, bookDealAction);
}

function* watchFilterAgencies() {
  yield takeLatest(FILTER_AGENCIES, filterAgenciesAction);
}

function* watchFilterPitches() {
  yield takeLatest(FILTER_PITCHES, filterPitchesAction);
}

function* watchContactUs() {
  yield takeLatest(CONTACT_US, contactUsAction);
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE, updateProfileAction);
}

function* watchDeleteAccount() {
  yield takeLatest(DELETE_ACCOUNT, deleteAccountAction);
}

function* watchPostSubmission() {
  yield takeLatest(POST_SUBMISSION, postSubmissionAction);
}

function* watchFindAgentByGenreAction() {
  yield takeLatest(FIND_AGENT_BY_GENRE, findAgentByGenreAction);
}

function* watchagentPaid() {
  yield takeLatest(AGENT_PAID, agentPaidAction);
}

function* watchBookSubmissionBuy() {
  yield takeLatest(BOOK_SUBMISSION_PURCHASE, bookSubmissionBuyAction);
}

export default function* authorSaga() {
  yield all([
    fork(watchGetAgencies),
    fork(watchAddRemoveAgency),
    fork(watchGetWishlist),
    fork(watchGetFreeAgent),
    fork(watchGetNotifications),
    fork(watchGetPaymentHistory),
    fork(watchGetPaidAgents),
    fork(watchGetWishlistAgencies),
    fork(watchGetAllPitches),
    fork(watchGetMySubmissions),
    fork(watchUnpublishedSubmissions),
    fork(watchBookDeal),
    fork(watchFilterAgencies),
    fork(watchFilterPitches),
    fork(watchContactUs),
    fork(watchUpdateProfile),
    fork(watchDeleteAccount),
    fork(watchPostSubmission),
    fork(watchFindAgentByGenreAction),
    fork(watchagentPaid),
    fork(watchBookSubmissionBuy),
  ]);
}
