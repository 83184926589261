import { Stack } from "@mui/system";
import React, { useRef } from "react";
import PinField from "react-pin-field";

export const OtpInput = ({ handlePin }) => {
  const ref = useRef();
  return (
    <Stack gap={"2vw"} alignSelf={"center"} flexDirection={"row"}>
      <PinField
        ref={ref}
        length={4}
        type={"number"}
        inputMode={"numeric"}
        className={"pin-input"}
        style={{
          width: "40px",
          height: "40px",
          border: "none",
          borderRadius: "10px",
          textAlign: "center",
          fontSize: "1.5vw",
          fontWeight: "700",
          color: "#FF414C",
          background: "#F5F5F5",
        }}
        validate={/^[0-9]$/}
        onChange={(value, index) => handlePin(value)}
      />
    </Stack>
  );
};
