import React, { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { Stack } from "@mui/system";
import {
  Button,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import agentIcon from "../../assets/images/agent-icon.svg";
import submissionIcon from "../../assets/images/submission-icon.svg";
import { defaultModalStyle } from "../../utils/Constants";
import { HowToSubmitDlg } from "./HowToSubmitDlg";
import { AgentMatchesDlg } from "./AgentMatchesDlg";
import closeIcon from "../../assets/images/close-icon.svg";
import GooglePaymentButton from "./GooglePaymentButton";
import ApplePay from "./ApplePay";
import { useDispatch, useSelector } from "react-redux";
import {
  agentPaid,
  bookSubmissionBuy,
  findAgentByGenre,
  resetAgentState,
} from "../../store/author/actions";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-hot-toast";
import axios from "../../axios/AxiosConfig";
import { useElements } from "@stripe/react-stripe-js";
import BankDetailCard from "../cards/BankDetailCard";

let SECRET_KEY_DEV =
  "sk_test_51K9O1IBw1yafzqNXTs97guVwGXn94wEz5ZbK2BQMp5thYcaz4mOZjeJ8x5m5MJdtDiFFoRI5mXuKapSIMfocW0Cm00jVleziVi";

export const AgentMatchingDlg = ({ handleClose, open }) => {
  const dispatch = useDispatch();

  const { agentFound, agentFoundLoading, free_agents, user_genres } =
    useSelector((state) => ({
      agentFound: state.author.agentFound,
      agentFoundLoading: state.author.agentFoundLoading,
      free_agents: state.auth.free_agents,
      user_genres: state.auth.user_genres,
    }));
  console.log(
    "🚀 ~ file: AgentMatchingDlg.jsx:41 ~ AgentMatchingDlg ~ agentFound:",
    agentFound
  );

  const [genre, setGenre] = useState();
  const triggerFrom = useRef(false);

  const [products, setProducts] = useState([]);
  console.log(
    "🚀 ~ file: AgentMatchingDlg.jsx:56 ~ AgentMatchingDlg ~ products:",
    products
  );
  const [isvisibleBook, setIsvisibleBook] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agentLoading, setAgentLoading] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [isvisibleAgent, setIsvisibleAgent] = useState(false);
  const [showHowToSubmitDlg, setShowHowToSubmitDlg] = useState(false);
  const [showAgentMatchesDlg, setShowAgentMatchesDlg] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState();

  const handleChange = (event) => {
    setGenre(event.target.value);
  };

  const fetchProducts = async () => {
    const response = await axios.get("/stripe/products");
    if (response.data.success) {
      console.log(response.data.data);
      setProducts(response.data.data);
    }
  };

  const renderBookComponent = () => {
    // setIsvisibleBook(!isvisibleBook);
    // setIsvisibleAgent(true);
    // setPaymentAmount(16.99);
  };

  const stripeCheckout = async (id, type, sub_genre = null) => {
    if (triggerFrom.current === "agent") {
      setAgentLoading(true);
    } else {
      setLoading(true);
    }
    const response = await axios.post("/stripe-checkout", {
      product_id: id,
      type: type,
      sub_genre: sub_genre,
    });
    const data = response.data;
    var url = data.url;
    window.location.href = url;
  };

  const renderAgentComponent = () => {
    dispatch(findAgentByGenre({ genre }));
  };

  useEffect(() => {
    // if (agentFound && agentFound === "free_agent") {
    //   toast.success("Free Agent Is Assigned");
    // } else if (typeof agentFound === "number") {
    //   setPaymentAmount(1.99);
    //   setIsvisibleAgent(true);
    // }
    if (agentFound) {
      handleBuyAgent();
    }
  }, [agentFound]);

  const handleIsVisibleCardDetails = () => {
    setIsvisibleAgent(false);
    dispatch(resetAgentState());
  };

  useEffect(() => {
    if (paymentSuccess) {
      if (triggerFrom.current === "agent") {
        const form = new FormData();
        // const receiptID = transactionDetail?.receipt_url.split("/").pop();
        form.append("receipt_data", "1787-5151");
        form.append("transaction_id", transactionDetail?.id);
        form.append("no_of_agents", 1);
        form.append("platform", "apple");
        form.append("price", paymentAmount);
        form.append("agents", agentFound);
        dispatch(agentPaid(form));
        dispatch(resetAgentState());
      } else if (triggerFrom.current === "book") {
        const payload = {
          token: transactionDetail?.id,
          id: SECRET_KEY_DEV,
          paymentAmount,
        };
        dispatch(bookSubmissionBuy(payload));
        setPaymentSuccess(false);
      }
    }
  }, [paymentSuccess]);

  const handleBuyAgent = () => {
    const form = new FormData();
    form.append("no_of_agents", 1);
    form.append("platform", "apple");
    form.append("price", 0);
    form.append("agents", agentFound);
    dispatch(agentPaid(form));
    dispatch(resetAgentState());
    handleClose();
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          spacing={"1em"}
          height={"85vh"}
          overflow={"auto"}
          alignItems={"center"}
          sx={defaultModalStyle}
          width={{ xs: "70%", sm: "55vw", md: "45vw", lg: "30vw" }}
        >
          <IconButton
            component="span"
            onClick={handleClose}
            sx={{ position: "absolute", right: "6px", top: "10px" }}
          >
            <img alt="" src={closeIcon} style={{ width: "15px" }} />
          </IconButton>
          <Typography
            sx={{ fontWeight: { md: "700", xs: "300" } }}
            variant={"h4"}
            mt={"0.3em !important"}
          >
            Query Submission
          </Typography>
          <Typography marginTop={"0 !important"}>
            Authors, find your Agents
          </Typography>
          <Button
            sx={{
              width: "85%",
              color: "#00A3FF",
              fontSize: "0.8em",
              fontWeight: "700",
              borderRadius: "20px",
              background: "#F6F6F6",
              paddingY: "1em",
              marginTop: "2em !important",
              boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => setShowHowToSubmitDlg(true)}
          >
            How to Submit?
          </Button>

          <Stack
            sx={{
              width: "80%",
              padding: "1em",
              borderRadius: "30px",
              background: "#ffffff",
              boxShadow: "0px 3px 8px 0px #00000033",
            }}
          >
            <Stack sx={{ flexDirection: "row" }}>
              <img
                alt=""
                src={submissionIcon}
                style={{ height: "50px", width: "50px", paddingRight: "1em" }}
              />
              <Stack>
                <Typography variant="h5" fontWeight={700}>
                  Book Submission
                </Typography>
                {/* <Typography variant="body2">+ 2 free agent matches</Typography> */}
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                marginTop: "1em",
                alignSelf: "center",
                border: "1px solid #E4E4E4",
              }}
            />
            <Stack
              sx={{
                paddingY: "1em",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {products.length > 0 && (
                <Typography color={"#FF414C"} fontWeight={700} variant={"h5"}>
                  ${products[0].amount}
                </Typography>
              )}
              <Stack
                sx={{
                  gap: "0.5em",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {products.length > 0 && (
                  <Button
                    onClick={() => {
                      renderBookComponent();
                      triggerFrom.current = "book";
                      stripeCheckout(products[0].id, "book");
                    }}
                    sx={{
                      display: isvisibleBook ? "none" : "inherit",
                      background: "#FF414C",
                      color: "white",
                      borderRadius: "20px",
                      boxShadow:
                        "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
                      ":hover": { background: "#FF414C" },
                    }}
                  >
                    {loading ? "Please Wait..." : "Buy"}
                    {/* Buy */}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Button
            sx={{
              color: "#00A3FF",
              fontSize: "0.8em",
              fontWeight: "700",
              borderRadius: "20px",
              width: "85%",
              paddingY: "1em",
              background: "#F6F6F6",
              marginTop: "2em !important",
              boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => setShowAgentMatchesDlg(true)}
          >
            What is an Agent Match?
          </Button>

          <Stack
            sx={{
              width: "80%",
              padding: "1em",
              borderRadius: "30px",
              background: "#ffffff",
              boxShadow: "0px 3px 8px 0px #00000033",
            }}
          >
            <Stack sx={{ flexDirection: "row" }}>
              <img
                alt=""
                src={agentIcon}
                style={{
                  height: "50px",
                  width: "50px",
                  paddingRight: "1em",
                }}
              />
              <Stack>
                <Typography variant="h5" fontWeight={700}>
                  Agent Match
                </Typography>
                {/* <Typography variant="body2">
                  {free_agents} free Agents Available
                </Typography> */}
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                marginTop: "1em",
                alignSelf: "center",
                border: "1px solid #E4E4E4",
              }}
            />
            <Stack
              sx={{
                paddingY: "1em",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <Stack
                sx={{
                  gap: "0.5em",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {products.length > 1 && (
                  <LoadingButton
                    loading={agentFoundLoading}
                    sx={{
                      display: isvisibleAgent ? "none" : "inherit",
                      background: "#FF414C",
                      color: "white",
                      borderRadius: "20px",
                      boxShadow:
                        "0px 5.92814px 29.6407px rgba(255, 0, 0, 0.25)",
                      ":hover": { background: "#FF414C" },
                    }}
                    onClick={() => {
                      // renderAgentComponent();
                      triggerFrom.current = "agent";
                      genre
                        ? //stripeCheckout(products[1].id, "agent", genre)
                          // handleBuyAgent()
                          renderAgentComponent()
                        : toast.error("Choose a Genre First");
                    }}
                  >
                    Get
                  </LoadingButton>
                )}
              </Stack>
            </Stack>
          </Stack>
          <RadioGroup
            sx={{
              alignSelf: "flex-start",
              paddingLeft: "2em",
              width: "100%",
              flexDirection: "row",
            }}
            value={genre}
            onChange={handleChange}
          >
            {user_genres.map((item) => {
              return (
                <FormControlLabel
                  value={item}
                  label={[item]}
                  control={<Radio sx={{ color: "red" }} />}
                  sx={{ margin: 0, fontSize: "1vw" }}
                />
              );
            })}

            {/* <FormControlLabel
              value={user_genres && user_genres[1]}
              label={user_genres && user_genres[1]}
              sx={{ margin: 0, fontSize: "1vw" }}
              control={<Radio sx={{ color: "red" }} />}
            /> */}
          </RadioGroup>
          <Typography
            color={"#00A3FF"}
            variant={"h6"}
            fontWeight={700}
            sx={{ cursor: "pointer" }}
          >
            Terms and Conditions
          </Typography>
        </Stack>
      </Modal>
      <HowToSubmitDlg
        open={showHowToSubmitDlg}
        handleClose={() => setShowHowToSubmitDlg(false)}
      />
      <AgentMatchesDlg
        open={showAgentMatchesDlg}
        handleClose={() => setShowAgentMatchesDlg(false)}
      />
    </>
  );
};
