import React, { useState } from "react";
import { Box } from "@mui/system";
import { AlertDialog } from "../dialogs/AlertDialog";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import yesIcon from "../../assets/images/yes.svg";
import unpublishIcon from "../../assets/images/unpublish.svg";
import { useDispatch, useSelector } from "react-redux";
import { bookDeal, unpublishedSubmissions } from "../../store/author/actions";
import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { NonFictionalSubmission } from "../dialogs/NonFictionalSubmission";
import { FictionalSubmissionDlg } from "../dialogs/FictionalSubmissionDlg";

const SubmissionActions = ({ submission }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  console.log(
    "🚀 ~ file: SubmissionActions.jsx:17 ~ SubmissionActions ~ user:",
    user
  );

  const [showBookDeal, setShowBookDeal] = useState(false);
  const [showUnpublish, setShowUnpublish] = useState(false);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [showFictionaModal, setShowFictionalModal] = useState(false);
  const [showBookDealSuccess, setShowBookDealSuccess] = useState(false);
  const [showNonFictionalModal, setShowNonFictionalModal] = useState(false);

  const handleOpenSettings = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorElSettings(e.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };
  const handleSettingsClick = (e) => {
    let value = e.target.innerText;
    if (value === "Book Deal") setShowBookDeal(true);
    if (value === "Unpublish") setShowUnpublish(true);
    if (value === "Edit Details") {
      if (user?.is_fictional === 1) {
        setShowFictionalModal(true);
      } else {
        setShowNonFictionalModal(true);
      }
    }

    setAnchorElSettings(null);
  };

  const pendingSettingsList = ["Edit Details"];
  const publishedSettingsList = ["Book Deal", "Unpublish"];
  const settingsList = ["Edit Details", "Book Deal", "Unpublish"];

  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenSettings} sx={{ p: 0 }}>
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElSettings}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElSettings)}
          onClose={handleCloseSettings}
        >
          {submission?.status === "publish"
            ? publishedSettingsList.map((item) => (
                <MenuItem key={item} onClick={handleSettingsClick}>
                  <Typography textAlign="center">{item}</Typography>
                </MenuItem>
              ))
            : submission?.status === "pending"
            ? pendingSettingsList.map((item) => (
                <MenuItem key={item} onClick={handleSettingsClick}>
                  <Typography textAlign="center">{item}</Typography>
                </MenuItem>
              ))
            : settingsList.map((item) => (
                <MenuItem key={item} onClick={handleSettingsClick}>
                  <Typography textAlign="center">{item}</Typography>
                </MenuItem>
              ))}
        </Menu>
      </Box>
      <AlertDialog
        primaryButton={
          <Typography display={"flex"} alignItems={"center"} gap={"0.2em"}>
            <img alt="" src={unpublishIcon} width={"20px"} />
            Unpublish
          </Typography>
        }
        secondaryButton={"Cancel"}
        secondaryStyle={{ color: "#C0C0C0" }}
        primaryStyle={{ color: "#FF0000", fontWeight: 700 }}
        open={showUnpublish}
        heading={"Are you sure?"}
        text={
          <Typography textAlign={"center"}>
            You want to unpublish this submission. This submission will not be
            available for any agent to see and you will need to resubmit if
            offering it again.
          </Typography>
        }
        handleClose={() => setShowUnpublish(false)}
        primaryAction={() => {
          dispatch(
            unpublishedSubmissions({
              id: submission?.id,
              setShowUnpublish,
            })
          );
        }}
        secondaryAction={() => setShowUnpublish(false)}
      />
      <AlertDialog
        primaryButton={
          <Typography display={"flex"} alignItems={"center"} gap={"0.2em"}>
            <img alt="" src={yesIcon} width={"20px"} />
            Yes
          </Typography>
        }
        secondaryButton={"Cancel"}
        secondaryStyle={{ color: "#C0C0C0" }}
        primaryStyle={{ color: "#6DD96B", fontWeight: 700 }}
        open={showBookDeal}
        heading={"Are you sure?"}
        text={
          <Typography textAlign={"center"}>
            Now that you have made a book deal, click yes.
          </Typography>
        }
        handleClose={() => setShowBookDeal(false)}
        secondaryAction={() => setShowBookDeal(false)}
        primaryAction={() => {
          dispatch(
            bookDeal({
              id: submission?.id,
              setShowBookDeal,
              setShowBookDealSuccess,
            })
          );
        }}
      />
      <AlertDialog
        primaryButton={<Typography gap={"0.2em"}>Continue</Typography>}
        primaryStyle={{ color: "#6DD96B", fontWeight: 700 }}
        open={showBookDealSuccess}
        heading={"Congratulations!"}
        text={<Typography textAlign={"center"}>For your book deal.</Typography>}
        handleClose={() => setShowBookDealSuccess(false)}
        primaryAction={() => setShowBookDealSuccess(false)}
      />

      <FictionalSubmissionDlg
        edit={true}
        submission={submission}
        open={showFictionaModal}
        handleClose={() => {
          setShowFictionalModal(false);
        }}
      />
      <NonFictionalSubmission
        edit={true}
        submission={submission}
        open={showNonFictionalModal}
        handleClose={() => setShowNonFictionalModal(false)}
      />
    </>
  );
};

export default SubmissionActions;
